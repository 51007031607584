import type { JSX, MouseEvent } from 'react';
import { useCallback } from 'react';

import { memo } from '@change/core/react/core';
import { parseHtml } from '@change/core/react/html';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { BannerWrapper } from './BannerWrapper';

export const Banner = memo(function Banner({
	mobileContent,
	desktopContent,
	onLinkClick,
}: {
	mobileContent: string;
	desktopContent: string;
	onLinkClick?: () => void;
}): JSX.Element {
	const onClick = useCallback(
		(event: MouseEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if ((event.target as HTMLElement).tagName === 'A') {
				onLinkClick?.();
			}
		},
		[onLinkClick],
	);

	// TODO might be better to use a replace function with parseHtml for the onClick
	return (
		<BannerWrapper big>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div onClick={onClick}>
				<Box sx={{ display: ['block', 'none'] }} data-testid="emergency-banner-mobile">
					<Text
						sx={{
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'& a': {
								fontWeight: 'normal',
								textDecoration: 'underline',
							},
						}}
					>
						{parseHtml(mobileContent)}
					</Text>
				</Box>
				<Box sx={{ display: ['none', 'block'] }} data-testid="emergency-banner-desktop">
					<Text
						sx={{
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'& a': {
								fontWeight: 'normal',
								textDecoration: 'underline',
							},
						}}
					>
						{parseHtml(desktopContent)}
					</Text>
				</Box>
			</div>
		</BannerWrapper>
	);
});
