import pickBy from 'lodash/fp/pickBy';
import qs from 'qs';

import { getLocation } from '@change/core/window';

import type { HydrationData } from 'src/app/types';

const AUTO_LOGIN_PARAMS = ['cs_auth_tk', 'cs_tk', 'tk'];
const TRACKING_PARAMS = [
	'gclid',
	'msclkid',
	'ttclid',
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_term',
	'utm_content',
];
const L10N_PARAMS = ['lang', 'country_code'];
const SESSION_FORWARDED_PARAMS = [...AUTO_LOGIN_PARAMS, ...TRACKING_PARAMS, ...L10N_PARAMS];

export function createSessionGqlPath({ hydrationData }: { hydrationData?: HydrationData }): string {
	const { search } = getLocation();

	const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true });

	const sessionQueryParams = pickBy((value, key) => !!value && SESSION_FORWARDED_PARAMS.includes(key), {
		...parsedQuery,
		// using hydrationData to make sure that the session is consistent with what was compute by the SSR server
		lang: hydrationData?.l10n.locale || parsedQuery.lang,
		country_code: hydrationData?.l10n.countryCode || parsedQuery.country_code,
	});

	const sessionQueryString = qs.stringify(
		{
			...sessionQueryParams,
			// this is necessary so that the API endpoint can override the Referer header value (which would be the current url)
			// with the value that actually interest us for tracking (which would be the url the user is coming from)
			referrer: document.referrer,
		},
		{ encodeValuesOnly: true },
	);

	return `/session${sessionQueryString ? `?${sessionQueryString}` : ''}`;
}
