import type { TrackingFn } from '@change/core/react/tracking';
import { openWindow } from '@change/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareWhatsapp(
	// TODO invert text/url for consistency with other shared
	text: string | undefined,
	url: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): void {
	const msg = encodeURIComponent(text ? `${text}\n${url}` : url);
	openWindow(`https://wa.me/?text=${msg}`);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
