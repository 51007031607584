import type { UtilityContext } from '@change/core/react/utilityContext';

import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

export const {
	Context: MembershipAwarenessCardDataContext,
	Provider: MembershipAwarenessCardDataContextProvider,
	useContext: useMembershipAwarenessCardData,
	useAsyncData: useMembershipAwarenessCardDataFromApi,
	prefetchAsyncData: prefetchMembershipAwarenessCardDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'MembershipAwarenessCardDataContext',
	dataProperty: 'data',
	hasUserData: true,
	getData: async (_utilityContext: UtilityContext) => Promise.resolve({}),
});
