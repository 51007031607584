import qs from 'qs';

import type { SubEnvironment } from '@change/config/environments';
import { facebookAppIds } from '@change/config/facebook';
import type { TrackingFn } from '@change/core/react/tracking';
import type { UtilityContext } from '@change/core/react/utilityContext';
import { openWindow } from '@change/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

function getFacebookAppId({ environment }: UtilityContext): string {
	const env = (environment.getDemoEnvironment() as SubEnvironment) || environment.getEnvironment();
	return facebookAppIds[env] || facebookAppIds.development;
}

export function shareFacebookMessenger(
	url: string,
	{ trackingEvents, trackingData }: Options,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	openWindow(
		`fb-messenger://share/?${qs.stringify(
			{ link: url, app_id: getFacebookAppId(utilityContext) },
			{ encodeValuesOnly: true },
		)}`,
	);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
