import { useEffect, useMemo } from 'react';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

import { usePromotedTrackingDataForCurrentPetition } from './promotedTrackingDataForCurrentPetition';
import type { PromotedTrackingData } from './types';

export function usePromotedTrackingDataWithQueryParams(): PromotedTrackingData | undefined {
	const queryPromotionToken = useQueryParamValue('pt');
	const queryPlacement = useQueryParamValue('source_location');

	const [localstoragePromotedData, setLocalstoragePromotedData] = usePromotedTrackingDataForCurrentPetition();

	const promotionToken = queryPromotionToken || localstoragePromotedData?.token;
	const promotionPlacement = queryPlacement || localstoragePromotedData?.placement;

	const promotedData = useMemo(() => {
		if (!promotionToken) return undefined;
		return {
			token: promotionToken,
			placement: promotionPlacement,
		};
	}, [promotionToken, promotionPlacement]);

	useEffect(() => {
		if (promotionToken && localstoragePromotedData?.token !== promotionToken)
			setLocalstoragePromotedData({ token: promotionToken, placement: queryPlacement });
	}, [promotionToken, localstoragePromotedData, queryPlacement, setLocalstoragePromotedData]);

	return promotedData;
}
