import { useCallback, useEffect } from 'react';

import { useTracking } from '@change/core/react/tracking';

export function useTrackBannerActionClick(location: string, petitionId: string): () => void {
	const track = useTracking();

	return useCallback(() => {
		void track('starter_dash_prompt_banner_click', {
			context: location,
			destination_petition_id: petitionId,
		});
	}, [track, location, petitionId]);
}

export function useTrackBannerView(location: string, petitionId: string): void {
	const track = useTracking();

	useEffect(() => {
		void track('starter_dash_prompt_banner_view', {
			context: location,
			destination_petition_id: petitionId,
		});
	}, [location, petitionId, track]);
}

export function useTrackBannerClose(location: string, petitionId: string): () => void {
	const track = useTracking();

	return useCallback(() => {
		void track('starter_dash_prompt_banner_close', {
			context: location,
			destination_petition_id: petitionId,
		});
	}, [track, location, petitionId]);
}
