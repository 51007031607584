import React, { useEffect } from 'react';

import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';

import { useTracking } from '@change/core/react/tracking';
import { ButtonLink } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { Image } from '@change/design-system/components/media';
import { iconClose } from '@change/design-system/icons';
import { Box, Container, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { LocalizedCountsConfig } from './hooks/useLayout';
import { useLayout } from './hooks/useLayout';
import type { ExperimentTextProps } from './type';

type Props = {
	variant: string;
	localizedCountsConfig: LocalizedCountsConfig;
	onClickClose: () => void;
	onClickSupport: () => void;
	url: string;
};

function renderExperimentTextVariant(experimentData: ExperimentTextProps | null): React.JSX.Element | null {
	if (!experimentData) return null;
	if (experimentData.variant === 'variant_2') return null;

	const { name, signatureCount } = experimentData;

	if (experimentData.variant === 'variant_3') {
		if (!signatureCount) return null;

		const { locationName } = experimentData;
		const formattedName = name ? `${capitalize(name)},` : '';
		const formattedLocationName = startCase(locationName);
		const formattedSignatureCount = signatureCount.toLocaleString();

		return (
			<Text size="large">
				<strong>{`${formattedName} ${formattedLocationName} is making a difference on Change.org. `}</strong>
				{`More than ${formattedSignatureCount} people are taking action on the issues that matter most. Help support your community and their efforts with a contribution today.`}
			</Text>
		);
	}

	return (
		<Text size="large">
			<strong>
				{name ? `${capitalize(name)}, you have helped ` : 'You have helped '}
				<Text color="primary-changeRed" size="large">
					{(signatureCount ?? 0 > 1) ? signatureCount?.toLocaleString() : 'a'} petition
					{(signatureCount ?? 0 > 1) ? 's' : ''}
				</Text>{' '}
				gain momentum.
			</strong>{' '}
			Your impact is just getting started. Contribute today to support turning your signature into a victory.
		</Text>
	);
}

export function MembershipOnsitePlacements(props: Props): React.JSX.Element | null {
	const { variant, localizedCountsConfig, onClickClose, onClickSupport, url } = props;
	const experimentData = useLayout(localizedCountsConfig, variant);
	const track = useTracking();
	const textVariant = renderExperimentTextVariant(experimentData);
	useEffect(() => {
		if (textVariant) {
			void track('membership_awareness_banner_shown');
		}
		// only send the shown event once per page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!textVariant) return null;

	return (
		<Box
			sx={{
				backgroundColor: '#FAF7ED',
				padding: 24,
				borderWidth: 1,
				borderColor: 'neutral-grey100',
				borderStyle: 'solid',
			}}
		>
			<Container variant="small">
				<Flex
					sx={{
						flexDirection: ['column', 'row'],
						width: '100%',
						alignItems: 'flex-start',
						gap: 16,
					}}
				>
					<Box sx={{ display: ['none', 'block'] }}>
						<Image
							alt="support-change-image"
							src="https://change-assets.s3.amazonaws.com/membership/Heart+hands.png"
							sx={{ maxWidth: '75%' }}
						/>
					</Box>
					<Flex>
						<Box sx={{ display: ['block', 'none'] }}>
							<Image
								alt="support-change-image"
								src="https://change-assets.s3.amazonaws.com/membership/Heart+hands.png"
								sx={{ maxWidth: '75%' }}
							/>
						</Box>
						{textVariant}
						<Box sx={{ display: ['block', 'none'] }}>
							<Icon
								data-testid="close-icon"
								icon={iconClose}
								size="xl"
								sx={{ cursor: 'pointer' }}
								onClick={onClickClose}
							/>
						</Box>
					</Flex>
					<ButtonLink
						to={url}
						sx={{
							width: ['100%', 'fit-content'],
							whiteSpace: 'nowrap',
						}}
						onClick={onClickSupport}
						data-testid="support-change-button"
					>
						<Text>Support Change</Text>
					</ButtonLink>
					<Box sx={{ display: ['none', 'block'] }}>
						<Icon
							data-testid="close-icon"
							icon={iconClose}
							size="xl"
							sx={{ cursor: 'pointer' }}
							onClick={onClickClose}
						/>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
}
