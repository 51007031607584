import { InlineMessage } from '@change/design-system/components/alerts';

import type { SupporterComment } from '../../shared/types';

export function DeleteSuccessMessage({ comment }: { comment: SupporterComment }): React.JSX.Element {
	return (
		<InlineMessage variant="success" title="Success" mb={8}>
			{comment.__typename === 'PetitionVideoComment' ? 'Video' : 'Comment'} successfully removed.
		</InlineMessage>
	);
}
export function DeleteErrorMessage(): React.JSX.Element {
	return (
		<InlineMessage variant="warning" title="Delete failed" mb={8}>
			Something went wrong while deleting.
		</InlineMessage>
	);
}

export function DeleteMessage({
	message,
	comment,
}: {
	message: 'success' | 'error' | undefined;
	comment: SupporterComment;
}): React.JSX.Element | null {
	if (message === 'success') return <DeleteSuccessMessage comment={comment} />;
	if (message === 'error') return <DeleteErrorMessage />;
	return null;
}
