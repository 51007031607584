import type { ServerError } from '@apollo/client';
import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	GammaPetitionDetailsPageTrackPromotionEventMutation,
	GammaPetitionDetailsPageTrackPromotionEventMutationVariables,
} from './promotionEvent.graphql';

const QUERY = gql`
	mutation GammaPetitionDetailsPageTrackPromotionEvent($input: TrackPromotedEventInput!) {
		trackPromotedEvent(input: $input) {
			success
		}
	}
`;

type EventType = GammaPetitionDetailsPageTrackPromotionEventMutationVariables['input']['eventType'];
type EventData = Omit<GammaPetitionDetailsPageTrackPromotionEventMutationVariables['input'], 'eventType'>;

function getReportParams(error: unknown) {
	if (error instanceof Error) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		if ((error as any).statusCode) {
			const response = error as ServerError;
			return {
				status: response.statusCode,
				message: response.message,
			};
		}
		return {
			message: error.message,
		};
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	if ((error as any).status) {
		const response = error as Response;
		return {
			status: response.status,
			message: response.statusText,
		};
	}
	return {};
}

export async function trackPromotionEvent(
	eventType: EventType,
	eventData: EventData,
	{ gql: { fetch }, errorReporter: { report } }: UtilityContext,
): Promise<void> {
	try {
		await fetch<
			GammaPetitionDetailsPageTrackPromotionEventMutation,
			GammaPetitionDetailsPageTrackPromotionEventMutationVariables
		>({
			query: QUERY,
			variables: {
				input: {
					eventType,
					...eventData,
				},
			},
		});
	} catch (error) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		void report({
			error: 'Error tracking promotion event',
			params: getReportParams(error),
		});
	}
}
