import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import type { MandatoryResponsiveArrayValue, MandatoryResponsiveValue } from '@change/design-system/theme';
import { normalizeResponsiveValue, useBreakpoints } from '@change/design-system/theme';

type Props = {
	url: MandatoryResponsiveValue<string>;
	// TODO: might be part of React 18, and therefore could potentially be removed after bump
	fetchPriority?: 'high' | 'low' | 'auto';
};

function isSrcArray(src: MandatoryResponsiveValue<string>): src is MandatoryResponsiveArrayValue<string> {
	return Array.isArray(src);
}

type SrcWithMaxWidth = {
	src: string;
	minWidth: string | undefined;
	maxWidth: string | undefined;
};

function genMinWidth(width: string) {
	return width.replace(/^(\d+)(.*)$/, (_m, widthNumber: string, unit: string) => {
		return `${parseInt(widthNumber, 10) + 1}${unit}`;
	});
}

function genMedia({ minWidth, maxWidth }: Omit<SrcWithMaxWidth, 'src'>) {
	if (minWidth && maxWidth) return `(min-width: ${minWidth}) and (max-width: ${maxWidth})`;
	if (minWidth) return `(min-width: ${minWidth})`;
	if (maxWidth) return `(max-width: ${maxWidth})`;
	return '';
}

/**
 * Can be used to preload above-the-fold images for better FCP/LCP
 */
export function ImagePreload({ url: src, fetchPriority: fetchpriority }: Props): JSX.Element {
	const breakpoints = useBreakpoints();

	if (!isSrcArray(src) || src.length < 2) {
		return (
			<Helmet>
				<link
					rel="preload"
					href={isSrcArray(src) ? src[0] : src}
					as="image"
					// all this so we can have proper camel case without having React complaining...
					{...(fetchpriority ? { fetchpriority } : {})}
				/>
			</Helmet>
		);
	}

	const srcWithMaxWidthArray = normalizeResponsiveValue(src)
		.map(
			(srcItem, idx): SrcWithMaxWidth => ({
				src: srcItem,
				minWidth: idx ? genMinWidth(breakpoints[idx - 1]) : undefined,
				maxWidth: breakpoints[idx],
			}),
		)
		.reduce<SrcWithMaxWidth[]>((acc, srcWithMaxWidth) => {
			if (acc.length && srcWithMaxWidth.src === acc[acc.length - 1].src) {
				// eslint-disable-next-line no-param-reassign
				acc[acc.length - 1] = {
					...acc[acc.length - 1],
					maxWidth: srcWithMaxWidth.maxWidth,
				};
			} else {
				acc.push(srcWithMaxWidth);
			}
			return acc;
		}, []);
	return (
		<Helmet>
			{srcWithMaxWidthArray.map(({ src: srcSet, ...rest }, idx) => (
				<link
					// eslint-disable-next-line react/no-array-index-key
					key={idx}
					rel="preload"
					href={srcSet}
					as="image"
					media={genMedia(rest)}
					// all this so we can have proper camel case without having React complaining...
					{...(fetchpriority ? { fetchpriority } : {})}
				/>
			))}
		</Helmet>
	);
}
