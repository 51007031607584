import { useMemo } from 'react';

import { useTracking } from '@change/core/react/tracking';

import type { LoginMethod, LoginOrJoinMethod, LoginType } from 'src/app/shared/utils/loginOrJoin';
import { getMethod } from 'src/app/shared/utils/loginOrJoin';

import { useTrackingCommonProps } from './useTrackingCommonProps';

type SignupProps = {
	userId: string | undefined;
	loginMethod: Extract<LoginMethod, 'facebook' | 'google'>;
	loginOrJoinMethod: Extract<LoginOrJoinMethod, 'manual_fb' | 'manual_google'>;
	loginType: Extract<LoginType, 'facebook_signup' | 'google_signup'>;
	userFlow: string;
};

type SuccessfulLoginOrJoinProps = Omit<SignupProps, 'loginType'> & {
	loginType: Extract<LoginType, 'facebook_login' | 'facebook_signup' | 'google_login' | 'google_signup'>;
	newUser: boolean;
};

type Result = {
	trackSignupSocial: (props: SignupProps) => void;
	trackSuccessfulLoginOrJoinSocial: (props: SuccessfulLoginOrJoinProps) => void;
};

function getSuccessProps({
	userId,
	loginMethod,
	loginOrJoinMethod,
	loginType,
}: SignupProps | SuccessfulLoginOrJoinProps) {
	return {
		user_id: userId === undefined ? undefined : parseInt(userId, 10),
		connected_to_facebook: loginMethod === 'facebook',
		google_logged_in: loginMethod === 'google',
		connected_to_google: loginMethod === 'google',
		login_or_join_method: loginOrJoinMethod,
		fb_logged_in: loginMethod === 'facebook',
		login_type: loginType,
		has_set_password: false,
	};
}

export function useLoginOrJoinSuccessTracking(): Result {
	const track = useTracking();
	const getCommonProps = useTrackingCommonProps();

	return useMemo(
		() => ({
			trackSignupSocial: (props: SignupProps) => {
				void track('signup', {
					...getCommonProps(),
					...getSuccessProps(props),
					new_user: true,
					context: 'sign_up',
					method: getMethod(props.loginMethod),
					user_flow: props.userFlow,
				});
			},

			trackSuccessfulLoginOrJoinSocial: (props: SuccessfulLoginOrJoinProps) => {
				void track('successful_login_or_join', {
					...getCommonProps(),
					...getSuccessProps(props),
					new_user: props.newUser,
					context: props.newUser ? 'sign_up' : 'log_in',
					user_flow: props.userFlow,
				});
			},
		}),
		[getCommonProps, track],
	);
}
