import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'news-and-guides-list',
		/*
			TODO ?category=guides should be replace with a different path
			Once that's done, we can stop forcing external navigation on links and redirects within this folder
		 */
		path: '/l/:countryCode',
		component: loadable(async () => import(/* webpackChunkName: "pages/news_and_guides_list" */ './index'), {
			exportName: 'NewsAndGuidesList',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			enabled: true,
			seo: true,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					lifetimeInSeconds: 5 * 60,
					queryParams: ['category'],
				},
			},
		},
	},
];
