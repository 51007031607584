import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';
import type { SessionUser } from '@change/core/session';
import { Button } from '@change/design-system/components/actions';
import { InlineMessage } from '@change/design-system/components/alerts';
import { Input } from '@change/design-system/components/forms';
import { Box } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';

import { useRefreshSession } from 'src/app/shared/hooks/session';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { loginByEmail } from './api/loginByEmail';

type FormData = {
	email: string | undefined;
	password: string | undefined;
};

export const useLoginModal = createModalHook<{ email?: string }, { user: SessionUser }>({
	name: 'LoginModal',
	title: 'Log Into Change.org',
	body: function LoginModalBody({ options, closeModal }) {
		const [{ email, password }, setFormData] = useState<FormData>({ email: options.email || '', password: '' });
		const [formState, setFormState] = useState<{ status: 'loading' } | { status: 'error'; error: string } | undefined>(
			undefined,
		);
		const utilityContext = useUtilityContext();
		const refreshSession = useRefreshSession();

		const submit = async () => {
			if (!email || !password || (formState && isLoading(formState))) return;
			setFormState({ status: 'loading' });
			try {
				await loginByEmail({ email, password }, utilityContext);
				const newSession = await refreshSession();
				if (!newSession.user) throw new Error('Failed to retrieved new session user');
				closeModal({ user: newSession.user });
			} catch (e) {
				setFormState({ status: 'error', error: (e as Error).message });
			}
		};

		return (
			<Box>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						void submit();
					}}
				>
					{formState && isError(formState) && (
						<InlineMessage variant="error" mb={8}>
							{formState.error}
						</InlineMessage>
					)}
					<Input
						id="email"
						label="Email"
						value={email}
						data-testid="login-modal-email"
						onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData((data) => ({ ...data, email: e.target.value }))}
					/>
					<Input
						type="password"
						id="password"
						label="Password"
						value={password}
						data-testid="login-modal-password"
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setFormData((data) => ({ ...data, password: e.target.value }))
						}
					/>
					<Button
						variant="primary"
						type="submit"
						size="medium"
						disabled={!email || !password}
						loading={!!formState && isLoading(formState)}
						mt={16}
					>
						Log In
					</Button>
				</form>
			</Box>
		);
	},
});
