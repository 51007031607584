import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { TabLink, TabLinks } from '@change/design-system/components/navigation';

type NavigationTabsProps = {
	selected?: 'about' | 'impact' | 'careers' | 'team';
};

export function NavigationTabs({ selected }: NavigationTabsProps): JSX.Element | null {
	return (
		<TabLinks selected={selected || ''}>
			<TabLink name="about" to="/about">
				<Translate value="fe.components.brand_pages_navbar.about" />
			</TabLink>
			<TabLink name="impact" to="/impact">
				<Translate value="fe.components.brand_pages_navbar.impact" />
			</TabLink>
			<TabLink name="careers" to="/careers">
				<Translate value="fe.components.brand_pages_navbar.careers" />
			</TabLink>
			<TabLink name="team" to="/about/team">
				<Translate value="fe.components.brand_pages_navbar.team" />
			</TabLink>
		</TabLinks>
	);
}
