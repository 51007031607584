import React from 'react';

import { Icon } from '@change/design-system/components/icon';
import { iconFavorite, iconFavoriteBorder } from '@change/design-system/icons';

type LikeIconProps = {
	commentId: string;
};

export function LikeIcon({ commentId }: LikeIconProps): React.JSX.Element {
	return (
		<Icon
			icon={iconFavoriteBorder}
			size="l"
			sx={{
				color: 'primary-black',
			}}
			data-testid={`supporter-comment-${commentId}-like-icon`}
		/>
	);
}
export function UnLikeIcon({ commentId }: LikeIconProps): React.JSX.Element {
	return (
		<Icon
			icon={iconFavorite}
			size="l"
			sx={{
				color: 'primary-changeRed',
			}}
			data-testid={`supporter-comment-${commentId}-unlike-icon`}
		/>
	);
}
