import type { UtilityContext } from '@change/core/react/utilityContext';

import { FCM_PETITION_PAGE_CONTACT_DM } from 'src/app/shared/fcm/configs';

export async function getContactDmConfig(utilityContext: UtilityContext): Promise<{ dmContactsToFetch: string[] }> {
	const { contactDmConfig } = await utilityContext.fcm.get({ contactDmConfig: FCM_PETITION_PAGE_CONTACT_DM });

	const dmContactsToFetch = [];

	if (contactDmConfig?.emailEnabled) dmContactsToFetch.push('email');
	if (contactDmConfig?.phoneEnabled) dmContactsToFetch.push('phone');

	return { dmContactsToFetch };
}
