import type { JSX } from 'react';
import { useEffect } from 'react';

import { useRouteError } from 'react-router';

import { Redirect } from '@change/core/react/router';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { buildRedirectErrorUrl, HttpError, RedirectError, UnauthorizedError } from 'src/shared/error';

import { DefaultLayout } from 'src/app/app/layout';
import { useLoginRedirectUrl } from 'src/app/shared/hooks/auth';
import { useQueryString } from 'src/app/shared/hooks/location';

import { ErrorPageContainer } from './ErrorPage';

export function ErrorBoundary(): JSX.Element {
	const error = useRouteError();
	const utilityContext = useUtilityContext();

	useEffect(() => {
		if (error instanceof HttpError) return;
		if (error instanceof RedirectError) return;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
		void utilityContext.errorReporter.report(error as any);
	}, [error, utilityContext]);

	if (error instanceof RedirectError) {
		return <ErrorBoundaryRedirect error={error} />;
	}
	if (error instanceof UnauthorizedError) {
		return <ErrorBoundaryLoginRedirect />;
	}

	return (
		<DefaultLayout>
			<ErrorPageContainer status={error instanceof HttpError ? error?.code : 500} />
		</DefaultLayout>
	);
}

function ErrorBoundaryRedirect({ error }: { error: RedirectError }) {
	const query = useQueryString();

	return <Redirect to={buildRedirectErrorUrl(error, query)} permanent={error.permanent} reason={error.reason} />;
}

function ErrorBoundaryLoginRedirect() {
	const url = useLoginRedirectUrl();

	return <Redirect to={url} />;
}
