import type { JSX } from 'react';

import { stripHtmlTags } from '@change/core/html';
import { Box, Flex } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

export type BodyProps = {
	description: string;
	title: string;
};

export function Body({ description, title }: BodyProps): JSX.Element {
	return (
		<Flex sx={{ flexDirection: 'column', rowGap: 8, flexGrow: 1 }} p={[16, 24]}>
			<Heading as="div" size="h4" ellipsis>
				{title}
			</Heading>
			<Box as="div">
				<Text lineClamp={4} as="span">
					{stripHtmlTags(description)}
				</Text>
			</Box>
		</Flex>
	);
}
