import qs from 'qs';

import type { Locale } from '@change/config/locales';
import { getTwitterHandle } from '@change/config/twitter';
import type { TrackingFn } from '@change/core/react/tracking';
import { openWindow } from '@change/core/window';

import { truncate } from 'src/app/shared/utils/string';

import { trackShareSuccess } from './track';

type Options = {
	countryCode?: string;
	locale?: Locale;
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareTwitter(
	url: string,
	comment: string,
	{ trackingEvents, trackingData, countryCode, locale }: Options,
	track: TrackingFn,
): void {
	const params = {
		text: truncate(comment),
		url,
		via: getTwitterHandle({ countryCode: countryCode || 'US', locale }),
	};
	const twitterUrl = `https://x.com/intent/post?${qs.stringify(params, { encodeValuesOnly: true })}`;
	openWindow(twitterUrl);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
