/* eslint-disable jsx-a11y/no-autofocus */
import type { JSX, PropsWithChildren } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { VisuallyHidden } from '@change/design-system/a11y';
import { Icon } from '@change/design-system/components/icon';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { iconFacebook } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { buttonResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import type { FacebookLoginResultData } from 'src/app/shared/hooks/auth';
import { useFacebookLoginOrSignup } from 'src/app/shared/hooks/auth';

type Props = {
	onSuccess: (data: FacebookLoginResultData) => void;
	onError: (reason: string) => void;
	loginType: string;
	signupContext?: string;
	disabled?: boolean;
	autoFocus?: boolean;
	onWebview?: () => void;
	optionalOnClickTrackProps?: Record<string, string | boolean>;
};

export function FacebookAuthButton({
	disabled,
	autoFocus,
	children,
	loginType,
	signupContext,
	onError,
	onSuccess,
	onWebview,
	optionalOnClickTrackProps,
}: PropsWithChildren<Props>): JSX.Element | null {
	const { attemptFacebookLogin, result } = useFacebookLoginOrSignup();

	const handleClick = useCallback(() => {
		void attemptFacebookLogin({ fbLoginType: loginType, signupContext, optionalOnClickTrackProps }, onWebview);
	}, [attemptFacebookLogin, loginType, onWebview, optionalOnClickTrackProps, signupContext]);

	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		switch (result.status) {
			case 'success':
				onSuccess(result.data);
				break;
			case 'error':
				onError(result.errorReason);
				break;
			case 'loading':
				setLoading(true);
				break;
			case 'cancelled':
				setLoading(false);
				break;
			default:
				break; // do nothing
		}
	}, [result, onError, onSuccess]);
	return (
		<button
			type="button"
			sx={{
				...buttonResetStyles,
				px: 2,
				height: 40,
				width: '100%',
				// Using this value to keep consistency between both buttons
				border: '1px solid #dadce0',
				borderRadius: 5,
				position: loading ? 'relative' : undefined,
				// Setting this because google forces its button to this max width
				maxWidth: 400,
			}}
			onClick={handleClick}
			disabled={disabled}
			autoFocus={autoFocus}
			data-testid="facebook-auth-button"
		>
			<Flex
				px={8}
				sx={{ flexDirection: 'row', alignItems: 'center', gap: 8, visibility: loading ? 'hidden' : undefined }}
			>
				<Icon size="xl" icon={iconFacebook} />
				<Text sx={{ fontSize: 14, lineHeight: 'inherit', flex: 1, textAlign: 'center' }} data-label>
					{children}
				</Text>
			</Flex>
			{loading && (
				<Flex
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Loader size={24} color="typography-lightPrimary" data-testid="facebook-auth-button-spinner" />
					{/* TODO translate label */}
					<VisuallyHidden>loading</VisuallyHidden>
				</Flex>
			)}
		</button>
	);
}
