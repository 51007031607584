import type { PrefetchContext, PrefetchedDataProps } from '@change/core/react/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchMembershipAwarenessCardDataFromApi, useMembershipAwarenessCardDataFromApi } from './data';
import type { MembershipAwarenessCardFcmState } from './fcm';
import { prefetchMembershipAwarenessCardFcm, useAsyncMembershipAwarenessCardFcm } from './fcm';
import type { MembershipAwarenessCardFcmExperimentsState } from './fcmExperiments';
import {
	prefetchMembershipAwarenessCardFcmExperiments,
	useAsyncMembershipAwarenessCardFcmExperiments,
} from './fcmExperiments';

export type MembershipAwarenessCardPrefetchedContext = Readonly<{
	data: Parameters<typeof useMembershipAwarenessCardDataFromApi>[0];
}>;

export type MembershipAwarenessCardCombinedState = MergedState<
	ReturnType<typeof useMembershipAwarenessCardDataFromApi>,
	MembershipAwarenessCardFcmState,
	MembershipAwarenessCardFcmExperimentsState
>;

export function useContext(
	prefetchedData?: MembershipAwarenessCardPrefetchedContext,
): MembershipAwarenessCardCombinedState {
	return useMergedStates(
		useMembershipAwarenessCardDataFromApi(prefetchedData?.data),
		useAsyncMembershipAwarenessCardFcm(),
		useAsyncMembershipAwarenessCardFcmExperiments(),
	);
}

export async function prefetchContext(context: PrefetchContext): Promise<MembershipAwarenessCardPrefetchedContext> {
	const [data] = await Promise.all([
		prefetchMembershipAwarenessCardDataFromApi(context),
		prefetchMembershipAwarenessCardFcm(context),
		prefetchMembershipAwarenessCardFcmExperiments(context),
	]);

	return { data };
}

export type MembershipAwarenessCardPrefetchedDataProps = PrefetchedDataProps<MembershipAwarenessCardPrefetchedContext>;
