import type { ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Link } from '@change/core/react/router';
import { Card, Separator } from '@change/design-system/components/content';
import { Flex } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';

import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Media } from './components/Media';

export type PetitionListCardProps = {
	href: string;
	description: string;
	photo?: { url: string; processing: boolean };
	title: string;
	userPhoto?: string;
	username: string;
	userLocation?: string;
	supporters: string;
	creation: string;
	isVictory: boolean;
	onClick?: () => void;
};

function PetitionListCardInner(
	{
		description,
		photo,
		title,
		href,
		userPhoto,
		username,
		userLocation,
		supporters,
		creation,
		isVictory,
		onClick,
	}: PetitionListCardProps,
	ref: ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
	return (
		<Link to={href} sx={linkResetStyles} data-qa="petition-list-card" onClick={onClick} ref={ref}>
			<Card sx={{ maxHeight: ['auto', null, 236], overflow: 'hidden', boxShadow: '0px 10px 20px 0px #18191F1A' }}>
				<Flex sx={{ flexDirection: ['column', null, 'row-reverse'], overflow: 'hidden', borderRadius: 'inherit' }}>
					<Media showVictory={isVictory} imageUrl={photo?.url} />
					<Flex sx={{ flexDirection: 'column', flex: 1 }}>
						<Body description={description} title={title} />
						<Separator color="neutral-grey600" sx={{ margin: 'auto 0 0' }} />
						<Footer
							userPhoto={userPhoto}
							username={username}
							userLocation={userLocation}
							supporters={supporters}
							creation={creation}
						/>
					</Flex>
				</Flex>
			</Card>
		</Link>
	);
}

export const PetitionListCard = forwardRef(PetitionListCardInner);
