import { getWindowSafe } from '@change/core/window';

import type { StorageEventsHandler } from './StorageEventsHandler';

export function listenToWindowEvents(handler: StorageEventsHandler, storage: Storage): void {
	getWindowSafe()?.addEventListener('storage', (event: StorageEvent) => {
		if (event.storageArea !== storage) {
			return;
		}
		// clear
		if (event.key === null) {
			handler.triggerAll();
			return;
		}
		handler.trigger(event.key);
	});
}
