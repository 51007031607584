import { useCallback, useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { useTrackView } from 'src/app/shared/hooks/tracking';
import { isLoaded } from 'src/app/shared/utils/async';

import type { BadgeProps } from '../../index';

type Result = ModelHookResult<
	{
		url: string;
		tooltip: string;
	},
	{
		onClick: () => void;
	}
>;

type Props = {
	sourceLocation: BadgeProps['sourceLocation'];
};

export function useMembershipBadge({ sourceLocation }: Props): Result {
	const track = useTracking();
	useTrackView('member_badge_view', { sourceLocation });
	const session = useSessionAsync();

	const { translate } = useI18n();

	const onClick = useCallback(() => {
		void track('member_badge_click', { sourceLocation });
	}, [track, sourceLocation]);

	const url = useMemo(() => {
		if (!isLoaded(session) || !session.value.hasMembership) {
			return `/member/join?source_location=${sourceLocation}`;
		}
		return `/impact?source_location=${sourceLocation}`;
	}, [session, sourceLocation]);

	return {
		data: {
			url,
			tooltip: translate('corgi.membership.badge.tooltip'),
		},
		actions: {
			onClick,
		},
	};
}
