import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		emailEnabled: { type: 'boolean' },
	},
});

export const FCM_STARTER_DASHBOARD_CONTACT_DM = createFcmConfig('starter_dashboard_contact_dm', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		emailEnabled: false,
	},
});
