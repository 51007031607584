// TODO: move to @change/config?

import type { Category, ReportType } from './types';

const CATEGORIES: readonly Category[] = [
	'dont_like',
	'spam',
	'infringes_rights',
	'abusive_hateful_content',
	'inappropriate_images',
	'child_abuse',
	'suicide_self_harm',
	'impersonation',
];
const COUNTRY_SPECIFIC_CATEGORIES: Partial<
	Readonly<Record<ReportType, Readonly<Record<string, readonly Category[]>>>>
> = {
	petition: {},
};

export function getCategories(type: ReportType, countryCode: string): readonly Category[] {
	const additional = COUNTRY_SPECIFIC_CATEGORIES[type] || {};
	return [...CATEGORIES, ...(additional[countryCode] || [])];
}

const CATEGORY_API_LABELS_DEFAULTS = {
	spam: 'Misleading or Spam',
	infringes_rights: 'Infringes on my rights',
	abusive_hateful_content: 'Abusive or hateful',
	inappropriate_images: 'Inappropriate images',
	child_abuse: 'Harmful to children',
	suicide_self_harm: 'Violence, suicide or self harm',
	impersonation: 'Impersonation',
};

const CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<Record<Category, string>>>> = {
	petition: {
		...CATEGORY_API_LABELS_DEFAULTS,
		dont_like: "I don't like this petition",
	},
	comment: {
		...CATEGORY_API_LABELS_DEFAULTS,
		dont_like: "I don't like this comment",
	},
	video_comment: {
		...CATEGORY_API_LABELS_DEFAULTS,
		dont_like: "I don't like this video",
	},
	generic: {
		...CATEGORY_API_LABELS_DEFAULTS,
		dont_like: "I don't like this content",
	},
};

export function getCategoryApiLabel(type: ReportType, category: Category): string {
	return CATEGORY_API_LABELS[type][category];
}
