import { withPrefetchedData } from '@change/core/react/prefetch';

import { MembershipAwarenessCardContainer as Layout } from './container';
import type { MembershipAwarenessCardPrefetchedDataProps } from './context';
import { MembershipAwarenessCardContextProvider, prefetchContext } from './context';

type MembershipAwarenessCardProps = MembershipAwarenessCardPrefetchedDataProps & { petitionId?: string };

export const MembershipAwarenessCard = withPrefetchedData(
	function MembershipAwarenessCardEntry({
		prefetchedData,
		petitionId,
	}: MembershipAwarenessCardProps): React.JSX.Element {
		return (
			<MembershipAwarenessCardContextProvider prefetchedData={prefetchedData} petitionId={petitionId}>
				<Layout />
			</MembershipAwarenessCardContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => prefetchContext(context),
	},
);
