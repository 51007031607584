import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		activatedPercentage: { type: 'number' },
		blocklist: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'string' },
				},
			},
		},
		openVideoCreationFromPetitionDetails: { type: 'boolean', optional: true },
	},
});

export const FCM_SUPPORTER_VOICES_DETAILS_PAGE = {
	name: 'supporter_voices_details_page',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		activatedPercentage: 0,
		blocklist: [],
		openVideoCreationFromPetitionDetails: false,
	} as ReturnType<typeof normalizer>,
};
