import type { EnvironmentUtils } from '@change/core/environment';
import type { EventTracker } from '@change/core/eventTracker';
import { createEventTracker as _createEventTracker } from '@change/core/eventTracker';
import type { Experiments } from '@change/core/experiments';
import type { Facebook } from '@change/core/facebook';
import type { HttpClient } from '@change/core/http';
import type { Optimizely } from '@change/core/optimizely';
import type { CsrfUtils, Session } from '@change/core/session';
import type { UserAgentUtils } from '@change/core/userAgent';

import webappInfo from 'src/webappInfo';

import { createFacebookDecorator } from './facebookDecorator';

type Options = Readonly<{
	csrf: CsrfUtils;
	session: Session;
	environment: EnvironmentUtils;
	optimizely: Optimizely;
	experiments: Experiments;
	userAgent: UserAgentUtils;
	http: HttpClient;
	facebook: Facebook;
}>;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Navigator {
		standalone: boolean | undefined;
	}
}

export function createEventTracker({
	csrf,
	environment,
	session,
	optimizely,
	experiments,
	userAgent,
	http,
	facebook,
}: Options): EventTracker {
	const {
		country: { countryCode },
		locale: { localeCode: locale },
	} = session;

	return _createEventTracker({
		environment: environment.getEnvironment(),
		webappInfo,
		sessionInfo: { loginState: session.loginState, locale, countryCode },
		commonProperties: {
			...session.trackingData,
		},
		decorators: [optimizely, experiments, userAgent, createFacebookDecorator({ session, facebook })],
		listeners: [optimizely],
		post: http.post.bind(http),
		sendBeacon: http.sendBeacon.bind(http),
		getCsrfToken: csrf.getCsrfToken.bind(csrf),
		experimentOverrides: experiments.overrides,
		defaultLoggingEnabled: environment.getEnvironment() === 'development',
		// using sendBeacon when possible due to apparent issues with fetch.keepalive
		httpMode: 'beacon',
	}).decorateWindow();
}
