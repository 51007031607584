import type { JSX } from 'react';

import type { TranslationKey } from '@change/core/i18n';
import { Translate, useI18n } from '@change/core/react/i18n';
import { Image } from '@change/design-system/components/media';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

type Props = {
	text: TranslationKey;
	icon?: string;
	accessibleText?: TranslationKey;
};

export function Instruction({ text, icon, accessibleText }: Props): JSX.Element {
	const { translate } = useI18n();
	return (
		<Flex sx={{ alignItems: 'center' }}>
			<Text mr={4} aria-hidden={!!accessibleText}>
				<Translate value={text} />
			</Text>
			{icon && accessibleText && <Image src={icon} alt={translate(accessibleText)} />}
		</Flex>
	);
}
