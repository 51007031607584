import type { UtilityContext } from '@change/core/react/utilityContext';

import { NotFoundError } from 'src/shared/error';

import {
	PetitionDetailsPageDecisionMakerInfo,
	type PetitionDetailsPageDecisionMakerInfoQuery,
	type PetitionDetailsPageDecisionMakerInfoQueryVariables,
} from './decisionMaker.graphql';

type Options = {
	slugOrId: string;
	decisionMakerHash?: string | null;
};

export async function isDecisionMaker(
	{ gql: { fetch } }: UtilityContext,
	{ slugOrId, decisionMakerHash }: Options,
): Promise<boolean> {
	const { data } = await fetch<
		PetitionDetailsPageDecisionMakerInfoQuery,
		PetitionDetailsPageDecisionMakerInfoQueryVariables
	>({
		query: PetitionDetailsPageDecisionMakerInfo,
		variables: {
			slugOrId,
			decisionMakerHash: decisionMakerHash || '__dm_hash_placeholder__', // necessary to satisfy GraphQL validation
			fetchDmByHash: !!decisionMakerHash,
		},
		rejectOnError: true,
	});
	const petition = data?.petition;
	if (!petition) {
		throw new NotFoundError();
	}
	return petition.viewerIsDecisionMaker || !!petition.decisionMakerByHash;
}
