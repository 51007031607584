import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-petition-update-moderation',
		path: '/dashboard/petition_update_moderation',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_petitionUpdateModeration" */ './index'), {
			exportName: 'PetitionUpdateModeration',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['campaigns', 'product'],
		},
		frame: true,
	},
];
