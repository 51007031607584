import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'account-settings-contributions-cancel-confirmation',
		path: '/account_settings/contributions/cancel_confirmation',
		component: loadable(
			async () => import(/* webpackChunkName: "pages/account_settings_contributions_cancel_confirmation" */ './index'),
			{
				exportName: 'CancelConfirmation',
				fallback: Loading,
			},
		),
		restrictedAccess: {
			authOnly: true,
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
