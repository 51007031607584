import { useCallback, useMemo } from 'react';

import { useLocalStorageValueAsync } from 'src/app/shared/hooks/storage';

type PromotedTrackingData = Readonly<{ token: string; placement: string | undefined }>;

type PromotedTrackingDataHookResult = [PromotedTrackingData | undefined, (data: PromotedTrackingData) => void];

function parse(str: string | null | undefined) {
	if (!str) return undefined;
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return JSON.parse(str) as PromotedTrackingData;
	} catch (e) {
		return undefined;
	}
}
export function usePromotedTrackingData(petitionId: string): PromotedTrackingDataHookResult {
	const [promotedDataStr, setPromotedDataStr] = useLocalStorageValueAsync(`petition_${petitionId}`);

	const promotedData = useMemo(() => parse(promotedDataStr), [promotedDataStr]);

	const setPromotedData = useCallback(
		(data: PromotedTrackingData) => {
			setPromotedDataStr(JSON.stringify(data));
		},
		[setPromotedDataStr],
	);

	return [promotedData, setPromotedData];
}
