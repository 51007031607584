import type { PropsWithChildren } from 'react';

import { TrackingContext } from '@change/core/react/tracking';

import { useIsMembershipEligibleUser } from 'src/app/shared/hooks/membership';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { MembershipAwarenessCardDataContextProvider } from './data';
import { MembershipAwarenessCardFcmContextProvider } from './fcm';
import { MembershipAwarenessCardFcmExperimentsContextProvider } from './fcmExperiments';
import type { MembershipAwarenessCardPrefetchedContext } from './hook';
import { useContext } from './hook';

type Props = {
	prefetchedData: MembershipAwarenessCardPrefetchedContext | undefined;
	petitionId?: string;
};

export function MembershipAwarenessCardContextProvider({
	children,
	prefetchedData,
	petitionId,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const contextState = useContext(prefetchedData);
	const session = useSessionAsync();
	const isMembershipEligibleUser = useIsMembershipEligibleUser();

	// not displaying a loader when in a loading state because it might result in nothing
	if (!isLoaded(contextState) || !isLoaded(session)) return null;

	const { data, fcm, fcmExperiments } = contextState;

	if (!isMembershipEligibleUser || (session.value.user?.totalSignatureCount ?? 0) < 1) return null;

	return (
		<MembershipAwarenessCardDataContextProvider data={data}>
			<MembershipAwarenessCardFcmContextProvider fcm={fcm}>
				<MembershipAwarenessCardFcmExperimentsContextProvider fcmExperiments={fcmExperiments}>
					<TrackingContext properties={{ petition_id: petitionId }}>{children}</TrackingContext>
				</MembershipAwarenessCardFcmExperimentsContextProvider>
			</MembershipAwarenessCardFcmContextProvider>
		</MembershipAwarenessCardDataContextProvider>
	);
}
