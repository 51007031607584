import type {
	CommentReport,
	GenericReport,
	PetitionReport,
	Report,
	ReportApiPayload,
	VideoCommentReport,
} from './types';

const commonPayload = (report: Report): { abuse_category: string; abuse_sub_category: string; reason: string } => ({
	abuse_category: report.category,
	abuse_sub_category: report.subCategory,
	reason: report.reason,
});

const petitionPayload = (report: PetitionReport): ReportApiPayload => ({
	item_type: 'petition',
	...commonPayload(report),
	petition_id: report.petitionId,
	petition_url: report.petitionUrl,
	item_id: report.petitionId,
});
const commentPayload = (report: CommentReport): ReportApiPayload => ({
	item_type: 'comment',
	...commonPayload(report),
	comment_id: report.commentId,
	comment_url: report.commentUrl,
	petition_url: report.petitionUrl,
	item_id: report.commentId,
});
const videoCommentPayload = (report: VideoCommentReport): ReportApiPayload => ({
	item_type: 'video_comment',
	...commonPayload(report),
	comment_id: report.commentId,
	comment_url: report.commentUrl,
	petition_url: report.petitionUrl,
	item_id: report.commentId,
});
const genericPayload = (report: GenericReport): ReportApiPayload => ({
	item_type: 'generic',
	...commonPayload(report),
	item_id: 'NA',
	...(report.pageUrl && { decision_maker_url: report.pageUrl }),
});

export function normalizeReportApiPayload(report: Report): ReportApiPayload {
	switch (report.type) {
		case 'petition':
			return petitionPayload(report);
		case 'comment':
			return commentPayload(report);
		case 'video_comment':
			return videoCommentPayload(report);

		case 'generic':
			return genericPayload(report);
		default:
			throw new Error('unsupported report type');
	}
}
