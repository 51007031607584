import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		url: { type: 'string' },
	},
});
export const FCM_PETITION_STARTER_MEDIA_INQUIRY_CONFIG = {
	name: 'petition_starter_media_inquiry',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		url: '',
	} as ReturnType<typeof normalizer>,
};
