import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { canEditPetition, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'create-petition-update',
		path: '/p/:slug/u/new',
		component: loadable(async () => import(/* webpackChunkName: "pages/petition-update" */ './index'), {
			exportName: 'CreatePetitionUpdateView',
			fallback: Loading,
		}),
		priority: 10, // to be matched before /u/:id
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support', canEditPetition('slug')],
		},
		ssr: {
			enabled: false,
			seo: false,
		},
	},
	{
		id: 'edit-petition-update',
		path: '/p/:slug/u/:updateId/edit',
		component: loadable(async () => import(/* webpackChunkName: "pages/petition-update" */ './index'), {
			exportName: 'EditPetitionUpdateView',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support', canEditPetition('slug')],
		},
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
