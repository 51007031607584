import type { INotice } from '@change/core/errorReporter/common';

import { checkNoticeItemFileCurrentUrl } from './shared/currentUrl';

export function filterOutHttpAndRedirectErrors(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter(
		(error) =>
			!['NotFoundError', 'ForbiddenError', 'UnauthorizedError', 'UnderReviewError', 'RedirectError'].includes(
				error.type,
			),
	);
	return !!notice.errors && !!notice.errors.length;
}
function isBrowserExtensionFile(file: string): boolean {
	// webkit-masked-url are safari plugins (https://stackoverflow.com/questions/74197049/exception-caught-in-sentry-error-hidden-in-ywebkit-masked-url)
	return !!/^((chrome|safari|safari-web|moz)-extension|webkit-masked-url):\/\//.exec(file);
}

export function filterOutBrowserExtensions(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter(
		(error) =>
			!error.backtrace ||
			!error.backtrace.length ||
			!error.backtrace[0].file ||
			!isBrowserExtensionFile(error.backtrace[0].file),
	);
	return !!notice.errors && !!notice.errors.length;
}

function isQaaScriptFile(file: string): boolean {
	// testcafe script: https://github.com/DevExpress/testcafe-hammerhead
	return file.includes('/hammerhead.js');
}

export function filterOutQaaScripts(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter(
		(error) =>
			!error.backtrace ||
			!error.backtrace.length ||
			!error.backtrace[0].file ||
			!isQaaScriptFile(error.backtrace[0].file),
	);
	return !!notice.errors && !!notice.errors.length;
}

function isMiscError(message: string): boolean {
	return (
		// https://stackoverflow.com/questions/60174224/refenceerror-uet-is-not-defined
		// 3-8k daily errors
		message.includes('UET is not defined') ||
		// https://stackoverflow.com/questions/75536442/troubleshooting-non-error-promise-rejection-captured-with-value-object-not-fou
		// 9k daily errors
		message.includes('Object Not Found Matching Id') ||
		message.includes("Can't find variable: __firefox__") ||
		message.includes('window.__firefox__')
	);
}

export function filterOutMiscErrors(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter((error) => !isMiscError(error.message));
	return !!notice.errors && !!notice.errors.length;
}

// those seem to only be triggered by extensions or by applications displaying Change in a webview
export function filterOutEmptyFileNames(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter(
		(error) =>
			!error.backtrace ||
			!error.backtrace.length ||
			// only filter out reports with one backtrace item
			!(error.backtrace.length === 1 && !error.backtrace[0].file),
	);
	return !!notice.errors && !!notice.errors.length;
}

// those seem to only be triggered by extensions or by applications displaying Change in a webview
export function filterOutCurrentUrls(notice: INotice): boolean {
	// eslint-disable-next-line no-param-reassign
	notice.errors = notice.errors?.filter(
		(error) =>
			!error.backtrace ||
			!error.backtrace.length ||
			// only filter out reports with one backtrace item which is on the first line of the page
			!(
				error.backtrace.length === 1 &&
				error.backtrace[0].line === 0 &&
				checkNoticeItemFileCurrentUrl(error.backtrace[0].file)
			),
	);
	return !!notice.errors && !!notice.errors.length;
}
