import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { canEditPetition, Loading, petitionSlugCanonicalRedirect } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'share_for_starters',
		path: '/p/:slug/share_for_starters',
		beforeServerRender: async (context) => {
			await petitionSlugCanonicalRedirect(context);
		},
		component: loadable(async () => import(/* webpackChunkName: "pages/shareForStarters" */ './index'), {
			exportName: 'ShareForStartersWrapper',
			fallback: Loading,
		}),
		frame: { footer: false, header: true },
		restrictedAccess: {
			authOnly: true,
			role: canEditPetition('slug'),
		},
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
