import { useMemo } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import type { SignPetitionInput } from 'src/app/shared/api/signature';

type Result = Pick<SignPetitionInput, 'isMobile'>;

export function useUserAgentSignData(): Result {
	const { userAgent } = useUtilityContext();

	return useMemo(
		() => ({
			isMobile: ['mobile', 'tablet'].includes(userAgent.getParsedUserAgent().getDevice().type || ''),
		}),
		[userAgent],
	);
}
