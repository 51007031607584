import type { GuestSession } from '@change/core/session';

import type { HydrationData } from 'src/app/types';

const FALLBACK_SESSION: GuestSession = {
	loginState: 'GUEST',
	csrfToken: 'fake-csrf',
	user: null,
	uuid: '<unknown>',
	trackingData: {},
	locale: { localeCode: 'en-US' },
	country: { countryCode: 'US', membershipProgram: null },
	ipCountry: { countryCode: 'US' },
	experiments: null,
	cookiePrefs: {
		analytics: false,
		marketing: false,
		preferences: false,
	},
	cookiePrefsSaved: false,
	geoipData: {
		city: null,
		countryCode: null,
		postalCode: null,
		region: null,
	},
	hasMembership: false,
};

export function createFallbackSession({ hydrationData }: { hydrationData?: HydrationData }): GuestSession {
	const l10nFallback = hydrationData?.l10n;

	// if there is an error, we still want to use the locale/country computed by the SSR server
	return !l10nFallback
		? FALLBACK_SESSION
		: {
				...FALLBACK_SESSION,
				locale: { localeCode: l10nFallback.locale },
				country: { countryCode: l10nFallback.countryCode, membershipProgram: null },
			};
}
