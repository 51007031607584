import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { BanditsSharedRewardBanditMutation, BanditsSharedRewardBanditMutationVariables } from './reward.graphql';

type Input = {
	experimentName: string;
	variantName: string;
	increment?: number;
};

const query = gql`
	mutation BanditsSharedRewardBandit($input: BanditInput!) {
		rewardBandit(input: $input)
	}
`;

export async function rewardBandit(
	{ experimentName, variantName, increment }: Input,
	{ gql: { fetch } }: UtilityContext,
	{
		silent,
	}: {
		// if true, won't throw on error
		silent?: boolean;
	} = {},
): Promise<void> {
	let data: BanditsSharedRewardBanditMutation | null | undefined;
	try {
		const result = await fetch<BanditsSharedRewardBanditMutation, BanditsSharedRewardBanditMutationVariables>({
			query,
			variables: {
				input: {
					banditId: experimentName,
					variantName,
					increment: increment || null,
					rewardType: 'DEFAULT',
				},
			},
			important: true,
		});
		({ data } = result);
	} catch (err) {
		if (silent) return;
		throw new Error('Bandit reward failed');
	}

	if (silent) return;
	if (!data?.rewardBandit) {
		throw new Error('Bandit reward failed');
	}
}
