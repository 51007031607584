import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_MEMBERSHIP_BUTTON_AMOUNTS_EXPERIMENT = createFcmConfig('membership_button_amounts_experiment', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
				variant_1: {
					type: 'object',
					shape: {
						amounts: {
							type: 'array',
							shape: {
								type: 'number',
							},
						},
						defaultAmount: { type: 'number' },
					},
				},
				variant_2: {
					type: 'object',
					shape: {
						amounts: {
							type: 'array',
							shape: {
								type: 'number',
							},
						},
						defaultAmount: { type: 'number' },
					},
				},
			},
		}),
	),
	defaultValue: undefined,
});
