import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'about-business-model',
		path: '/about/business-model',
		component: loadable(async () => import(/* webpackChunkName: "pages/about_business_model" */ './index'), {
			exportName: 'Business',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: true,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					lifetimeInSeconds: 60 * 60,
				},
			},
		},
	},
];
