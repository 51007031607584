import qs from 'qs';

import type { TrackingFn } from '@change/core/react/tracking';
import { openWindow } from '@change/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareVk(
	url: string,
	title: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): void {
	const params = {
		title,
		url,
	};
	const vkUrl = `https://vk.com/share.php?${qs.stringify(params, { encodeValuesOnly: true })}`;
	openWindow(vkUrl);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
