import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import webappInfo from 'src/webappInfo';

import type { ShortUrlMutation, ShortUrlMutationVariables } from './getShortUrl.graphql';

export async function getShortUrl(longUrl: string, { gql: { fetch } }: UtilityContext): Promise<string> {
	if (webappInfo.proxyTarget?.root) {
		// link shortener currently doesn't support local-change
		// eslint-disable-next-line no-param-reassign
		longUrl = longUrl.replace(/^https?:\/\/[^/]*\//, `${webappInfo.proxyTarget?.root}/`);
	}

	const { data } = await fetch<ShortUrlMutation, ShortUrlMutationVariables>({
		query: gql`
			mutation ShortUrl($url: String!, $hashLength: Int!) {
				shortenLink(url: $url, hashLength: $hashLength) {
					shortUrl
					message
				}
			}
		`,
		variables: {
			url: longUrl,
			hashLength: 10,
		},
	});

	if (!data?.shortenLink?.shortUrl) {
		throw new Error(`Could not generate short url from ${longUrl} (reason: ${data?.shortenLink.message || 'unknown'})`);
	}
	return data.shortenLink.shortUrl;
}
