import type { UtilityContext } from '@change/core/react/utilityContext';

/*
 * Having multiple configs that relate to the same FC could cause inconsistencies due to the caching logic.
 * The same config object or the same generated hook should be used instead.
 */
export function reportMultipleConfigs(
	multipleConfigs: readonly string[],
	{ errorReporter: { report } }: UtilityContext,
): void {
	multipleConfigs.forEach((name) => {
		void report({
			error: `Multiple FCM configs for ${name}`,
			params: {
				name,
			},
		});
	});
}
