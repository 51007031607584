import React from 'react';

import { Button } from '@change/design-system/components/actions';
import { iconOutlinedFlag } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';

import { ReportAbuseLink } from 'src/app/shared/components/abuse';

import type { SupporterVideo } from '../../shared/types';

import { useReportVideo } from './hooks/useReportVideo';

type ReportVideoProps = {
	video: SupporterVideo;
	petitionId: string;
	petitionSlug: string;
};
export function ReportVideo({ video, petitionId, petitionSlug }: ReportVideoProps): React.JSX.Element | null {
	const {
		data: { canReportVideo },
		actions: { handleReportClick },
	} = useReportVideo({ petitionId, video });

	if (!canReportVideo) return null;

	return (
		<Flex onClick={handleReportClick}>
			<ReportAbuseLink
				type={video.videoUrl ? 'video_comment' : 'comment'}
				comment={{ id: video.id, likes: Number(video.likeCount), role: 'VIDEO_COMMENT' }}
				petitionId={petitionId}
				petitionSlug={petitionSlug}
			>
				<Button
					icon={iconOutlinedFlag}
					size="small"
					mode="icon"
					variant="secondary"
					aria-label={`report video from ${video.user?.shortDisplayName}`}
					data-testid={`supporter-video-${video.id}-report-button`}
					sx={{
						border: 'none',
						background: 'primary-greyBackground',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': {
							background: 'rgba(0,0,0,0.1)',
						},
					}}
				>
					Report
				</Button>
			</ReportAbuseLink>
		</Flex>
	);
}
