import { useKnownAvailableParams } from '@change/core/react/router';
import { useTracking } from '@change/core/react/tracking';

type Result = ModelHookResult<
	{
		link: string;
	},
	{
		handleOpenClick: () => void;
	}
>;

export function useOpenVideo({ petitionId, videoId }: { petitionId: string; videoId: string }): Result {
	const track = useTracking();
	const { slug } = useKnownAvailableParams<{ slug: string }>();

	const link = `/p/${slug}/v/${videoId}`;

	const handleOpenClick = () => {
		void track('supporter_voice_open_button_click', {
			petitionId,
			videoId,
		});
	};

	return {
		data: {
			link,
		},
		actions: {
			handleOpenClick,
		},
	};
}
