import React from 'react';

import { ButtonLink } from '@change/design-system/components/actions';
import { iconOpenInNew } from '@change/design-system/icons';

import type { SupporterVideo } from '../../shared/types';

import { useOpenVideo } from './hooks/useOpenVideo';

type LikeIconProps = {
	petitionId: string;
	video: SupporterVideo;
};

export function OpenVideo({ petitionId, video }: LikeIconProps): React.JSX.Element {
	const {
		data: { link },
		actions: { handleOpenClick },
	} = useOpenVideo({ videoId: video.id, petitionId });

	return (
		<ButtonLink
			to={link}
			size="small"
			mode="icon"
			icon={iconOpenInNew}
			variant="secondary"
			aria-label={`open video from ${video.user?.shortDisplayName}`}
			data-testid={`supporter-video-${video.id}-open-button`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleOpenClick}
		>
			Open video
		</ButtonLink>
	);
}
