import type { ChangeEvent, JSX } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { Input } from '@change/design-system/components/forms';
import { Avatar } from '@change/design-system/components/media';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { FormState } from '../../shared/types';
import { getError } from '../../utilities/getError';

export function LoginForm({
	userInfo,
	formState,
	email,
	setEmail,
	password,
	setPassword,
}: {
	userInfo: { avatarUrl: string; displayName: string; location: string } | undefined;
	formState: FormState | undefined;
	email: string;
	setEmail: (newEmail: string) => void;
	password: string;
	setPassword: (newPassword: string) => void;
}): JSX.Element {
	const { translate } = useI18n();
	return (
		<Box mb={16}>
			{userInfo && (
				<Box
					variant="bordered"
					px={8}
					sx={{
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderColor: 'neutral-grey100',
						borderWidth: '1px 1px 0 1px',
					}}
					data-testid="user-info-box"
				>
					<Flex sx={{ alignItems: 'center', height: 58 }}>
						<Box
							variant="bordered"
							sx={{
								width: 36,
								height: 36,
								borderWidth: 0,
								borderRadius: '50%',
								overflow: 'hidden',
							}}
							mr={8}
						>
							<Avatar src={`${userInfo.avatarUrl}`} alt="" />
						</Box>
						<Box sx={{ flex: '1' }}>
							<Text as="div" fontWeight="bold">
								{userInfo.displayName}
							</Text>
							<Text as="div" color="neutral-grey600">
								{userInfo.location}
							</Text>
						</Box>
					</Flex>
				</Box>
			)}
			{!userInfo && (
				<Input
					type="email"
					id="email"
					aria-label={translate('fe.components.login_or_join.email.placeholder')}
					placeholder={translate('fe.components.login_or_join.email.placeholder')}
					error={getError('email', formState)}
					value={email}
					data-testid="login-modal-email"
					onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
					sx={{
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderColor: 'neutral-grey100',
						borderWidth: '1px 1px 0 1px',
					}}
				/>
			)}
			<Input
				type="password"
				id="password"
				aria-label={translate('fe.components.login_or_join.password.placeholder')}
				placeholder={translate('fe.components.login_or_join.password.placeholder')}
				error={getError('password', formState)}
				value={password}
				data-testid="login-modal-password"
				onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
				sx={{ borderColor: 'neutral-grey100', borderRadius: '0 0 8px 8px' }}
			/>
		</Box>
	);
}
