import type { ServerError } from '@apollo/client';
import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { TrackPromotionImpressionMutation, TrackPromotionImpressionMutationVariables } from './api.graphql';

const QUERY = gql`
	mutation TrackPromotionImpression($input: TrackPromotedEventInput!) {
		trackPromotedEvent(input: $input) {
			success
		}
	}
`;

type EventData = Omit<TrackPromotionImpressionMutationVariables['input'], 'eventType'>;

function getReportParams(error: unknown) {
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	if (error instanceof Error) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		if ((error as any).statusCode) {
			const response = error as ServerError;
			return {
				status: response.statusCode,
				message: response.message,
			};
		}
		return {
			message: error.message,
		};
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	if ((error as any).status) {
		const response = error as Response;
		return {
			status: response.status,
			message: response.statusText,
		};
	}
	return {};
	/* eslint-enable @typescript-eslint/consistent-type-assertions */
}

export async function trackPromotionImpression(
	eventData: EventData,
	{ gql: { fetch }, errorReporter: { report } }: UtilityContext,
): Promise<void> {
	try {
		await fetch<TrackPromotionImpressionMutation, TrackPromotionImpressionMutationVariables>({
			query: QUERY,
			variables: {
				input: {
					eventType: 'IMPRESSION',
					...eventData,
				},
			},
		});
	} catch (error) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		void report({
			error: 'Error tracking promotion impression',
			params: getReportParams(error),
		});
	}
}
