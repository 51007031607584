import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import type { FormValues, ReportContext } from '../../shared/types';

import { reportAbuse } from './api';
import { normalizeForApi } from './normalize';
import { useReportAbuseTracking } from './useReportAbuseTracking';

export function useReportAbuse(reportContext: ReportContext): (formValues: FormValues) => Promise<void> {
	const utilityContext = useUtilityContext();
	const trackReport = useReportAbuseTracking(reportContext);

	return useCallback(
		async (formValues) => {
			try {
				await reportAbuse(normalizeForApi(reportContext, formValues), utilityContext);
				trackReport({ success: true });
			} catch (e) {
				trackReport({ success: false });
				throw e;
			}
		},
		[reportContext, trackReport, utilityContext],
	);
}
