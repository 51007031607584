import type { TrackingFn } from '@change/core/react/tracking';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: { success: string };
};

export function shareQRCode({ trackingEvents, trackingData }: Options, track: TrackingFn): void {
	// TODO: implement qrcode download action when qrcode generation is outside the context of a react component
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
