import { createMandatoryContext } from '@change/core/react/context';

import type { UserInfo } from 'src/app/pages/petitionGamma/shared/types';

export type PetitionDetailsPageUserInfo = UserInfo;

export const {
	Context: PetitionDetailsPageUserInfoContext,
	Provider: PetitionDetailsPageUserInfoContextProvider,
	useContext: usePetitionDetailsPageUserInfo,
} = createMandatoryContext<PetitionDetailsPageUserInfo | undefined, { userInfo: UserInfo }>(undefined, {
	name: 'PetitionDetailsPageUserInfoContext',
	processProviderProps: ({ userInfo }) => userInfo,
});
