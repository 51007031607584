import gql from 'graphql-tag';
import { CustomError } from 'ts-custom-error';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	AuthSharedLoginOrSignupWithFacebookMutation,
	AuthSharedLoginOrSignupWithFacebookMutationVariables,
} from './loginOrSignupByFacebook.graphql';

type LoginOrSignupWithFacebookInput = AuthSharedLoginOrSignupWithFacebookMutationVariables['loginOrSignupInput'];
type LoginOrSignupWithFacebookResponse = AuthSharedLoginOrSignupWithFacebookMutation['loginOrSignupWithFacebook'];

export class LoginOrSignupByFacebookError extends CustomError {
	readonly status?: LoginOrSignupWithFacebookResponse['status'];

	constructor(status?: LoginOrSignupWithFacebookResponse['status']) {
		super('Error on login by Facebook');

		this.status = status;
	}
}
export type LoginOrSignupWithFacebookContext = LoginOrSignupWithFacebookInput;

const loginOrSignupMutation = gql`
	mutation AuthSharedLoginOrSignupWithFacebook($loginOrSignupInput: LoginOrSignupWithFacebookInput!) {
		loginOrSignupWithFacebook(input: $loginOrSignupInput) {
			status
		}
	}
`;

export async function loginOrSignupByFacebook(
	loginOrSignupContext: LoginOrSignupWithFacebookContext,
	{ gql: { fetch } }: UtilityContext,
): Promise<'CONNECTED' | 'LOGGED_IN' | 'CREATED'> {
	let data: AuthSharedLoginOrSignupWithFacebookMutation | null | undefined;
	try {
		({ data } = await fetch<
			AuthSharedLoginOrSignupWithFacebookMutation,
			AuthSharedLoginOrSignupWithFacebookMutationVariables
		>({
			query: loginOrSignupMutation,
			variables: {
				loginOrSignupInput: loginOrSignupContext,
			},
			important: true,
		}));
	} catch (err) {
		throw new LoginOrSignupByFacebookError();
	}

	if (data?.loginOrSignupWithFacebook?.status === 'CONNECTED') return 'CONNECTED';
	if (data?.loginOrSignupWithFacebook?.status === 'CREATED') return 'CREATED';
	if (data?.loginOrSignupWithFacebook?.status === 'LOGGED_IN') return 'LOGGED_IN';

	throw new LoginOrSignupByFacebookError(data?.loginOrSignupWithFacebook?.status);
}
