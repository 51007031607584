import { useCallback, useMemo } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { likeSupporterVideo } from 'src/app/shared/api/supporterVoices';
import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import type { PageRenderingButton, SupporterComment } from '../../../../shared/types';
import { useLikeCommentState } from '../useLikeCommentState';
import { useLikeCommentTracking } from '../useLikeCommentTracking';

type Result = ModelHookResult<
	{
		isCommentLiked: boolean | null;
		commentLikeCount: number;
		isGuest: boolean;
	},
	{
		handleLikeClick: () => Promise<undefined>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useLikeComment({
	comment,
	petitionId,
	renderedOn,
}: {
	comment: SupporterComment;
	petitionId: string;
	renderedOn: PageRenderingButton;
}): Result {
	const { id } = comment;
	const loginStateBase = useLoginStateAsync();
	const loginState = isLoaded(loginStateBase) ? loginStateBase.value : undefined;

	const currentUserBase = useCurrentUserAsync();
	const currentUser = isLoaded(currentUserBase) && currentUserBase.value;

	const utilityContext = useUtilityContext();

	const {
		data: { isCommentLiked, commentLikeCount },
		actions: { updateLike },
	} = useLikeCommentState({ comment });

	const commentLikeTrackingData = useMemo(() => {
		return {
			petition_id: petitionId,
			comment_id: id,
			liker_id: currentUser ? currentUser?.id : undefined,
			page: renderedOn,
		};
	}, [petitionId, id, currentUser, renderedOn]);

	const {
		actions: { trackLikeComment, trackError },
	} = useLikeCommentTracking({ commentLikeTrackingData, loginState });

	const handleLikeClick = useCallback(async (): Promise<undefined> => {
		trackLikeComment('supporter_voice_like_click', {
			likeState: !isCommentLiked,
		});
		updateLike(!isCommentLiked);

		try {
			const likeResult = await likeSupporterVideo({ videoId: id, like: !isCommentLiked, utilityContext });
			if (likeResult?.likeComment.success) {
				trackLikeComment('supporter_voice_like_success', { likeState: !!likeResult.likeComment.liked });
			} else {
				updateLike(isCommentLiked);
				trackLikeComment('supporter_voice_like_failure', {
					likeState: !!likeResult?.likeComment.liked,
					errors: likeResult?.likeComment?.reason || 'an unknown reason',
				});
			}
		} catch (err) {
			updateLike(isCommentLiked);
			trackError('supporter_voice_like_error', err, {
				params: commentLikeTrackingData,
			});
		}
	}, [id, isCommentLiked, updateLike, trackLikeComment, utilityContext, trackError, commentLikeTrackingData]);

	return {
		data: {
			isGuest: loginState === 'GUEST',
			isCommentLiked,
			commentLikeCount,
		},
		actions: {
			handleLikeClick,
		},
	};
}
