import { useCallback, useMemo } from 'react';

import type { FeatureConfigInfo } from '@change/core/fcm';
import { useIsFirstAppRender } from '@change/core/react/ssr/render';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { createFcmHook } from 'src/app/shared/hooks/fcm';
import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { isLoading } from 'src/app/shared/utils/async';

import type { ExperimentName, FcmExperimentConfig, HookOptions, ResultState } from './types';
import { getExperimentName } from './types';

export function createFcmExperimentHook<
	T extends Record<string, FeatureConfigInfo>,
	EN extends ExperimentName<T>,
	KEY extends string = 'values',
>(
	{ fcmConfigs, isEnabled, experimentName }: FcmExperimentConfig<T, EN>,
	{ async, valueProperty }: HookOptions<KEY> = {},
): () => ResultState<T, EN> {
	const useFcm = createFcmHook(fcmConfigs);

	return (): ResultState<T, EN> => {
		const fcm = useFcm();
		const utilityContext = useUtilityContext();
		const locale = useLocale();
		const countryCode = useCountryCode();
		const isFirstAppRender = useIsFirstAppRender();

		const getVariation = useCallback(
			(name: string) => {
				const experiment = utilityContext.experiments.get(name);
				void experiment.treat();
				return experiment.variation;
			},
			[utilityContext],
		);

		// so the loading object is always the same for a specific config
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const loadingState = useMemo(() => ({ status: 'loading' as const }), []);

		return useMemo(() => {
			if (isLoading(fcm)) return loadingState;
			if (async && isFirstAppRender) return loadingState;

			const callbackContext = { fcm: fcm.values, locale, countryCode };
			const name = getExperimentName(callbackContext, experimentName);
			const fcmEnabled = isEnabled(callbackContext);

			return {
				status: 'loaded',
				[valueProperty || 'experiment']: {
					fcm: fcm.values,
					name,
					variation: fcmEnabled && name ? getVariation(name) : 'control',
				},
			} as ResultState<T, EN>;
		}, [loadingState, fcm, locale, countryCode, getVariation, isFirstAppRender]);
	};
}
