import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { ROUTES_COMPONENT } from 'src/app/pages/petition/routes.config';
import type { SubRouteConfig } from 'src/app/pages/petition/shared/tabTypes';
import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-comments',
		path: ['/p/:slug/c', '/p/:slug/comments'],
		component: ROUTES_COMPONENT,
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		additionalConfig: {
			routeType: 'petitionSubRoute',
			tab: 'comments',
			trackingData: {
				currentPage: 'comments',
			},
			hideTitle: false,
			component: loadable(async () => import(/* webpackChunkName: "pages/petition_comments" */ './index'), {
				exportName: 'PetitionComments',
				fallback: Loading,
			}),
		} as SubRouteConfig,
	},
];
