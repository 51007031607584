import { useCallback, useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';
import { Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconClose } from '@change/design-system/icons';
import { Box, Container, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import {
	useTrackBannerActionClick,
	useTrackBannerClose,
	useTrackBannerView,
} from '../hooks/useTrackingStarterDashboard';

export type BannerProps = {
	location: 'home' | 'petition';
	bannerContext: { petitionId: string; petitionSlug: string; onClose: () => void };
};

export function StarterDashPromptingBanner({
	location,
	bannerContext: { petitionId, petitionSlug, onClose },
}: BannerProps): JSX.Element | null {
	const [closed, setClosed] = useState(false);

	const trackBannerClose = useTrackBannerClose(location, petitionId);

	const onCloseBanner = useCallback(() => {
		trackBannerClose();
		setClosed(true);
		onClose();
	}, [onClose, trackBannerClose]);

	const trackClick = useTrackBannerActionClick(location, petitionId);
	useTrackBannerView(location, petitionId);

	if (closed) return null;

	return (
		<Box backgroundColor="secondary-purple" data-testid="dash-prompt-banner">
			<Container variant="default" py={16} px={20}>
				<Flex>
					<Flex sx={{ flex: '1', flexDirection: ['column', 'row'], justifyContent: ['flex-start', 'center'] }}>
						<Text mx="xs" color="white" sx={{ marginRight: '6px' }}>
							<Translate value="corgi.components.banner.starter_dashboard_prompt.text_copy" />
						</Text>
						<Link mx="xs" to={`/p/${petitionSlug}/dashboard`} onClick={trackClick} variant="light">
							<Text fontWeight="bold">
								<Translate value="corgi.components.banner.starter_dashboard_prompt.link_copy" />
							</Text>
						</Link>
					</Flex>
					<Box ml="auto">
						<Box
							mx="xs"
							as="button"
							onClick={onCloseBanner}
							sx={{ cursor: 'pointer', padding: 0, background: 'none', border: 0 }}
							data-testid="close-dash-prompt-banner"
						>
							<Icon color="white" icon={iconClose} />
							{/* using this approach for a11y for wider screen reader support
								https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
							<VisuallyHidden>
								<Translate value="corgi.components.banner.close" />
							</VisuallyHidden>
						</Box>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
}
