import { getFacebookVanity } from '@change/config/facebook';
import { getInstagramHandle } from '@change/config/instagram';
import { getTwitterHandle } from '@change/config/twitter';
import { useI18n } from '@change/core/react/i18n';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';

import type { LinkGroup } from '../../shared/types';

export function useConnectLinks(): LinkGroup {
	const { translate } = useI18n();
	const countryCode = useCountryCode();
	const locale = useLocale();
	const twitterHandle = getTwitterHandle({ locale, countryCode });
	const facebookVanity = getFacebookVanity({ locale, countryCode });
	const instagramHandle = getInstagramHandle({ locale, countryCode });

	return {
		title: translate('fe.components.footer.connect'),
		links: [
			{
				href: `https://twitter.com/${twitterHandle}`,
				title: translate('fe.components.footer.twitter'),
				target: '_blank',
			},
			{
				href: `https://www.facebook.com/${facebookVanity}`,
				title: translate('fe.components.footer.facebook'),
				target: '_blank',
			},
			...(instagramHandle
				? [
						{
							href: `https://www.instagram.com/${instagramHandle}`,
							title: translate('fe.components.footer.instagram'),
							target: '_blank',
						},
					]
				: []),
		],
	};
}
