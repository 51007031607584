import { getMediaCount } from '@change/core/features/sapRichMedia';

export const getMediaCountInfo = (
	description: string,
): { mediaCount: number; imageCount: number; youtubeCount: number } => {
	const mediaCountObj = getMediaCount(description);
	const mediaCount = Object.values(mediaCountObj).reduce((prev, curr) => prev + curr, 0);
	return {
		mediaCount,
		imageCount: mediaCountObj.image,
		youtubeCount: mediaCountObj.youtube,
	};
};

export const getImageCount = (html: string): number =>
	new DOMParser().parseFromString(html, 'text/html').getElementsByTagName('IMG').length;

export const getIframeCount = (html: string): number =>
	new DOMParser().parseFromString(html, 'text/html').getElementsByTagName('IFRAME').length;
