import { useEffect, useRef, useState } from 'react';
import type { JSX } from 'react';

import debounce from 'lodash/fp/debounce';

import { memo } from '@change/core/react/core';
import { Translate } from '@change/core/react/i18n';
import { ClientRender } from '@change/core/react/ssr/render';
import { useUtilityContext } from '@change/core/react/utilityContext';
import { isSsr } from '@change/core/ssr';
import { getWindow } from '@change/core/window';
import { VisuallyHidden } from '@change/design-system/a11y';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Flex } from '@change/design-system/layout';
import { buttonResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import type { GoogleLoginResultData } from 'src/app/shared/hooks/auth';
import { useHandleGoogleLogin } from 'src/app/shared/hooks/auth';
import { useLocale } from 'src/app/shared/hooks/l10n';

import type { GsiButtonConfiguration } from './types';

const buttonOptions: GsiButtonConfiguration = {
	size: 'large',
	type: 'standard',
	theme: 'outline',
	shape: 'rectangular',
	text: 'continue_with',
};

type Props = {
	onSuccess: (data: GoogleLoginResultData) => void;
	onError: (reason: string) => void;
	loginType: string;
	signupContext: string;
	optionalOnClickTrackProps?: Record<string, string | boolean>;
};

const RealGoogleAuthButton = memo(function RealGoogleAuthButton({
	loginType,
	signupContext,
	optionalOnClickTrackProps,
	onSuccess,
	onError,
}: Props): JSX.Element | null {
	const { googleAuth } = useUtilityContext();
	const locale = useLocale();
	const buttonElement = useRef<HTMLDivElement>(null);
	const [screenWidth, setScreenWidth] = useState<number>(isSsr() ? 0 : getWindow().innerWidth);
	const { handleGoogleLogin, handleGoogleLoginClick, result } = useHandleGoogleLogin({
		signupContext,
		loginType,
		optionalOnClickTrackProps,
	});

	// Dynamically resize the button when window is resized
	useEffect(() => {
		const handleResize = debounce(400, () => {
			setScreenWidth(getWindow().innerWidth);
		});
		getWindow().addEventListener('resize', handleResize);

		return () => {
			handleResize.cancel();
			getWindow().removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (buttonElement.current) {
			void googleAuth.renderButton({
				handleGoogleSignIn: handleGoogleLogin,
				parent: buttonElement.current,
				buttonOptions: {
					...buttonOptions,
					width: `${buttonElement.current.clientWidth}px`,
					click_listener: handleGoogleLoginClick,
					locale,
				},
			});
		}
	}, [screenWidth, googleAuth, handleGoogleLogin, handleGoogleLoginClick, locale]);

	useEffect(() => {
		switch (result.status) {
			case 'success':
				onSuccess(result.data);
				break;
			case 'error':
				onError(result.errorReason);
				break;
			case 'loading':
				break;
			case 'cancelled':
				break;
			default:
				break; // do nothing
		}
	}, [result, onError, onSuccess]);

	if (googleAuth.disabled) return null;

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div ref={buttonElement} data-testid="google-auth-button" />
			{result.status === 'loading' && <FakeGoogleButton loading />}
		</>
	);
});

export function GoogleAuthButton({
	loginType,
	signupContext,
	optionalOnClickTrackProps,
	onSuccess,
	onError,
}: Props): JSX.Element | null {
	return (
		<ClientRender placeholder={<FakeGoogleButton />}>
			<RealGoogleAuthButton
				loginType={loginType}
				signupContext={signupContext}
				optionalOnClickTrackProps={optionalOnClickTrackProps}
				onSuccess={onSuccess}
				onError={onError}
			/>
		</ClientRender>
	);
}

function FakeGoogleButton({ loading }: { loading?: boolean }) {
	return (
		<button
			type="button"
			sx={{
				...buttonResetStyles,
				px: 4,
				height: 40,
				width: '100%',
				// Copied from google style
				border: '1px solid #dadce0',
				borderRadius: 5,
				position: loading ? 'relative' : undefined,
				// Setting this because google forces its button to this max width
				maxWidth: 400,
			}}
			data-testid="google-auth-button-fake"
		>
			<Flex
				px={8}
				sx={{ flexDirection: 'row', alignItems: 'center', gap: 8, visibility: loading ? 'hidden' : undefined }}
			>
				<GoogleIcon aria-hidden="true" sx={{ fontSize: 18 }} />
				<Text sx={{ fontSize: 14, flex: 1, textAlign: 'center' }} size="small" data-label>
					<Translate value="corgi.google.continue_with" />
				</Text>
			</Flex>
			{loading && (
				<Flex
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Loader size={24} color="typography-lightPrimary" data-testid="google-auth-button-spinner" />
					{/* TODO translate label */}
					<VisuallyHidden>loading</VisuallyHidden>
				</Flex>
			)}
		</button>
	);
}

function GoogleIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48" {...props}>
			<g>
				<path
					fill="#EA4335"
					d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
				></path>
				<path
					fill="#4285F4"
					d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
				></path>
				<path
					fill="#FBBC05"
					d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
				></path>
				<path
					fill="#34A853"
					d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
				></path>
				<path fill="none" d="M0 0h48v48H0z"></path>
			</g>
		</svg>
	);
}
