import type { SapCreatePublishedPetitionMutation } from './createPublishedPetition.graphql';

type ResponseErrors = Exclude<
	SapCreatePublishedPetitionMutation['createPublishedPetition'],
	{ __typename: 'CreatePublishedPetitionSuccess' }
>['__typename'];

type Errors = ResponseErrors | 'NoResponseData' | 'GraphqlError' | 'UnknownError';

type ErrorTypes = Record<Errors, string>;

export const ERROR_TYPES = {
	CreatePublishedPetitionCreateError: 'sap_api_create_failed',
	CreatePublishedPetitionUnauthorizedError: 'sap_api_session_timeout',
	CreatePublishedPetitionUnexpectedError: 'sap_api_internal_server_error',
	GraphqlError: 'sap_api_graphql_error',
	NoResponseData: 'sap_api_no_response_data',
	UnknownError: 'sap_api_unknown_error',
} satisfies ErrorTypes;
