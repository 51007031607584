/* istanbul ignore file: this uses both canvas and image.onload which are both difficult to test in a node environment */
import { toCanvas } from 'qrcode';

import { getWindowSafe } from '@change/core/window';

import { createImage } from 'src/app/shared/utils/dom';

import { QR_CODE_LOGO } from './constants';

const addLogo = async (canvas: HTMLCanvasElement): Promise<void> =>
	// eslint-disable-next-line promise/avoid-new
	new Promise((resolve) => {
		const IMAGE_SIZE = canvas.width / 5;
		const image = createImage(IMAGE_SIZE, IMAGE_SIZE);
		image.src = QR_CODE_LOGO;
		image.onload = () => {
			const context = canvas.getContext('2d');
			if (!context) return;
			const center = canvas.width / 2 - IMAGE_SIZE / 2;
			context.drawImage(image, center, center, IMAGE_SIZE, IMAGE_SIZE);
			resolve();
		};
	});

export const createQRCode = async (value: string, size: number, includeMargin = true): Promise<string | null> => {
	// canvas is only available in the browser
	if (!getWindowSafe()) return null;

	// increase resolution based on users device to make it clearer when rendering
	const width = size * window.devicePixelRatio;

	const canvas = await toCanvas(value, { width, errorCorrectionLevel: 'H', margin: includeMargin ? 4 : 0 });
	// add change logo to canvas
	await addLogo(canvas);

	return canvas.toDataURL('image/png');
};
