/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { getWindow } from '@change/core/window';

type IframelyEventName = 'click' | 'cancel';
// eslint-disable-next-line @typescript-eslint/ban-types
type IframelyEventCallback = Function;
type IframelyEventCallbackMap = {
	click: (href: string) => void;
	cancel: (url: string, parentNode: Node) => void;
};

type WindowIframely = {
	load: () => void;
	on: <E extends IframelyEventName>(eventName: E, callback: IframelyEventCallbackMap[E]) => void;
};

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		iframely?: WindowIframely;
	}
}

class Iframely {
	private listeners: Record<IframelyEventName, readonly IframelyEventCallback[]> = {
		cancel: [],
		click: [],
	};

	private listening = false;
	private listen() {
		if (this.listening) return;

		this.listening = true;

		{
			const { iframely } = getWindow();
			if (iframely) {
				this.addListeners(iframely);
				return;
			}
		}

		// we might have started listening before the iframely script was loaded
		this.listening = true;
		const interval = setInterval(() => {
			const { iframely } = getWindow();
			if (iframely) {
				clearInterval(interval);
				this.addListeners(iframely);
			}
		}, 1000);
	}

	private addListeners(iframely: WindowIframely) {
		(['click', 'cancel'] as IframelyEventName[]).forEach((eventName) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			iframely.on(eventName, (...args: any[]) => {
				this.listeners[eventName].forEach((callback) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					callback(...args);
				});
			});
		});
	}

	on<E extends IframelyEventName>(eventName: E, callback: IframelyEventCallbackMap[E]): () => void {
		if (this.listeners[eventName].includes(callback)) {
			return () => this.off(eventName, callback);
		}
		this.listeners[eventName] = [...this.listeners[eventName], callback];

		this.listen();

		return () => this.off(eventName, callback);
	}

	off<E extends IframelyEventName>(eventName: IframelyEventName, callback: IframelyEventCallbackMap[E]): void {
		if (!this.listeners[eventName].includes(callback)) return;
		this.listeners[eventName] = this.listeners[eventName].filter((cb) => cb !== callback);
	}
}

export type { Iframely, IframelyEventName, IframelyEventCallback, IframelyEventCallbackMap };

export const iframely = new Iframely();
