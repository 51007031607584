import type { JSX } from 'react';

import { Image } from '@change/design-system/components/media';

import blackHistory from 'src/assets/wordmark/blackHistory.svg';
import climateStrike from 'src/assets/wordmark/climateStrike.svg';
import defaultLogo from 'src/assets/wordmark/default.svg';
import pride from 'src/assets/wordmark/pride.svg';

import type { LogoVariation } from '../types';

type Props = {
	logoVariation?: LogoVariation;
};

const LOGO_URL: Record<LogoVariation, string> = {
	pride,
	climateStrike,
	blackHistory,
};

export function Logo({ logoVariation }: Props): JSX.Element | null {
	const url = (logoVariation && LOGO_URL[logoVariation]) || defaultLogo;
	return <Image src={url} sx={{ width: [114, 142] }} alt="" draggable="false" />;
}
