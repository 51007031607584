import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		petition_ids: {
			type: 'array',
			shape: {
				type: 'number',
			},
		},
		percent: {
			type: 'number',
		},
		channels: {
			type: 'array',
			shape: {
				type: 'string',
			},
		},
	},
});

export const FCM_MANUALLY_PROMOTED_PETITION_DATA = createFcmConfig('manually_promoted_petition_data', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
