import type { UtilityContext } from '@change/core/react/utilityContext';

import { RedirectError } from 'src/shared/error';

import { differenceInDays } from 'src/app/shared/utils/dates';

import type { GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables } from './api.graphql';
import { GammaPetitionDetailsData } from './api.graphql';
import { getContactDmConfig } from './getContactDmConfig';
import { getPetitionStrengthScore } from './getPetitionStrengthScore';

export type PetitionDetailsPageData = {
	petition: NonNullable<GammaPetitionDetailsDataQuery['petition']>;
	recommendedDecisionMakers: NonNullable<GammaPetitionDetailsDataQuery['recommendedDecisionMakers']>['items'];
	createdLessThanOneDayAgo: boolean;
	score: number;
};

export async function getPetitionDetailsPageData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{ locale, countryCode, loadBandits }: { locale: string; countryCode: string; loadBandits: boolean },
): Promise<PetitionDetailsPageData> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { dmContactsToFetch } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables>({
		query: GammaPetitionDetailsData,
		variables: { slugOrId, locale, countryCode, loadBandits, dmContactsToFetch },
		rejectOnError: true,
		// to separate query from other batched queries
		important: true,
	});

	if (!data?.petition) {
		throw new RedirectError({ url: '/?petition_not_found=true' });
	}

	return {
		petition: data.petition,
		recommendedDecisionMakers: data.recommendedDecisionMakers?.items || [],
		createdLessThanOneDayAgo: differenceInDays(new Date(), new Date(data.petition.createdAt)) < 1,
		score: getPetitionStrengthScore(data.petition),
	};
}
