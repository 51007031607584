import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { HttpClient } from '@change/core/http';
import type { Translations } from '@change/core/i18n';
import { getTranslations as _getTranslations } from '@change/core/i18n';

import { getFilteredTranslationsUrl } from './getFilteredTranslationsUrl';

type Options = {
	getAsset: HttpClient['getAsset'];
	reportError: ErrorReporter['report'];
	filterTranslations: boolean;
	cacheBuster?: string;
};

export async function getTranslations(
	locale: string,
	{ getAsset, reportError, filterTranslations, cacheBuster }: Options,
): Promise<Translations> {
	// if translations are filtered, attempt to get filtered translations, falling back to default translations
	return _getTranslations(locale, {
		getAsset,
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		reportError,
		cacheBuster,
		i18nUrl: filterTranslations ? getFilteredTranslationsUrl(locale, cacheBuster) : undefined,
	});
}
