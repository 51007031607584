import { useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';

import { usePetitionDetails } from '../../petitionDetails';

export function useHeadlineReplacementsStarter(): Record<string, string | number> {
	const petition = usePetitionDetails();
	const { getCountry } = useI18n();

	return useMemo(
		() => ({
			starter_name: petition.organization?.name || petition.user.displayName,
			starter_city_state: petition.user.formattedLocationString,
			starter_city_country: (() => {
				const { city } = petition.user;
				const countryCode = petition.user.country?.countryCode;
				const countryName = (countryCode && getCountry(countryCode)?.name) || '';
				return (city && countryName && `${city}, ${countryName}`) || '';
			})(),
		}),
		[petition, getCountry],
	);
}
