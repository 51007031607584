import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { BanditsSharedPullBanditMutation, BanditsSharedPullBanditMutationVariables } from './pull.graphql';

type Input = {
	experimentName: string;
	variantName: string;
};

const query = gql`
	mutation BanditsSharedPullBandit($input: BanditInput!) {
		pullBandit(input: $input)
	}
`;

export async function pullBandit(
	{ experimentName, variantName }: Input,
	{ gql: { fetch } }: UtilityContext,
	{
		silent,
	}: {
		// if true, won't throw on error
		silent?: boolean;
	} = {},
): Promise<void> {
	let data: BanditsSharedPullBanditMutation | null | undefined;
	try {
		const result = await fetch<BanditsSharedPullBanditMutation, BanditsSharedPullBanditMutationVariables>({
			query,
			variables: {
				input: {
					banditId: experimentName,
					variantName,
					increment: null,
				},
			},
			important: true,
		});
		({ data } = result);
	} catch (err) {
		if (silent) return;
		throw new Error('Bandit pull failed');
	}

	if (silent) return;
	if (!data?.pullBandit) {
		throw new Error('Bandit pull failed');
	}
}
