import type { JSX } from 'react';

import { Html } from '@change/design-system/html';

import { BannerWrapper } from 'src/app/shared/components/banners/layout';
import { useErrorText } from 'src/app/shared/hooks/error';

export function PetitionUnderReviewBanner(): JSX.Element {
	const text = useErrorText('petition_under_review');

	return (
		<BannerWrapper py={8}>
			<Html html={text.header} size="large" />
			<Html
				html={text.main}
				mt={16}
				sx={{
					p: {
						margin: '8px 0',
					},
					// overrides the margins sets by <Html>
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'p+p': {
						marginTop: '8px',
					},
				}}
			/>
		</BannerWrapper>
	);
}
