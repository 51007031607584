import type { JSX, KeyboardEvent } from 'react';

import { useLocation } from 'react-router';

import { translationKey } from '@change/core/i18n';
import { Translate, useI18n } from '@change/core/react/i18n';
import { Link } from '@change/design-system/components/actions';
import { Separator } from '@change/design-system/components/content';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import {
	LOGIN_URL,
	logoutUrl,
	MEMBERSHIP_URL,
	MY_PETITIONS_DROPDOWN_URL,
	PETITIONS_URL,
	SEARCH_URL,
	SETTINGS_URL,
	START_PETITION_URL,
} from '../../shared/navigation';
import { useHeaderTracking } from '../hooks/tracking/useHeaderTracking';

import { DropDownMenuItem } from './DropDownMenuItem';

type Props = {
	displayName?: string;
	isAtLeastIdentified: boolean;
	onClose?: () => void;
	isMembershipEnabled: boolean;
};

const bubbleArrowHeight = 9;
const bubbleArrowWidth = 7;

// eslint-disable-next-line max-lines-per-function
export function DropDownMenu({
	displayName,
	isAtLeastIdentified,
	onClose,
	isMembershipEnabled,
}: Props): JSX.Element | null {
	const { translate } = useI18n();
	const location = useLocation();
	const { trackHeaderClick } = useHeaderTracking();

	const onKeyUp = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			onClose?.();
		}
	};
	const onItemClick = () => {
		onClose?.();
	};

	const onStartAPetitionClick = () => {
		trackHeaderClick();
		onClose?.();
	};

	return (
		<Box
			role="menu"
			sx={{
				position: 'absolute',
				minWidth: 150,

				right: 0,
				zIndex: 3000,
				backgroundColor: 'primary-white',
			}}
			onKeyUp={onKeyUp}
			tabIndex={-1}
			aria-label={translate('fe.components.header.menu')}
			data-testid="header-dropdown-menu"
		>
			<Box role="none" sx={{ borderRadius: 'standard', boxShadow: 'rgba(54, 49, 53, 0.2) 0px 2px 3px 0px' }}>
				<Box
					variant="bordered"
					sx={{
						borderColor: 'neutral-grey100',
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&::before,	&::after': {
							content: '""',
							position: 'absolute',
							right: 2,
							top: -bubbleArrowHeight,
							transform: 'rotate(180deg)',
							borderStyle: 'solid',
							borderTopColor: 'neutral-grey100',
							borderBottomColor: 'neutral-grey100',
							borderLeftColor: 'transparent',
							borderRightColor: 'transparent',
							borderTopWidth: bubbleArrowHeight,
							borderBottomWidth: 0,
							borderLeftWidth: bubbleArrowWidth,
							borderRightWidth: bubbleArrowWidth,
						},
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&::after': {
							top: -bubbleArrowHeight + 2,
							borderTopColor: 'primary-white',
							borderBottomColor: 'primary-white',
						},
					}}
					role="none"
					// variant="outlineBasic"
					// arrowPosition="tr"
					p={16}
				>
					<Box as="ul" role="none">
						{isAtLeastIdentified && (
							<>
								<DropDownMenuItem
									labelKey={translationKey('fe.components.header.my_petitions')}
									onClick={onItemClick}
									to={MY_PETITIONS_DROPDOWN_URL}
									data-qa="header-my-petitions-link"
								/>
								<DropDownMenuItem
									labelKey={translationKey('fe.components.header.settings')}
									to={SETTINGS_URL}
									onClick={onItemClick}
									data-qa="header-settings-link"
								/>
							</>
						)}

						<DropDownMenuItem
							labelKey={translationKey('fe.components.header.start_a_petition')}
							onClick={onStartAPetitionClick}
							to={START_PETITION_URL}
							mobileOnly
							data-qa="header-sap-link"
						/>
						{isMembershipEnabled && (
							<DropDownMenuItem
								labelKey={translationKey('fe.components.header.membership')}
								onClick={onItemClick}
								to={MEMBERSHIP_URL}
								mobileOnly
								data-qa="header-membership-link"
							/>
						)}
						<DropDownMenuItem
							labelKey={translationKey('fe.components.header.browse')}
							onClick={onItemClick}
							to={PETITIONS_URL}
							mobileOnly
							data-qa="header-browse-link"
						/>
						<DropDownMenuItem
							labelKey={translationKey('fe.components.header.search')}
							onClick={onItemClick}
							to={SEARCH_URL}
							mobileOnly
							data-qa="header-search-button"
						/>

						<Box as="li" role="none" pt={8}>
							<Separator role="presentation" mb={16} mt={0} />

							{!isAtLeastIdentified && (
								<Link role="menuitem" to={LOGIN_URL} onClick={onItemClick} data-qa="header-login-link">
									<Translate value="fe.pages.login.title" />
								</Link>
							)}

							{isAtLeastIdentified && (
								<>
									<Text
										as="div"
										breakWord
										// this allows the item to take as much space as possible before wrapping
										sx={{ maxWidth: 200, width: 'max-content' }}
										lineClamp={3}
										data-qa="header-user-name"
									>
										{displayName}
									</Text>
									<Link role="menuitem" to={logoutUrl({ redirectTo: location })} data-qa="header-logout-link">
										<Translate value="fe.components.header.log_out" />
									</Link>
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
