import type { ComponentProps, JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { parseHtml } from '@change/core/react/html';
import { Box } from '@change/design-system/layout';

type Props = {
	html: string;
	aspectRatio?: number;
};

/**
 * For rendering embed code coming from iframely
 */
function EmbeddedMediaInner({ html, aspectRatio, ...rest }: Props & ComponentProps<typeof Box>) {
	return (
		<Box
			sx={{
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&>iframe': {
					height: aspectRatio ? 'auto' : undefined,
					aspectRatio,
				},
			}}
			suppressHydrationWarning
			{...rest}
		>
			{parseHtml(html, {
				allowedTags: ['iframe'],
				allowedAttrs: [
					'scrolling',
					'frameborder',
					'allowfullscreen',
					'allow',
					// https://iframely.com/docs/lazy-load#what-you-get
					'data-iframely-url',
					'data-img',
				],
				suppressHydrationWarning: true,
			})}
		</Box>
	);
}

const SCRIPT_ID = 'iframely-embed-script';
// This prevents from running the full vendor JS every time we display an embedded media
const SCRIPT = `
(function(){
	if (document.getElementById('${SCRIPT_ID}')) return;
	var p = document.getElementsByTagName('script')[0];
	var s = document.createElement('script');
	s.id = '${SCRIPT_ID}';
	s.async = 1;
	s.charSet = 'utf-8';
	s.src = '//cdn.iframe.ly/embed.js';
	p.parentNode.insertBefore(s, p);
}());
`;

export function EmbeddedMedia(props: Props & ComponentProps<typeof Box>): JSX.Element | null {
	return (
		<>
			<Helmet>
				<script type="text/javascript" id="iframely-embed-script-injector">
					{SCRIPT}
				</script>
			</Helmet>
			<EmbeddedMediaInner {...props} />
		</>
	);
}
