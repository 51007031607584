import type { PerimeterXEnforcerChallengeWithBlockedUrl } from '@change/core/perimeterx';
import { Translate } from '@change/core/react/i18n';
import { createModalHook } from '@change/design-system/modals';

import { PerimeterXCaptcha } from './PerimeterXCaptcha';

// This component renders a modal dialog containing the PerimeterXCaptcha
// component, which displays a captcha to the user. When the captcha is solved,
// either onSuccess() or onFailure() will be called as appropriate.

export type Options = {
	pxResponse: PerimeterXEnforcerChallengeWithBlockedUrl;
};

export type Result = {
	success: boolean;
};

export const usePerimeterXCaptchaModal = createModalHook<Options, Result>({
	name: 'PerimeterXCaptchaModal',
	closeButton: false,
	closeOnClickOutside: false,
	closeOnEscape: false,
	stylesOverrides: {
		// slightly larger than the default
		width: ['calc(100vw - 32px)', 570],
	},
	title: function PerimeterXCaptchaModalTitle() {
		return <Translate value="fe.components.px_captcha.title" />;
	},
	body: function PerimeterXCaptchaModalBody({ options: { pxResponse }, closeModal }) {
		return (
			<PerimeterXCaptcha
				pxResponse={pxResponse}
				onSuccess={() => {
					closeModal({ success: true });
				}}
				onFailure={() => {
					closeModal({ success: false });
				}}
			/>
		);
	},
	killOpenModal: true,
});
