import qs from 'qs';

import type { UtilityContext } from '@change/core/react/utilityContext';

import { normalizeSignature, normalizeSignatureForList } from './normalize';
import type {
	ApiPetitionListSignature,
	ApiPetitionSignature,
	PetitionSignature,
	PetitionSignatureListItem,
} from './types';

export async function getSignature(
	userId: string,
	slugOrId: string,
	{ http: { get } }: UtilityContext,
): Promise<PetitionSignature | undefined> {
	const signature = await get<ApiPetitionSignature | EmptyObject>(
		`/api-proxy/-/users/${userId}/signatures/petitions/${encodeURI(slugOrId)}`,
	);
	// weirdly, when the user hasn't signed the petition, an empty object is returned
	if (!Object.keys(signature).length) {
		return undefined;
	}
	return normalizeSignature(signature as ApiPetitionSignature);
}

export type ApiPetitionSignatures = {
	offset: number;
	limit: number;
	count: number;
	total_count: number;
	user_id: number;
	items: readonly ApiPetitionListSignature[];
};

export async function getSignatures(
	userSlugOrId: string,
	{ cursor, limit }: { cursor: string | undefined; limit: number },
	{ http: { get }, i18n, errorReporter: { report } }: UtilityContext,
): Promise<{
	items: readonly PetitionSignatureListItem[];
	totalCount: number;
	totalCountLocalized: string;
	endCursor: string | undefined;
	hasNextPage: boolean;
}> {
	const response = await get<ApiPetitionSignatures>(
		`/api-proxy/-/users/${userSlugOrId}/signatures/petitions${qs.stringify(
			{ limit, offset: cursor && parseInt(cursor, 10) },
			{ addQueryPrefix: true },
		)}`,
	);
	if (!response.items) {
		void report({
			error: 'Failed to get signatures',
			params: { response, userSlugOrId },
		});
		throw new Error('Failed to get signatures');
	}
	// { petition: { status: 500, petitionId: 16564915 }, ... }
	const failedPetition = response.items.find((item) => !item.petition.id);
	if (failedPetition) {
		void report({
			error: 'Failed to get signatures',
			params: { failedPetition, userSlugOrId },
		});
		throw new Error('Failed to get signatures');
	}
	return {
		items: response.items.map(normalizeSignatureForList),
		totalCount: response.total_count,
		totalCountLocalized: i18n.localizeNumber(response.total_count),
		endCursor: response.count >= limit ? `${response.offset + response.count}` : undefined,
		hasNextPage: response.count >= limit,
	};
}
