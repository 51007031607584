import { useCallback } from 'react';

import { useNow } from '@change/core/react/date';
import { useI18n } from '@change/core/react/i18n';

export function useLocalizeRelativeNow(): (to: string | number | Date) => string {
	const { localizeRelativeTime } = useI18n();
	const now = useNow();

	return useCallback((to: string | number | Date) => localizeRelativeTime(now, to), [now, localizeRelativeTime]);
}
