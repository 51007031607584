import type { TrackingFn } from '@change/core/react/tracking';

function getTrackingCommonData() {
	return {
		location: 'instagram_story',
	};
}

export function trackShare(
	eventName: string,
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	track: TrackingFn,
): void {
	void track(eventName, { ...getTrackingCommonData(), ...trackingData });
}
