import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'landing-template',
		path: '/lp/:slug' /* check if this is ok for SEO */,
		component: loadable(async () => import(/* webpackChunkName: "pages/landing-template" */ './index'), {
			exportName: 'Template',
			fallback: Loading,
		}),
		frame: false,
		ssr: {
			enabled: true,
			seo: true,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					lifetimeInSeconds: 30 * 60,
				},
			},
		},
	},
];
