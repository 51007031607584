import { useEffect } from 'react';

import type { FeatureConfigInfo } from '@change/core/fcm';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useFcmState } from '../context';

export function useLoadQueue(): void {
	const [state, { removeFromQueue, setLoaded }] = useFcmState();
	const { fcm } = useUtilityContext();

	useEffect(() => {
		if (!state.queue.length) return;
		// copying the contents because queue is mutated by the state mutators
		const queue = [...state.queue];
		(async () => {
			// removing the items from the queue to make sure we're not making the request multiple times
			removeFromQueue(queue);
			const configs = queue.reduce<Record<string, FeatureConfigInfo>>((acc, config) => {
				// eslint-disable-next-line no-param-reassign
				acc[config.name] = config;
				return acc;
			}, {});
			setLoaded(await fcm.get(configs));
		})();
	}, [fcm, state, removeFromQueue, setLoaded]);
}
