import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { DeleteSupporterVideoMutation, DeleteSupporterVideoMutationVariables } from './index.graphql';

export async function deleteSupporterVideo({
	videoId,
	utilityContext: {
		gql: { fetch },
	},
}: {
	videoId: string;
	utilityContext: UtilityContext;
}): Promise<DeleteSupporterVideoMutation | null | undefined> {
	const { data: deleteResult } = await fetch<
		NonNullable<DeleteSupporterVideoMutation>,
		DeleteSupporterVideoMutationVariables
	>({
		query: gql`
			mutation DeleteSupporterVideo($input: DeleteCommentInput!) {
				deleteComment(input: $input) {
					success
					reason
				}
			}
		`,
		variables: {
			input: {
				commentId: videoId,
			},
		},
		important: true,
	});
	return deleteResult;
}
