/* eslint-disable max-lines-per-function */
import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren } from 'react';
import { Children, isValidElement, useMemo, useRef } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Box } from '@change/design-system/layout';

import { useScrollContainer } from './hooks/useScrollContainer';
import { useScrollEnd } from './hooks/useScrollEnd';
import { NavigationButtons } from './NavigationButtons';
import { toLinearGradient } from './utils';

type Props = PropsWithChildren<ComponentPropsWithRef<typeof Box>> & { label?: string };
function ScrollContainerInner(
	{ children, label, backgroundColor = 'transparent', ...rest }: Props,
	ref: ForwardedRef<HTMLElement>,
): JSX.Element | null {
	const carouselRef = useRef<HTMLElement | null>(null);
	const { data, actions } = useScrollContainer(carouselRef);

	useScrollEnd(carouselRef, actions.handleScrollEnd);

	const totalItems = useMemo(() => Children.count(children), [children]);

	if (totalItems === 0) return null;

	return (
		<Box
			as="section"
			aria-label={label}
			ref={ref}
			sx={{
				display: 'grid',
				gridTemplateColumns: `
				[carousel-gutter] calc(36px + (8px * 2))
				[carousel-scroller] 1fr
				[carousel-gutter] calc(36px + (8px * 2))`,
				gridTemplateRows: '[carousel-scroller] 1fr',
				position: 'relative',
				overflow: 'hidden',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'& #scroll-container-navigation-button-prev-bg': {
					background: toLinearGradient(backgroundColor, 'left'),
				},
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'& #scroll-container-navigation-button-next-bg': {
					background: toLinearGradient(backgroundColor, 'right'),
				},
			}}
			{...rest}
		>
			<NavigationButtons
				previous={{ onClick: actions.scrollToLeft, disabled: data.scrollLeftDisabled }}
				next={{ onClick: actions.scrollToRight, disabled: data.scrollRightDisabled }}
			/>
			<Box
				id="scroll-container-list"
				as="ul"
				role="list"
				ref={carouselRef}
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					display: 'grid',
					gridRow: '1',
					gridColumn: '1/-1',
					gridAutoFlow: 'column',
					gap: 16,
					overflowX: 'auto',
					overscrollBehaviorX: 'contain',
					scrollSnapType: 'x mandatory',
					'--webkit-overflow-scrolling': 'touch',
					'@media (prefers-reduced-motion: no-preference)': {
						scrollBehavior: 'smooth',
					},
					/** To meet a11y requirements, we show the scrollbar on hover
					 * when we disable the navigation controls on smaller screens.
					 * This allows users with mouse input to scroll the container.
					 * Future alternative = click+drag to scroll.
					 */
					/* padding-top accounts for scrollbar height when visible */
					/* Modern browsers with `scrollbar-*` support */
					'@supports (scrollbar-width: auto)': {
						scrollbarColor: 'transparent transparent',
						scrollbarWidth: ['thin', 'none'],
						'&:hover': {
							scrollbarColor: 'grey transparent',
						},
					},
					/* Legacy browsers with `::-webkit-scrollbar-*` support */
					'@supports not (scrollbar-width: auto)': {
						paddingBottom: [10, 0],
						// safari behaves really weird with hover states on scrollbar pseudo-elements.
						// so instead we're using a mask to hide the scrollbar and inverting it on hover.
						maskImage: ['linear-gradient(to top, transparent 10px, black 10px)', 'none'],
						'&:hover': {
							maskImage: ['linear-gradient(to top, black 10px, black 10px)', 'none'],
						},
						'&::-webkit-scrollbar': {
							display: ['initial', 'none'],
							maxWidth: 0,
							maxHeight: 10,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'grey',
							backgroundClip: 'content-box',
							border: '2px solid transparent',
							borderRadius: 8,
						},
						'&::-webkit-scrollbar-corner': {
							backgroundColor: 'transparent',
						},
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				{Children.map(children, (child, index) => {
					if (!isValidElement(child)) return null;

					return (
						// eslint-disable-next-line jsx-a11y/no-redundant-roles
						<li
							id={`scroll-container-list-item-${index}`}
							role="listitem"
							// eslint-disable-next-line react/no-array-index-key
							key={`${label}-${index}-${totalItems}`}
							sx={{ scrollSnapAlign: 'start', scrollSnapStop: 'always' }}
						>
							{child}
						</li>
					);
				})}
			</Box>
		</Box>
	);
}

export const ScrollContainer = forwardRef(ScrollContainerInner);
