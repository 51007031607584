import { useCallback } from 'react';
import type { ComponentProps, JSX } from 'react';

import type { Box } from '@change/design-system/layout';

import { LazyComponent, ScrollableComponent } from 'src/app/shared/components/layout/lazy';
import type { SuggestedPetitionsProps } from 'src/app/shared/components/suggestedPetitions';

export function SuggestedPetitionsLazy(props: SuggestedPetitionsProps & ComponentProps<typeof Box>): JSX.Element {
	const loadComponent = useCallback(
		async () =>
			(await import(/* webpackChunkName: "cmp/suggested_petitions" */ 'src/app/shared/components/suggestedPetitions'))
				.SuggestedPetitions,
		[],
	);

	return (
		<ScrollableComponent topOffset={200} immediateForBots>
			{/* weird type issue after adding ComponentProps<typeof Box>... */}
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions */}
			<LazyComponent loadComponent={loadComponent} {...(props as any)} />
		</ScrollableComponent>
	);
}
