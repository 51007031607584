import { useCallback, useMemo } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { likeSupporterVideo } from 'src/app/shared/api/supporterVoices';
import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import type { PageRenderingButton, SupporterVideo } from '../../../../shared/types';
import { useLikeVideoState } from '../useLikeVideoState';
import { useLikeVideoTracking } from '../useLikeVideoTracking';

type Result = ModelHookResult<
	{
		isVideoLiked: boolean | null;
		videoLikeCount: number;
		isGuest: boolean;
	},
	{
		handleLikeClick: () => Promise<undefined>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useLikeVideo({
	video,
	petitionId,
	renderedOn,
}: {
	video: SupporterVideo;
	petitionId: string;
	renderedOn: PageRenderingButton;
}): Result {
	const { id: videoId } = video;
	const loginStateBase = useLoginStateAsync();
	const loginState = isLoaded(loginStateBase) ? loginStateBase.value : undefined;

	const currentUserBase = useCurrentUserAsync();
	const currentUser = isLoaded(currentUserBase) && currentUserBase.value;

	const utilityContext = useUtilityContext();

	const {
		data: { isVideoLiked, videoLikeCount },
		actions: { updateLike },
	} = useLikeVideoState({ video });

	const videoLikeTrackingData = useMemo(() => {
		return {
			petition_id: petitionId,
			video_id: videoId,
			liker_id: currentUser ? currentUser?.id : undefined,
			page: renderedOn,
		};
	}, [petitionId, videoId, currentUser, renderedOn]);

	const {
		actions: { trackLikeVideo, trackError },
	} = useLikeVideoTracking({ videoLikeTrackingData });

	const handleLikeClick = useCallback(async (): Promise<undefined> => {
		trackLikeVideo('supporter_voice_like_click', {
			likeState: !isVideoLiked,
		});
		updateLike(!isVideoLiked);

		try {
			const likeResult = await likeSupporterVideo({ videoId, like: !isVideoLiked, utilityContext });
			if (likeResult?.likeComment.success) {
				trackLikeVideo('supporter_voice_like_success', { likeState: !!likeResult.likeComment.liked });
			} else {
				updateLike(isVideoLiked);
				trackLikeVideo('supporter_voice_like_failure', {
					likeState: !!likeResult?.likeComment.liked,
					errors: likeResult?.likeComment?.reason || 'an unknown reason',
				});
			}
		} catch (err) {
			updateLike(isVideoLiked);
			trackError('supporter_voice_like_error', err, {
				params: videoLikeTrackingData,
			});
		}
	}, [videoId, isVideoLiked, updateLike, trackLikeVideo, utilityContext, trackError, videoLikeTrackingData]);

	return {
		data: {
			isGuest: loginState === 'GUEST',
			isVideoLiked,
			videoLikeCount,
		},
		actions: {
			handleLikeClick,
		},
	};
}
