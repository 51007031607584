import { useMemo } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import errorI18n from 'config/error.i18n.yml';

import type { HttpError } from 'src/shared/error';

import { useLocale } from 'src/app/shared/hooks/l10n';

const FALLBACK_LOCALE = 'en-US';

export function useErrorText(code: HttpError['code'] | 500): Record<'header' | 'main' | 'footer', string> {
	const locale = useLocale();
	const { i18n } = useUtilityContext();

	const text = useMemo(() => {
		if (typeof code !== 'number') {
			return {
				header: i18n.translationExists(`corgi.error.${code}.header`)
					? i18n.translate(`corgi.error.${code}.header`)
					: i18n.translate('corgi.error.fallback.header'),
				main: i18n.translationExists(`corgi.error.${code}.main`)
					? i18n.translate(`corgi.error.${code}.main`)
					: i18n.translate('corgi.error.fallback.main'),
				footer: i18n.translationExists(`corgi.error.${code}.footer`)
					? i18n.translate(`corgi.error.${code}.footer`, { home: '/' })
					: i18n.translate('corgi.error.fallback.footer', { home: '/' }),
			};
		}
		// not using normal translation file as retrieving that file could actually be the cause of this error
		return (errorI18n[locale] || errorI18n[FALLBACK_LOCALE])[code === 401 ? 403 : code];
	}, [i18n, code, locale]);

	return useMemo(() => {
		return {
			...text,
			footer: text.footer.replace(/%{home}/, '/'),
		};
	}, [text]);
}
