import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { setPhoneNumberAndConsent } from './api';
import type { SetPhoneNumberAndConsentInput } from './api';

export type ConsentInput = SetPhoneNumberAndConsentInput['consentResponse'];

export function useSetCurrentUserPhoneNumber(): (
	phoneNumber: SetPhoneNumberAndConsentInput['phoneNumber'],
	consentResponse: SetPhoneNumberAndConsentInput['consentResponse'],
) => Promise<boolean> {
	const utilityContext = useUtilityContext();

	// TODO: trigger a refetch in useCurrentUserPhoneNumber?
	return useCallback(
		async (phoneNumber, consentResponse) => setPhoneNumberAndConsent({ phoneNumber, consentResponse }, utilityContext),
		[utilityContext],
	);
}
