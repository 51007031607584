import { useMemo } from 'react';
import type { JSX } from 'react';

import md5 from 'crypto-js/md5';
import { Helmet } from 'react-helmet-async';

import { perimeterxConfigs } from '@change/config/perimeterx';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCommonFcm } from 'src/app/shared/hooks/commonFcm';
import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';
import { getPxParamVariableName } from 'src/app/shared/utils/perimeterx';

const emailDomain = (email: string): string => email.split('@')[1];
const hash = (str: string): string => md5(str).toString();

type Props = {
	petitionId?: string;
};

export function PerimeterXSensorContainer({ petitionId }: Props): JSX.Element | null {
	const userState = useCurrentUserAsync();
	const { id: userId, email, createdAt } = (isLoaded(userState) && userState.value) || {};
	const { environment } = useUtilityContext();
	const locale = useLocale();
	const commonFcmState = useCommonFcm();
	const countryCode = useCountryCode();

	const petitionIdParam = petitionId ? `window.${getPxParamVariableName('petitionId')} = '${petitionId}';` : '';

	// When we know who the user is, include these extra custom params
	const userParams = userId
		? `
			window.${getPxParamVariableName('userId')} = '${userId}';
			window.${getPxParamVariableName('emailDomain')} = '${email ? emailDomain(email) : ''}';
			window.${getPxParamVariableName('emailHash')} = '${email ? hash(email) : ''}';
			window.${getPxParamVariableName('accountDate')} = '${createdAt || ''}';
			`
		: '';

	const countryParam = `window.${getPxParamVariableName('countryCode')} = '${countryCode}';`;

	const script = useMemo(() => {
		// in dev, using the name of the environment we are proxying to
		const env = environment.getApiEnvironment();

		// this is to avoid inserting the script multiple times
		const scriptId = `px-sensor-script-${env}`;

		// FIXME? is there a way to reload the script with a different _pxParam1?
		return `
		(function(){
			if (document.getElementById('${scriptId}')) return;
			window._pxAppId = '${perimeterxConfigs[env].appId}';
			${petitionIdParam}
			${userParams}
			${countryParam}
			window._pxSelectedLocale = '${locale}';
			var p = document.getElementsByTagName('script')[0];
			var s = document.createElement('script');
			s.id = '${scriptId}';
			s.async = 1;
			s.src = '${perimeterxConfigs[env].jsSrc}';
			p.parentNode.insertBefore(s, p);
		}());
	`;
	}, [environment, locale, petitionIdParam, userParams, countryParam]);

	if (!isLoaded(userState)) return null;
	if (!isLoaded(commonFcmState)) return null;

	if (!commonFcmState.values.pxSensorScriptEnabled) {
		return null;
	}

	return (
		<Helmet>
			{/* eslint-disable-next-line react/forbid-dom-props */}
			<script type="text/javascript" id="px_sensor">
				{script}
			</script>
		</Helmet>
	);
}
