import type { JSX } from 'react';
import { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';

import { Html } from '@change/design-system/html';

type Props = {
	html: string;
	styles: string;
};

export function CmsHtml({ html, styles }: Props): JSX.Element {
	// CMS content is sometimes linking directly to prod
	// which prevents from taking advantage of hash links in non-prod envs, or react-router navigation in all envs
	const transformLinkHref = useCallback((href: string) => href.replace(/^https:\/\/www.change.org\//, '/'), []);

	return (
		<>
			<Helmet>
				<style>{styles}</style>
			</Helmet>
			<Html
				html={html}
				noRestrictedAttrs
				noRestrictedTags
				noContentStyles
				supportHashLinks
				allowIframes
				linksTarget="_self"
				transformLinkHref={transformLinkHref}
				size="large"
			/>
		</>
	);
}
