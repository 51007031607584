import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import type { ConsentInput } from './api';
import { setConsent } from './api';

export function useSetMarketingCommsConsent(): (consentResponse: ConsentInput) => Promise<boolean> {
	const utilityContext = useUtilityContext();

	return useCallback(async (consentResponse) => setConsent(consentResponse, utilityContext), [utilityContext]);
}

export type { ConsentInput as MarketingCommsConsent };
