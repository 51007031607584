import { createContext } from '@change/core/react/context';

import type { UseLoadGooglePlacesReturn } from '../hooks/useLoadGooglePlaces';

// FIXME should this be a createMandatoryContext?
export const {
	Context: GooglePlacesContext,
	Provider: GooglePlacesContextProvider,
	useContext: useGooglePlacesContext,
} = createContext<
	UseLoadGooglePlacesReturn | undefined,
	{
		status: 'loading' | 'loaded' | 'error';
		autocompleteService?: google.maps.places.AutocompleteService;
	}
>(undefined, {
	name: 'GooglePlacesContext',
	processProviderProps: ({ status, autocompleteService }) => ({ status, autocompleteService }),
});
