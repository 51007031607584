import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Icon } from '@change/design-system/components/icon';
import { iconCheckCircle } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

export function EducationModalHeading(): JSX.Element {
	return (
		<Flex sx={{ alignItems: 'baseline', marginRight: 32 }}>
			<Icon icon={iconCheckCircle} size="xl" color="typography-positive" mr={8} sx={{ flexShrink: 0 }} />
			<Heading as="h1" size="h3">
				<Translate value="fe.components.psf.instagram.link_copied" />
			</Heading>
		</Flex>
	);
}
