import React from 'react';

import { Button } from '@change/design-system/components/actions';
import { iconDelete } from '@change/design-system/icons';

import type { PageRenderingButton, SupporterVideo } from '../../shared/types';

import { useDeleteVideo } from './hooks/useDeleteVideo';

type DeleteVideoProps = {
	petitionId: string;
	video: SupporterVideo;
	renderedOn: PageRenderingButton;
	/**
	 What happens after successfully deleting a video differs for different pages.
	 The 'afterDeleteAction' prop allows the page rendering of the delete
	 to specify what it wants to happen upon completion.
	*/
	afterDeleteAction: (videoId?: string) => void;
};
export function DeleteVideo({
	petitionId,
	video,
	renderedOn,
	afterDeleteAction,
}: DeleteVideoProps): React.JSX.Element | null {
	const {
		data: { canDeleteVideo },
		actions: { handleDeleteClick },
	} = useDeleteVideo({ petitionId, video, afterDeleteAction, renderedOn });

	if (!canDeleteVideo) return null;

	return (
		<Button
			size="small"
			mode="icon"
			icon={iconDelete}
			variant="secondary"
			aria-label={`delete video from ${video.user?.shortDisplayName}`}
			data-testid={`supporter-video-${video.id}-delete-button`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleDeleteClick}
		>
			Delete
		</Button>
	);
}
