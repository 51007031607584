import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { trackPromotionShare } from './api';

type Input = {
	token: string;
	placement?: string;
};

export function useTrackPromotionShare(): (
	input: Input,
	options?: {
		// if true, won't throw on error
		silent?: boolean;
	},
) => Promise<void> {
	const countryCode = useCountryCode();
	const utilityContext = useUtilityContext();

	return useCallback(
		async (input, options) => {
			if (utilityContext.userAgent.isBot()) return undefined;
			return trackPromotionShare({ ...input, countryCode }, utilityContext, options);
		},
		[countryCode, utilityContext],
	);
}
