import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { Utilities } from '@change/core/react/utilityContext';
import type { Session } from '@change/core/session';
import { createCsrfUtils as _createCsrfUtils } from '@change/core/session';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	session: Session;
}>;

export function createCsrfUtils({ errorReporter, session }: Options): Utilities['csrf'] {
	return _createCsrfUtils({
		initialCsrfToken: session.csrfToken,
		fetch,
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		reportError: errorReporter.report.bind(errorReporter),
		// we want to be the token to be read ASAP for event tracking (using sendBeacon)
		fetchPolicy: 'immediate',
		// disable cache auto-clear to match fe's behavior
		// otherwise that might result on click events no being sent exclusively on corgi
		cacheExpirationMs: 0,
	}).init();
}
