import type { EnvironmentUtils } from '@change/core/environment';
import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { GqlClient } from '@change/core/gql';
import { createGqlClient as _createGqlClient } from '@change/core/gql';

import webappInfo from 'src/webappInfo';

import { wrapFetchForPerimeterX } from './shared/perimeterX';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	environment: EnvironmentUtils;
}>;

export function createGqlClient({ errorReporter, environment }: Options): GqlClient {
	return _createGqlClient({
		uri: '/api-proxy/graphql',
		fetch: environment.getEnvironment() === 'production' ? undefined : wrapFetchForPerimeterX(),
		webappInfo,
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		reportError: errorReporter.report.bind(errorReporter),
		reportNetworkError: errorReporter.createSampledReporter(0.01),
		headers: {
			accept: 'application/json',
		},
		addOperationNamesToUri: true,
	});
}
