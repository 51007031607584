import { Box } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';

import { EmailVerificationModalBody } from './components/EmailVerificationModalBody';

export const useEmailVerificationModal = createModalHook<{
	path: string;
	showContinueAsGuest: boolean;
	userId: string;
}>({
	name: 'PromotePageEmailVerificationModal',
	stylesOverrides: {
		width: 420,
	},
	// FIXME no title because there was none in fe, although there should be one within the new design system
	body: function PromotePageEmailVerificationModalBody({ options, closeModal }) {
		return (
			<Box pt={64} px={8} data-qa="verify-email-modal">
				<EmailVerificationModalBody
					path={options.path}
					onContinueAsGuest={options.showContinueAsGuest ? () => closeModal() : undefined}
					userId={options.userId}
				/>
			</Box>
		);
	},
});
