import type { ErrorInfo, JSX } from 'react';

import { loadableReady } from '@loadable/component';
import qs from 'qs';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { CustomError } from 'ts-custom-error';

import type { ReportableError } from '@change/core/errorReporter/common';
import { getDocument, getWindow } from '@change/core/window';

import type { BootstrapResults } from 'src/app/bootstrap';
import { bootstrap } from 'src/app/bootstrap';
import { markTimelineEnd, markTimelineStart } from 'src/app/shared/performance';

import type { HydrationData } from './types';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		__HYDRATION_DATA__?: HydrationData;
	}
}

type Options = Readonly<{
	createApp: (bootstrapData: BootstrapResults) => () => JSX.Element | null;
}>;

export class HydrationError extends CustomError {
	constructor(error: Error | string) {
		super(error instanceof Error ? `Hydration failure: ${error.message}` : `Hydration failure: ${error}`);
	}
}

const reportHydrationFailed = (() => {
	let alreadyReportedTextContentNotMatchError = false;

	function isTextContentNotMatchError(error: Error | string) {
		if (typeof error === 'string') return false;
		return (
			// dev error
			error.message.includes('Text content does not match server-rendered HTML') ||
			error.message.includes("Hydration failed because the server rendered HTML didn't match the client.") ||
			// production error
			error.message.includes('Minified React error #425') ||
			error.message.includes('Minified React error #418')
		);
	}

	function shouldReportReactHydrationError(error: Error | string) {
		if (isTextContentNotMatchError(error)) {
			/**
			 * this error can occur for multiple reasons:
			 * - google translate being applied to the page (or similar browser plugin)
			 * - browser plugin that removes inclusive language
			 * - browser plugin that transforms miles to km or km to miles
			 * - ...
			 *
			 * when that error occurs, the next hydration errors are just adding noise and don't need to be reported
			 */
			alreadyReportedTextContentNotMatchError = true;
			return true;
		}
		return !alreadyReportedTextContentNotMatchError;
	}

	return (
		reportError: (error: ReportableError) => void,
		error: unknown,
		reactHydrationErrorInfo?: Partial<
			ErrorInfo & {
				// these are added to react by a patch (see patches folder at the project's root)
				serverText?: string;
				clientText?: string;
			}
		>,
	) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (reactHydrationErrorInfo && !shouldReportReactHydrationError(error as Error | string)) {
			return;
		}
		reportError({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error | string,
			...(reactHydrationErrorInfo
				? {
						params: {
							serverText: reactHydrationErrorInfo?.serverText,
							clientText: reactHydrationErrorInfo?.clientText,
						},
					}
				: {}),
		});
	};
})();

// eslint-disable-next-line max-statements
export async function init({ createApp }: Options): Promise<void> {
	markTimelineEnd('pre-bootstrap');

	const window = getWindow();
	const document = getDocument();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const rootApp = document.getElementById('rootApp')!;

	const needHydration = !!window.__HYDRATION_DATA__;

	const skipHydration =
		process.env.NODE_ENV === 'development' &&
		qs.parse(window.location.search, { ignoreQueryPrefix: true }).hydrate === 'false';

	markTimelineStart('bootstrap');
	const bootstrapData = await bootstrap({ hydrationData: window.__HYDRATION_DATA__ });
	markTimelineEnd('bootstrap');

	const App = createApp(bootstrapData);

	// if an error occured while bootstrapping, we are rendering an error page,
	// therefore we should not hydrate the page
	if (!bootstrapData.error && needHydration) {
		const reportError = bootstrapData.utilities.errorReporter.createSampledReporter(0.01);

		if (!skipHydration) {
			try {
				markTimelineStart('load lazy modules');
				await loadableReady();
				markTimelineEnd('load lazy modules');
				markTimelineStart('hydrate');
				hydrateRoot(rootApp, <App />, {
					onRecoverableError: (error, errorInfo) => {
						reportHydrationFailed(reportError, error, errorInfo);
					},
				});
				markTimelineEnd('hydrate');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				reportHydrationFailed(reportError, new HydrationError(e));
				createRoot(rootApp).render(<App />);
			} finally {
				getDocument().documentElement.setAttribute('data-hydrated', 'true');
			}
		}
	} else {
		createRoot(rootApp).render(<App />);
		getDocument().documentElement.setAttribute('data-hydrated', 'true');
	}
}
