import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-featured-victories',
		path: '/dashboard/featured_victories',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_featuredVictories" */ './index'), {
			exportName: 'FeaturedVictories',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		frame: true,
	},
];
