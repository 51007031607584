import type { PerimeterXEnforcerChallenge } from '@change/core/perimeterx';

export const PX_FAKE_CHALLENGE_APP_ID = 'PXfake_app_id';

export const PX_FAKE_CHALLENGE: PerimeterXEnforcerChallenge = {
	appId: PX_FAKE_CHALLENGE_APP_ID,
	vid: 'bf619be8-94be-458a-b6b1-ee81f154c282',
	uuid: '8712cef7-bcfa-4bb6-ae99-868025e1908a',
	blockScript:
		'/fake_app_id/captcha/captcha.js?a=&u=8712cef7-bcfa-4bb6-ae99-868025e1908a&v=bf619be8-94be-458a-b6b1-ee81f154c282&m=0',
	jsClientSrc: '/fake_app_id/init.js',
	firstPartyEnabled: true,
	hostUrl: '/fake_app_id/xhr',
};
