import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { iconArrowBack, iconArrowForward } from '@change/design-system/icons';
import { Box } from '@change/design-system/layout';
import { useBreakpoints } from '@change/design-system/theme';

type ButtonProps = { onClick: () => void; disabled: boolean };
type Props = { previous: ButtonProps; next: ButtonProps };
export function NavigationButtons({ previous, next }: Props): JSX.Element {
	const breakpoints = useBreakpoints();

	const renderFade = () => {
		const styles = {
			top: 0,
			bottom: 0,
			position: 'absolute',
			width: '50px',
		} as const;
		return (
			<>
				<Box
					id="scroll-container-navigation-button-prev-bg"
					aria-hidden
					hidden={previous.disabled}
					sx={{
						...styles,
						display: ['none', !previous.disabled ? 'block' : null],
						left: 0,
					}}
				/>
				<Box
					id="scroll-container-navigation-button-next-bg"
					aria-hidden
					hidden={next.disabled}
					sx={{
						...styles,
						display: ['none', !next.disabled ? 'block' : null],
						right: 0,
					}}
				/>
			</>
		);
	};

	return (
		<Box sx={{ display: 'contents', position: 'relative' }}>
			<Button
				type="button"
				icon={iconArrowBack}
				iconPosition="start"
				mode="icon"
				onClick={previous.onClick}
				size="small"
				variant="secondary"
				aria-disabled={previous.disabled}
				disabled={previous.disabled}
				data-testid="previous-button"
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					borderRadius: '50% !important',
					boxShadow: '0px 3px 3px 0px #E5E5E5',
					gridColumn: 1,
					gridRow: 1,
					left: -1000,
					placeSelf: 'center',
					position: 'absolute',
					zIndex: 1,
					'&:focus': {
						position: 'static',
					},
					[`@media screen and (min-width: ${breakpoints[0]})`]: {
						position: previous.disabled ? 'absolute' : 'static',
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				<Translate value="corgi.components.scroll_container.navigation_btn.previous" />
			</Button>
			<Button
				type="button"
				icon={iconArrowForward}
				iconPosition="start"
				mode="icon"
				onClick={next.onClick}
				size="small"
				variant="secondary"
				aria-disabled={next.disabled}
				disabled={next.disabled}
				data-testid="next-button"
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					borderRadius: '50% !important',
					boxShadow: '0px 3px 3px 0px #E5E5E5',
					gridColumn: 3,
					gridRow: 1,
					placeSelf: 'center',
					position: 'absolute',
					right: -1000,
					zIndex: 1,
					'&:focus': {
						position: 'static',
					},
					[`@media screen and (min-width: ${breakpoints[0]})`]: {
						position: next.disabled ? 'absolute' : 'static',
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				<Translate value="corgi.components.scroll_container.navigation_btn.next" />
			</Button>
			{renderFade()}
		</Box>
	);
}
