import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { LoginModalLoginByEmailMutation, LoginModalLoginByEmailMutationVariables } from './loginByEmail.graphql';

const QUERY = gql`
	mutation LoginModalLoginByEmail($input: LoginByEmailMutationInput!) {
		loginByEmail(input: $input) {
			status
			waitTime
		}
	}
`;

export async function loginByEmail(
	input: LoginModalLoginByEmailMutationVariables['input'],
	{ gql: { fetch } }: UtilityContext,
): Promise<void> {
	const { data, errors } = await fetch<LoginModalLoginByEmailMutation, LoginModalLoginByEmailMutationVariables>({
		path: '/login',
		query: QUERY,
		variables: {
			input,
		},
		important: true, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error('Unknown error');
	if (!data || !data.loginByEmail) throw new Error('Unknown error');
	if (data.loginByEmail.status !== 'LOGGED_IN') throw new Error(`Unexpected status: ${data.loginByEmail.status}`);
}
