import type { ReportableError } from '@change/core/errorReporter/common';

export function reportAddScriptError(
	url: string,
	reportError: (error: ReportableError) => Promise<{
		url?: string | undefined;
	}>,
	e: Error,
): void {
	/* istanbul ignore next */
	(() => {
		// eslint-disable-next-line promise/catch-or-return, promise/no-nesting
		fetch(url)
			// eslint-disable-next-line promise/prefer-await-to-then
			.then(async (response) => (response.ok ? Promise.resolve() : Promise.reject(response)))
			// eslint-disable-next-line promise/prefer-await-to-then
			.then(
				async () =>
					reportError({
						error: new Error(`Could not run script at ${url}`),
					}),
				(response) => {
					void reportError({
						error: new Error(`Could not retrieve script at ${url}`),
						/* eslint-disable @typescript-eslint/no-unsafe-member-access */
						params: response.status
							? {
									message: response.statusText,
									status: response.status,
									url,
								}
							: {
									// eslint-disable-next-line @typescript-eslint/no-unsafe-call
									message: response.toString(),
									url,
								},
						/* eslint-enable @typescript-eslint/no-unsafe-member-access */
					});
				},
			);
	})();
	void reportError(e);
}
