import { getLocation } from '@change/core/window';

export function checkNoticeItemFileCurrentUrl(
	file: string,
): Readonly<{ fileQueryString: string | undefined }> | undefined {
	if (!file) return undefined;
	const currentUrl = getLocation().href;
	// this handles errors like "https://www.change.org/p/slug line 10 > injectedScript"
	// we're only looking at the path, not the query params
	const [fullFilePath] = file.split(' ');
	const [filePath, fileQueryString] = fullFilePath.split('?');
	const [currentUrlPath] = currentUrl.split('?');
	return filePath === currentUrlPath ? { fileQueryString } : undefined;
}
