import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	RestrictedAccessCanEditPetitionQuery,
	RestrictedAccessCanEditPetitionQueryVariables,
} from './canEdit.graphql';

export async function getCanEditPetition(utilities: Pick<UtilityContext, 'gql'>, slugOrId: string): Promise<boolean> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessCanEditPetitionQuery,
		RestrictedAccessCanEditPetitionQueryVariables
	>({
		// cannot be in a .graphql file at this point because this file is indirectly imported by webpack
		query: gql`
			query RestrictedAccessCanEditPetition($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					viewerCanEdit
				}
			}
		`,
		variables: { slugOrId },
		rejectOnError: true,
	});

	return !!data?.petition?.viewerCanEdit;
}
