import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { SetPhoneNumberAndConsentMutation, SetPhoneNumberAndConsentMutationVariables } from './api.graphql';

export type SetPhoneNumberAndConsentInput = SetPhoneNumberAndConsentMutationVariables['input'];

const QUERY = gql`
	mutation SetPhoneNumberAndConsent($input: SetPhoneNumberAndConsentInput!) {
		setPhoneNumberAndConsent(input: $input) {
			success
		}
	}
`;

export async function setPhoneNumberAndConsent(
	{ phoneNumber, consentResponse }: SetPhoneNumberAndConsentInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data } = await fetch<SetPhoneNumberAndConsentMutation, SetPhoneNumberAndConsentMutationVariables>({
		query: QUERY,
		variables: {
			input: {
				phoneNumber,
				consentResponse: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					text: consentResponse.text,
					consentGiven: consentResponse.consentGiven,
					name: consentResponse.name,
				},
			},
		},
	});

	return !!data?.setPhoneNumberAndConsent.success;
}
