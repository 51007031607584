import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-promotion-countries-main',
		path: '/dashboard/promotion_countries',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_promotion_countries_main" */ './index'), {
			exportName: 'AdminPromotionCountriesMain',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['support', 'staff'],
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
