import { useMemo } from 'react';

import { useTracking } from '@change/core/react/tracking';

import type { FormValues } from 'src/app/pages/petitionGamma/details/shared/types';

import { useFcm } from '../../fcm';

import type { EventTrackingData } from './intentData';
import { useSignatureIntentTrackingData } from './intentData';
import { isSampled } from './shared/sampled';

export type SignatureIntentTrackingResult = Readonly<{
	trackSignIntent: (formData: FormValues) => void;
	trackSignIntentValid: (formData: FormValues) => void;
	trackPetitionActionsFlow: (petitionActionsEnabled: boolean) => void;
}>;

export function useSignatureIntentTracking(): SignatureIntentTrackingResult {
	const track = useTracking();
	const { signValidationIntentValidTrackSampleRate } = useFcm();
	const getTrackingData = useSignatureIntentTrackingData();

	return useMemo(
		() => ({
			trackSignIntent: (formData: FormValues) => {
				void track<EventTrackingData>('sign_intent', getTrackingData(formData));
			},
			trackSignIntentValid: (formData: FormValues) => {
				if (!isSampled(signValidationIntentValidTrackSampleRate)) return;

				void track<EventTrackingData>('sign_intent_valid', getTrackingData(formData));
			},
			trackPetitionActionsFlow: (petitionActionsEnabled: boolean) => {
				void track('petition_actions_flow', { enabled: petitionActionsEnabled });
			},
		}),
		[track, signValidationIntentValidTrackSampleRate, getTrackingData],
	);
}
