import React from 'react';
import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';

import type { ToggleButtonProps } from './ToggleButton';

type ChangeEvent = React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>;
type ToggleButtonGroupProps<VALUE> = {
	ariaLabel: string;
	children: React.ReactElement<ToggleButtonProps<VALUE>> | ReadonlyArray<React.ReactElement<ToggleButtonProps<VALUE>>>;
	onChange: (e: ChangeEvent, value: VALUE) => void;
	value: VALUE;
} & Omit<React.ComponentPropsWithRef<typeof Box>, 'onChange'>;

export function ToggleButtonGroup<VALUE>({
	ariaLabel,
	children,
	onChange,
	value,
	...rest
}: ToggleButtonGroupProps<VALUE>): JSX.Element {
	return (
		<Box role="group" aria-label={ariaLabel} {...rest}>
			{React.Children.map(children, (child: React.ReactElement<ToggleButtonProps<VALUE>>) => {
				if (!React.isValidElement(child)) {
					return null;
				}

				const ToggleButton = child;

				return React.cloneElement(ToggleButton, {
					onChange,
					selected: ToggleButton.props.value === value,
					value: ToggleButton.props.value,
				});
			})}
		</Box>
	);
}
