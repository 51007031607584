import { useMemo } from 'react';

import { useTracking } from '@change/core/react/tracking';

type Result = {
	trackHeaderClick: () => void;
};

export function useHeaderTracking(): Result {
	const track = useTracking();

	return useMemo(
		() => ({
			trackHeaderClick: () => {
				void track('nav_sap_click');
			},
		}),
		[track],
	);
}
