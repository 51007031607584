import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { createRoutes, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			frame: true,
			restrictedAccess: {
				authOnly: true,
				role: ['product', 'engineering'],
			},
			ssr: {
				// switch this to true to enable SSR
				enabled: false,
				seo: false,
			},
		},
		[
			{
				id: 'admin-experiments-list',
				path: '/admin/experiments',
				component: loadable(async () => import(/* webpackChunkName: "pages/admin_experiments_list" */ './index'), {
					exportName: 'AdminExperimentsList',
					fallback: Loading,
				}),
			},
			{
				id: 'admin-experiments-list-all',
				path: '/admin/experiments/all',
				priority: 10, // higher priority than /admin/experiments/:id
				component: loadable(async () => import(/* webpackChunkName: "pages/admin_experiments_list" */ './index'), {
					exportName: 'AdminExperimentsFullList',
					fallback: Loading,
				}),
			},
		],
	),
];
