import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-revoke-user-tokens',
		path: '/dashboard/revoke_user_tokens',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_revokeUserTokens" */ './index'), {
			exportName: 'RevokeUserTokens',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support'],
		},
		frame: true,
	},
];
