import { useMemo } from 'react';

import { useTracking } from '@change/core/react/tracking';

export type ClosedModalTrackingResult = Readonly<{
	trackClosedModal: () => void;
}>;

export function useClosedModalTracking(): ClosedModalTrackingResult {
	const track = useTracking();

	return useMemo(
		() => ({
			trackClosedModal: () => {
				void track('mobile_sign_modal_closed');
			},
		}),
		[track],
	);
}
