import { useCallback } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';
import type { SessionUser } from '@change/core/session';

import { useRefreshSession } from 'src/app/shared/hooks/session';

import type { LoginByEmailResult } from './api/loginByEmail';
import { loginByEmail } from './api/loginByEmail';

export function useLoginByEmail(): ({ email, password }: { email: string; password: string }) => Promise<SessionUser> {
	const utilityContext = useUtilityContext();
	const { translate, translatePlural, localizeNumber } = useI18n();
	const refreshSession = useRefreshSession();

	const handleResult = useCallback(
		(result: LoginByEmailResult) => {
			const { status, waitTime } = result;

			switch (status) {
				case 'LOGGED_IN':
					return;
				// for staff users who attempt to login without SAML
				case 'INVALID_LOGIN_METHOD':
					throw new Error(translate('corgi.login_or_signup.error.login.invalid_login_method'));
				// for users who input passwords that don't match their email addresses provided
				case 'NOT_FOUND':
					throw new Error(translate('corgi.login_or_signup.error.login.not_found'));
				// for users who attempt to login unsuccessfully multiple times
				case 'RATE_LIMITED': {
					const waitInMinutes = waitTime ? Math.ceil(waitTime / 60) : 0;
					throw new Error(
						translatePlural('corgi.login_or_signup.error.login.rate_limited_with_plural', waitInMinutes, {
							wait: localizeNumber(waitInMinutes),
						}),
					);
				}
				default:
					throw new Error(translate('corgi.login_or_signup.error.network'));
			}
		},
		[translate, translatePlural, localizeNumber],
	);

	return useCallback(
		async ({ email, password }) => {
			handleResult(await loginByEmail({ email, password }, utilityContext));
			const newSession = await refreshSession();
			if (!newSession.user) {
				// FIXME even though this should not happen often, we could use a more specific message
				throw new Error(translate('corgi.login_or_signup.error.network'));
			}
			return newSession.user;
		},
		[utilityContext, refreshSession, translate, handleResult],
	);
}
