import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';
import type { Normalizer } from '@change/core/fcm';

const FCM_ACCESS_CONTROL_CONFIG_DEFAULT_VALUE = {
	variants: [{ variant: 'control', percentage: 100 }],
	users: [],
};

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		variants: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					variant: { type: 'string' },
					percentage: { type: 'number' },
				},
			},
		},
		users: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'string' },
				},
			},
		},
	},
});

const normalizeAndValidateVariants: Normalizer<ReturnType<typeof normalizer>> = (value: unknown) => {
	const res = normalizer(value);
	if (!res.variants.some(({ variant }) => variant === 'control')) {
		throw new Error('missing control variant');
	}
	if (res.variants.reduce((acc, { percentage }) => acc + percentage, 0) !== 100) {
		throw new Error('total variant percentage is not 100');
	}
	return res;
};

export type FcmAccessControlConfigValueType = ReturnType<typeof normalizer>;

export const createAccessControlFcmConfig = (
	name: string,
): {
	name: string;
	normalizer: Normalizer<FcmAccessControlConfigValueType>;
	defaultValue: FcmAccessControlConfigValueType;
} => ({
	name,
	normalizer: createJsonNormalizer(normalizeAndValidateVariants),
	defaultValue: FCM_ACCESS_CONTROL_CONFIG_DEFAULT_VALUE,
});
