import { parseHtml } from '@change/core/react/html';

import { isError } from 'src/app/shared/utils/async';

import type { FormState } from '../shared/types';

type Field = 'email' | 'password';

export function getError(field: Field, formState?: FormState): ReturnType<typeof parseHtml> | undefined {
	if (!formState || !isError(formState)) return undefined;

	const fieldError = formState[field];
	return fieldError ? parseHtml(fieldError) : undefined;
}
