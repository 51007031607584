import qs from 'qs';

import type { INotice } from '@change/core/errorReporter/common';
import { getLocation, getWindow } from '@change/core/window';

import { getRouteConfigFromPath } from 'src/app/routes';

import { checkNoticeItemFileCurrentUrl } from './shared/currentUrl';

/**
 * regroup FB sdk errors by replacing SDK hash with <hash>
 */
export function fixFacebookSdkBackTraceItems(notice: INotice): INotice {
	let facebookSdkHash: string | undefined;
	let facebookSdkLocale: string | undefined;
	notice.errors?.forEach((error) => {
		if (!error.backtrace) {
			return;
		}
		error.backtrace.forEach((item) => {
			const match = /^https:\/\/connect\.facebook\.net\/([\w-]+)\/sdk\.js\?hash=([\w-]+)$/.exec(item.file);
			if (match) {
				[, facebookSdkLocale, facebookSdkHash] = match;
				// eslint-disable-next-line no-param-reassign
				item.file = 'https://connect.facebook.net/<locale>/sdk.js?hash=<hash>';
			}
		});
	});
	if (facebookSdkHash || facebookSdkLocale) {
		// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
		notice.params = { ...notice.params, facebookSdkHash, facebookSdkLocale };
	}
	return notice;
}

/**
 * regroup perimeterX errors by replacing url with url without query params
 */
export function fixPerimeterxBackTraceItems(pxId: string): (notice: INotice) => INotice {
	return (notice: INotice) => {
		let pxQueryParams: qs.ParsedQs | undefined;
		notice.errors?.forEach((error) => {
			if (!error.backtrace) {
				return;
			}
			error.backtrace.forEach((item) => {
				if (item.file.includes(`/${pxId}/`)) {
					const [url, search] = item.file.split('?');
					pxQueryParams = { ...pxQueryParams, ...qs.parse(search || '') };
					// eslint-disable-next-line no-param-reassign
					item.file = url;
				}
			});
		});
		if (pxQueryParams) {
			// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
			notice.params = { ...notice.params, pxQueryParams };
		}
		return notice;
	};
}

/**
 * regroup errors triggered on the page be replacing the page url (used as the file name) by a common <current_url>
 */
export function fixUrlBackTraceItems(notice: INotice): INotice {
	notice.errors?.forEach((error) => {
		if (!error.backtrace) {
			return;
		}
		error.backtrace.forEach((item) => {
			const currentUrlCheckResult = checkNoticeItemFileCurrentUrl(item.file);
			if (currentUrlCheckResult) {
				/* eslint-disable no-param-reassign */
				item.file = '<current_url>';
				item.line = 0;
				item.column = 0;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				notice.params = { fileQueryString: currentUrlCheckResult.fileQueryString, ...notice.params };
				/* eslint-enable no-param-reassign */
			}
		});
	});
	return notice;
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
export function addOptimizelyParams(notice: INotice): INotice {
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const window = getWindow() as any;
		const optimizelyExperiments =
			window.optimizely && window.optimizely.get
				? window.optimizely.get('state').getExperimentStates({ isActive: true })
				: undefined;

		if (!optimizelyExperiments) return notice;

		// eslint-disable-next-line no-param-reassign
		notice.params = {
			...notice.params,
			optimizelyExperiments: !!Object.keys(optimizelyExperiments || {}).length,
			optimizelyExperimentsIds: Object.keys(optimizelyExperiments || {}),
		};
		return notice;
	} catch (e) {
		return notice;
	}
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */

export function addRouteContext(notice: INotice): INotice {
	try {
		const match = getRouteConfigFromPath(getLocation().pathname);
		if (!match) return notice;

		// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
		notice.context = {
			...notice.context,
			route: {
				id: match.config.id,
				path: match.path,
				params: match.params,
			},
		};
		return notice;
	} catch (e) {
		return notice;
	}
}
