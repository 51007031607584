import { useCallback } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';
import type { SessionUser } from '@change/core/session';

import type { GoogleLoginResultData } from 'src/app/shared/hooks/auth';
import { useRefreshSession } from 'src/app/shared/hooks/session';

type Result = ModelHookResult<
	EmptyObject,
	{
		handleSuccess: (data: GoogleLoginResultData) => Promise<void>;
		handleFailure: () => void;
	}
>;

export function useGoogleButton({
	onError,
	onSuccess,
}: {
	onError: (err: string) => void;
	onSuccess: (user: SessionUser) => void;
}): Result {
	const { translate } = useI18n();
	const refreshSession = useRefreshSession();
	const track = useTracking();

	return {
		data: {},
		actions: {
			handleFailure: useCallback(() => {
				// TODO tracking
				onError(translate('corgi.login_or_signup.error.google.generic'));
			}, [onError, translate]),
			handleSuccess: useCallback(
				async (data) => {
					try {
						const newSession = await refreshSession();
						if (!newSession.user) {
							// FIXME even though this should not happen often, we could use a more specific message
							throw new Error(translate('corgi.login_or_signup.error.google.generic'));
						}
						void track('successful_login_or_join', {
							context: 'log_in',
							id: `user_${newSession.user.id}`,
							login_or_join_method: 'manual_google',
							login_type: data.existingUser ? 'google_login' : 'google_signup',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'new_user_this_session?': !data.existingUser,
							user_flow: 'payment',
							user_id: parseInt(newSession.user.id, 10),
						});
						onSuccess(newSession.user);
					} catch (e) {
						onError((e as Error).message);
					}
				},
				[onError, onSuccess, refreshSession, track, translate],
			),
		},
	};
}
