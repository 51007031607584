import type { ComponentProps } from 'react';

import type { Box } from '@change/design-system/layout';
import type { ResponsiveArrayValue, ResponsiveValue } from '@change/design-system/theme';

type BackgroundColor = ComponentProps<typeof Box>['backgroundColor'];

const TRANSPARENT_BACKGROUND = 'transparent';

export function toLinearGradient(backgroundColor: BackgroundColor, side: 'left' | 'right'): ResponsiveValue<string> {
	if (backgroundColor === undefined || backgroundColor === TRANSPARENT_BACKGROUND) return TRANSPARENT_BACKGROUND;

	if (Array.isArray(backgroundColor)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return backgroundColor.map((color) =>
			color === TRANSPARENT_BACKGROUND ? 'transparent' : `linear-gradient(to ${side}, transparent, ${color} 90%)`,
		) as ResponsiveArrayValue<string>;
	}
	return `linear-gradient(to ${side}, transparent, ${backgroundColor} 90%)`;
}
