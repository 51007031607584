import type { ComponentType, JSX, SuspenseProps } from 'react';
import { lazy, Suspense, useMemo } from 'react';

import { ClientRender } from '@change/core/react/ssr/render';
import { Loader } from '@change/design-system/components/progressiveDisclosure';

type Props<COMP_PROPS extends Record<string, unknown> = EmptyProps> = COMP_PROPS & {
	loadComponent: () => Promise<ComponentType<COMP_PROPS>>;
	fallback?: SuspenseProps['fallback'];
};

export function LazyComponent<COMP_PROPS extends Record<string, unknown> = EmptyProps>({
	loadComponent,
	fallback = <Loader size="m" mx="auto" />,
	...compProps
}: Props<COMP_PROPS>): JSX.Element {
	const LazyComponentInner = useMemo(
		() =>
			lazy(async () => ({
				default: await loadComponent(),
			})),
		[loadComponent],
	);

	return (
		<ClientRender>
			<Suspense fallback={fallback}>
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions */}
				<LazyComponentInner {...(compProps as any)} />
			</Suspense>
		</ClientRender>
	);
}
