import React, { useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { EmbeddedVideo, Image } from '@change/design-system/components/media';
import { iconPlayArrow } from '@change/design-system/icons';
import { Box } from '@change/design-system/layout';

import { useFocusFallbackRef } from 'src/app/shared/hooks/focus';

/**
 * Uses a facade pattern to prevent third party resources from loading until user interaction
 * https://web.dev/third-party-facades/
 */
type VideoProps = { html: string; aspectRatio: '1/1' | '16/9'; backgroundImage: string; alt: string };
export function Video({ html, aspectRatio, backgroundImage, alt }: VideoProps): JSX.Element {
	const [loaded, setLoaded] = useState(false);
	const ref = useFocusFallbackRef();

	const handleLoadVideo = (e: React.SyntheticEvent<HTMLDivElement | HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setLoaded(true);
	};

	return (
		<Box ref={ref}>
			{loaded ? (
				<EmbeddedVideo embedHtml={html} aspectRatio={aspectRatio} sx={{ height: '100%' }} />
			) : (
				<Box sx={{ position: 'relative' }}>
					<Button
						mode="icon"
						icon={iconPlayArrow}
						sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: 70 }}
						onClick={handleLoadVideo}
					>
						<Translate value="corgi.home.a11y.load_video_button_label" />
					</Button>
					<Image aspectRatio={aspectRatio} src={backgroundImage} fit="cover" onClick={handleLoadVideo} alt={alt} />
				</Box>
			)}
		</Box>
	);
}
