import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useUtilityContext } from '@change/core/react/utilityContext';

export function FaviconContainer(): JSX.Element | null {
	const {
		environment: { getEnvironment },
	} = useUtilityContext();
	const environment = getEnvironment();

	if (['production'].includes(environment)) {
		// leave defaults
		return null;
	}

	return (
		<Helmet>
			<link rel="icon" type="image/png" href={`https://static.change.org/favicons/favicon-48x48-${environment}.png`} />
			<link rel="apple-touch-icon" href={`https://static.change.org/favicons/favicon-57x57-${environment}.png`} />
			<link
				rel="apple-touch-icon"
				sizes="72x72"
				href={`https://static.change.org/favicons/favicon-72x72-${environment}.png`}
			/>
			<link
				rel="apple-touch-icon"
				sizes="114x114"
				href={`https://static.change.org/favicons/favicon-114x114-${environment}.png`}
			/>
		</Helmet>
	);
}
