/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { FeatureConfigData, FeatureConfigInfo } from '@change/core/fcm';
import { createMandatoryContext } from '@change/core/react/context';

import type { FcmFunctionsOptions, FcmFunctionsReturnValue } from 'src/app/shared/hooks/fcm';
import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

type Options<T extends Record<string, FeatureConfigInfo>, P extends boolean, DATA_PROPERTY extends string = 'fcm'> = {
	/**
	 * name of the context, to be used for the Provider's display name
	 * and any error being possibly thrown
	 *
	 * e.g. "MyFcmContext"
	 */
	name: string;
	/**
	 * Name of the property that will contain the FCM values in the returned objects + name of the prop in the provider
	 *
	 * Default is "fcm"
	 */
	dataProperty?: DATA_PROPERTY;
	/**
	 * FCM configs (from @change/config/fcm/configs)
	 */
	configs: T;
} & Omit<FcmFunctionsOptions<true, P, false, DATA_PROPERTY>, 'hook' | 'getter' | 'valueProperty'>;

type ContextFactoryResult<
	T extends Record<string, FeatureConfigInfo>,
	DATA_PROPERTY extends string = 'fcm',
> = ReturnType<
	typeof createMandatoryContext<
		FeatureConfigData<T>,
		{
			[key in DATA_PROPERTY]: FeatureConfigData<T>;
		}
	>
>;

type Result<T extends Record<string, FeatureConfigInfo>, P extends boolean, DATA_PROPERTY extends string = 'fcm'> = {
	/**
	 * The context that will hold the synchronous FCM data
	 */
	FcmContext: ContextFactoryResult<T, DATA_PROPERTY>['Context'];
	/**
	 * The provider that will expect the synchronous FCM data
	 */
	FcmProvider: ContextFactoryResult<T, DATA_PROPERTY>['Provider'];
	/**
	 * The synchronous hook based on the context
	 */
	useFcm: ContextFactoryResult<T, DATA_PROPERTY>['useContext'];
	/**
	 * The asynchonous hook that will hold the data that should be passed to the provider
	 */
	useAsyncFcm: FcmFunctionsReturnValue<T, true, P, false, DATA_PROPERTY>['useFcm'];
} & Omit<FcmFunctionsReturnValue<T, true, P, false, DATA_PROPERTY>, 'useFcm'>;

export function createFcmContext<
	T extends Record<string, FeatureConfigInfo>,
	P extends boolean,
	DATA_PROPERTY extends string = 'fcm',
>({
	name,
	dataProperty: dataPropertyProp,
	configs,
	...rest
}: Options<T, P, DATA_PROPERTY>): Result<T, P, DATA_PROPERTY> {
	const dataProperty = dataPropertyProp || 'fcm';

	const { Context, Provider, useContext } = createMandatoryContext<
		FeatureConfigData<T>,
		{
			[key in DATA_PROPERTY]: FeatureConfigData<T>;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	>(undefined, {
		name,
		processProviderProps: (props: {
			[key in DATA_PROPERTY]: FeatureConfigData<T>;
		}): FeatureConfigData<T> => props[dataProperty as DATA_PROPERTY],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { useFcm, ...functions } = createFcmFunctions(configs, {
		...rest,
		hook: true,
		valueProperty: dataProperty,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}) as any;

	return {
		FcmContext: Context,
		FcmProvider: Provider,
		useFcm: useContext,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		useAsyncFcm: useFcm,
		...functions,
	} as unknown as Result<T, P, DATA_PROPERTY>;
}
