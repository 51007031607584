import type { UtilityContext } from '@change/core/react/utilityContext';

import {
	PetitionDetailsPageGdprConsent,
	type PetitionDetailsPageGdprConsentQuery,
	type PetitionDetailsPageGdprConsentQueryVariables,
} from './gdprConsentProvided.graphql';

export async function isGdprConsentProvided({ gql: { fetch } }: UtilityContext): Promise<boolean> {
	const { data } = await fetch<PetitionDetailsPageGdprConsentQuery, PetitionDetailsPageGdprConsentQueryVariables>({
		query: PetitionDetailsPageGdprConsent,
		rejectOnError: true,
	});
	const session = data?.session;
	if (!session) {
		throw new Error('Cannot retrieve session');
	}
	return !!session.consentResponses.globalConsentInitialEu;
}
