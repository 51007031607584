import { getHelpUrl } from '@change/config/help';
import { useI18n } from '@change/core/react/i18n';

import { useLocale } from 'src/app/shared/hooks/l10n';
import { useRoutes } from 'src/app/shared/hooks/routes';

import type { LinkGroup } from '../../shared/types';

export function useSupportLinks(): LinkGroup {
	const { translate } = useI18n();
	const routes = useRoutes();
	const locale = useLocale();

	const links =
		locale === 'en-US'
			? [
					{
						href: getHelpUrl(locale),
						title: translate('fe.components.footer.help'),
					},
					{ href: '/policies/privacy', title: translate('fe.components.footer.privacy') },
					{ href: '/policies/terms-of-service', title: translate('fe.components.footer.terms') },
					{ href: '/policies/cookies', title: translate('fe.components.footer.cookies') },
					{ href: '/u/manage_cookies', title: translate('fe.components.footer.manage_cookies') },
				]
			: [
					{
						href: getHelpUrl(locale),
						title: translate('fe.components.footer.help'),
					},
					{
						href: routes.guides,
						title: translate('fe.components.footer.guides'),
					},
					{ href: '/policies/privacy', title: translate('fe.components.footer.privacy') },
					{ href: '/policies/terms-of-service', title: translate('fe.components.footer.terms') },
					{ href: '/policies/cookies', title: translate('fe.components.footer.cookies') },
					{ href: '/u/manage_cookies', title: translate('fe.components.footer.manage_cookies') },
				];

	return {
		title: translate('fe.components.footer.support'),
		links,
	};
}
