import { InlineMessage } from '@change/design-system/components/alerts';

import type { SupporterVideo } from '../../shared/types';

export function DeleteSuccessMessage({ video }: { video: SupporterVideo }): React.JSX.Element {
	return (
		<InlineMessage variant="success" title="Success" mb={8}>
			{video.videoUrl ? 'Video' : 'Comment'} Successfully removed.
		</InlineMessage>
	);
}
export function DeleteErrorMessage(): React.JSX.Element {
	return (
		<InlineMessage variant="warning" title="Delete failed" mb={8}>
			Something went wrong while deleting.
		</InlineMessage>
	);
}

export function DeleteVideoMessage({
	message,
	video,
}: {
	message: 'success' | 'error' | undefined;
	video: SupporterVideo;
}): React.JSX.Element | null {
	if (message === 'success') return <DeleteSuccessMessage video={video} />;
	if (message === 'error') return <DeleteErrorMessage />;
	return null;
}
