import { forwardRef } from 'react';
import type { JSX } from 'react';

import { Link } from '@change/core/react/router';
import { Box, Flex } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import type { PetitionContentUpdate } from 'src/app/pages/petitionGamma/details/shared/types';

import { UpdateBody } from './UpdateBody';
import { UpdateCardHeading } from './UpdateCardHeading';
import { UpdateMedia } from './UpdateMedia';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

export const UpdateCard = forwardRef<HTMLAnchorElement, Props>(function UpdateCard(
	{ update }: Props,
	ref,
): JSX.Element | null {
	return (
		<Link
			to={update.url}
			sx={{
				...linkResetStyles,
				display: 'block',
				borderRadius: 'standard',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus, &:hover': {
					a: {
						color: 'primary-black',
						textDecoration: 'none',
					},
				},
			}}
			ref={ref}
		>
			<Box
				variant="bordered"
				sx={{
					borderColor: 'neutral-grey400',
					overflow: 'hidden',
				}}
				backgroundColor="primary-white"
			>
				<UpdateCardHeading update={update} />
				<Flex
					sx={{
						p: 16,
						gap: 16,
					}}
				>
					<UpdateMedia update={update} />

					<Box
						sx={{
							flex: 1,
						}}
					>
						<Text
							as="div"
							fontWeight="bold"
							mb={4}
							sx={{
								fontSize: [16, 24],
								lineHeight: 1.25,
							}}
						>
							{update.title}
						</Text>
						<Text as="div" color="typography-secondary" mb={4}>
							{update.createdAtLocalized}
						</Text>
						<UpdateBody update={update} />
					</Box>
				</Flex>
			</Box>
		</Link>
	);
});
