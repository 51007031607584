import type { TrackingFn } from '@change/core/react/tracking';
import { openWindow } from '@change/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareNextdoor(
	url: string,
	text: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): void {
	openWindow(
		`https://nextdoor.com/sharekit/?source=change.org&body=${encodeURIComponent(text)}%20${encodeURIComponent(url)}`,
	);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
