import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-comms-orders-variants',
		path: '/dashboard/comms/send_orders/:id/variants',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_comms_orders_variants" */ './index'), {
			exportName: 'AdminCommsOrdersVariants',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['staff'],
		},
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
