import type { ComponentProps, JSX } from 'react';

import { Separator } from '@change/design-system/components/content';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { GRAY, GREEN } from './consts';
import { Step } from './Step';

type Props = {
	stepperText?: React.ReactNode;
	currentStep: 1 | 2 | 3;
	textOnly?: boolean;
};

export function Stepper({
	stepperText,
	currentStep,
	textOnly,
	...rest
}: Props & ComponentProps<typeof Flex>): JSX.Element {
	return (
		<Flex sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} pt={[16, 0]} {...rest}>
			{stepperText && <Text sx={{ textAlign: 'center' }}>{stepperText}</Text>}
			{!textOnly && (
				<Flex sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }} mt={stepperText ? 16 : 0}>
					<Step isStepCompleted={currentStep > 1} number={1} stepColor={currentStep >= 1 ? GREEN : GRAY} mx={4} />
					<Separator
						sx={{
							backgroundColor: currentStep >= 2 ? GREEN : GRAY,
							width: [50, 80],
							height: 2,
							borderBottom: 0,
						}}
						bg={currentStep >= 2 ? 'green.teal' : 'gray.medium'}
						mx={[0, 4]}
					/>
					<Step isStepCompleted={currentStep > 2} number={2} stepColor={currentStep >= 2 ? GREEN : GRAY} mx={4} />
					<Separator
						sx={{
							backgroundColor: currentStep >= 3 ? GREEN : GRAY,
							width: [50, 80],
							height: 2,
							borderBottom: 0,
						}}
						bg={currentStep >= 2 ? 'green.teal' : 'gray.medium'}
						mx={[0, 4]}
					/>
					<Step isStepCompleted={currentStep > 3} number={3} stepColor={currentStep >= 3 ? GREEN : GRAY} mx={4} />
				</Flex>
			)}
		</Flex>
	);
}
