import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { isLoaded } from 'src/app/shared/utils/async';

import { useLoginStateAsync } from '../loginState';

import { getViewerPhoneNumber } from './api';
import type { PhoneNumberData } from './api';

export function useCurrentUserPhoneNumber(): QueryHookResult<{ data: PhoneNumberData | undefined }> {
	const utilityContext = useUtilityContext();
	const loginStateState = useLoginStateAsync();
	const loginState = isLoaded(loginStateState) ? loginStateState.value : '';

	// TODO: refetch when user changes? e.g. login without page reload
	// Could also be stored in the session context so we don't make the request multiple times
	return useQuery(
		async () => ({ data: await getViewerPhoneNumber(loginState, utilityContext) }),
		[loginState, utilityContext],
	);
}
