import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { isFbLoggedIn, NOT_KNOWN } from 'src/app/shared/utils/loginOrJoin';
import type { LoginSignupCommonTrackingProps } from 'src/app/shared/utils/loginOrJoin';

export function useTrackingCommonProps(): () => LoginSignupCommonTrackingProps {
	const { facebook } = useUtilityContext();

	return useCallback(
		() => ({
			connected_to_facebook: facebook.isConnected,
			connected_to_google: NOT_KNOWN,
			context: 'combined',
			email_domain: NOT_KNOWN,
			fb_logged_in: isFbLoggedIn(facebook.authStatus),
			google_logged_in: NOT_KNOWN,
			google_permissions: 'none',
			has_set_password: false,
			new_user: NOT_KNOWN,
		}),
		[facebook],
	);
}
