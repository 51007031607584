import type { ComponentProps, JSX } from 'react';

import { Icon } from '@change/design-system/components/icon';
import { iconCheck } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

export type StepProps = {
	isStepCompleted: boolean;
	number: number;
	stepColor: string;
	filled?: boolean;
};

export function Step({
	isStepCompleted,
	number,
	stepColor,
	filled,
	...rest
}: StepProps & ComponentProps<typeof Flex>): JSX.Element {
	if (isStepCompleted) {
		return (
			<Flex
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: stepColor,
					borderRadius: '100%',
					width: 26,
					height: 26,
				}}
				mr={4}
				data-testid="complete-step"
				{...rest}
			>
				<Icon icon={iconCheck} color="primary-white" size="m" />
			</Flex>
		);
	}
	if (filled) {
		return (
			<Flex
				color={stepColor}
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: stepColor,
					borderRadius: '100%',
					width: 26,
					height: 26,
				}}
				data-testid="incomplete-step"
				{...rest}
			>
				<Text color="primary-white" fontWeight="bold" size="small">
					{number}
				</Text>
			</Flex>
		);
	}
	return (
		<Flex
			color={stepColor}
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				borderWidth: 2,
				borderColor: stepColor,
				borderStyle: 'solid',
				borderRadius: '100%',
				width: 26,
				height: 26,
			}}
			data-testid="incomplete-step"
			{...rest}
		>
			<Text color={stepColor} fontWeight="bold" size="small">
				{number}
			</Text>
		</Flex>
	);
}
