import type { JSX } from 'react';

import { Image } from '@change/design-system/components/media';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Flex } from '@change/design-system/layout';

import type { PetitionContentUpdate } from 'src/app/pages/petition/details/shared/types';
import { useLoadImage } from 'src/app/shared/hooks/image';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

const MAX_ATTEMPTS = 10;
const POLLING_TIME = 3 * 1000; // 3sec

export function UpdateMedia({ update }: Props): JSX.Element | null {
	const { photo } = update;

	const imageLoaded = useLoadImage(photo?.url, {
		maxAttempts: MAX_ATTEMPTS,
		pollingTime: POLLING_TIME,
		processing: photo?.processing,
	});

	if (!photo) {
		return null;
	}

	if (imageLoaded) {
		return (
			<Image
				aspectRatio="16/9"
				fit="cover"
				sx={{
					width: '100%',
					// removes an unwanted padding under the image
					verticalAlign: 'top',
				}}
				src={photo.url}
				alt=""
				loading="lazy"
			/>
		);
	}

	return (
		<Flex
			sx={{
				aspectRatio: '16/9',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			aria-hidden="true"
		>
			<Loader size="xs" />
		</Flex>
	);
}
