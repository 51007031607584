import type { FeatureConfigData, FeatureConfigInfo } from '@change/core/fcm';
import type { UtilityContext } from '@change/core/react/utilityContext';

export type ExperimentNameFunction<
	T extends Record<string, FeatureConfigInfo>,
	E extends string | undefined = string | undefined,
> = (context: CallbackContext<OptionsPartial<T>['fcmConfigs']>) => E;
export type ExperimentName<
	T extends Record<string, FeatureConfigInfo>,
	E extends string | undefined = string | undefined,
> = NonNullable<E> | ExperimentNameFunction<T, E>;

export type ResolvedExperimentName<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>> =
	EN extends ExperimentNameFunction<T> ? ReturnType<EN> : EN;

export type Result<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>> = {
	fcm: FeatureConfigData<T>;
	name: ResolvedExperimentName<T, EN>;
	variation: string;
};

export type ResultState<
	T extends Record<string, FeatureConfigInfo>,
	EN extends ExperimentName<T>,
	PROP extends string = 'experiment',
> = ({ status: 'loaded' } & { [key in PROP]: Result<T, EN> }) | { status: 'loading' };

type CallbackContext<T extends Record<string, FeatureConfigInfo>> = {
	fcm: FeatureConfigData<T>;
	locale: string;
	countryCode: string;
};

// using this to force TS to use fcmConfigs for type inference
type OptionsPartial<T extends Record<string, FeatureConfigInfo>> = {
	/**
	 * An object of FCM configs (see src/app/shared/hooks/fcm).
	 */
	fcmConfigs: T;
};
export type FcmExperimentConfig<
	T extends Record<string, FeatureConfigInfo> = Record<string, FeatureConfigInfo>,
	EN extends ExperimentName<T> = string,
> = OptionsPartial<T> & {
	/**
	 * A function that returns true if the experiment is enabled and should be treated.
	 */
	isEnabled: (context: CallbackContext<OptionsPartial<T>['fcmConfigs']>) => boolean;
	/**
	 * The name of the experiment to treat and return the variation of.
	 *
	 * A function can be used if the experiment is dynamic (e.g. depends on a FCM and/or the user's locale or country code).
	 *
	 * If a function, can also return "undefined" in which case no experiment will be treated.
	 */
	experimentName: EN;
};

export type HookOptions<HP extends string = 'experiment'> = {
	/**
	 * Makes the React hook SSR-compatible for when user data is not available
	 *
	 * => will result into waiting for 2nd render to return a loaded state
	 */
	async?: boolean;
	/**
	 * The key of the property that will hold the values in the hook's return value
	 *
	 * Defaults to "experiment"
	 */
	valueProperty?: HP;
};

export function getExperimentName<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>>(
	context: CallbackContext<T>,
	experimentName: EN,
): ResolvedExperimentName<T, EN> {
	return (typeof experimentName === 'string' ? experimentName : experimentName(context)) as ResolvedExperimentName<
		T,
		EN
	>;
}

export type GetterContext = {
	utilityContext: UtilityContext;
	l10n: { locale: string; countryCode: string };
};
