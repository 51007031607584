import { createCachePrefetchContextStateItemFunctions } from '@change/core/react/prefetch';

import { mutations, useFcmState } from './context';

export const {
	useCachePrefetchContext: useFcmCachePrefetchContext,
	createCachePrefetchContext: createFcmCachePrefetchContext,
} = createCachePrefetchContextStateItemFunctions({
	cacheKey: 'fcm',
	useState: useFcmState,
	mutations,
	initialState: { cache: {}, queue: [] },
});
