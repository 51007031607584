import { useCallback, useState } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import { createPublishedPetition } from './api';
import { ERROR_TYPES } from './errors';
import type { PublishedPetitionResponse } from './types';

export type SavePetitionError = Error | undefined;

export type SavePetitionResult = [
	(petitionInput: PetitionInput) => Promise<PublishedPetitionResponse>,
	{
		success: PublishedPetitionResponse | null;
		error: SavePetitionError;
		loading: boolean;
	},
];

export const useSavePetition = (): SavePetitionResult => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<SavePetitionError>(undefined);
	const [success, setSuccess] = useState<PublishedPetitionResponse | null>(null);

	const utilityContext = useUtilityContext();

	const createPetition = useCallback(
		async (petitionInput: PetitionInput) => {
			try {
				setSuccess(null);
				setError(undefined);
				setLoading(true);
				const petition = await createPublishedPetition({ petition: petitionInput }, utilityContext);
				setSuccess(petition);
				return petition;
			} catch (e: unknown) {
				const err = e instanceof Error ? e : new Error(ERROR_TYPES.UnknownError);
				setError(err);
				throw err;
			} finally {
				setLoading(false);
			}
		},
		[utilityContext],
	);

	return [createPetition, { success, error, loading }];
};
