import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	StartedPetitionsHomepageQuery,
	StartedPetitionsHomepageQueryVariables,
} from './startedPetitionsHomepage.graphql';
import { StartedPetitionsHomepage } from './startedPetitionsHomepage.graphql';

const DAYS_AFTER_CREATE = 30;

function differenceInDays(d1: Date, d2: Date) {
	return Math.floor(
		(Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) -
			Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())) /
			(1000 * 60 * 60 * 24),
	);
}

type GetRecentUserPetitionResponse = NonNullable<
	Extract<
		NonNullable<StartedPetitionsHomepageQuery['viewer']['user']>['startedPetitionsConnection'],
		{ __typename: 'StartedPetitionsConnectionSuccess' }
	>
>['nodes'][0];

export async function getRecentUserPetition({
	gql: { fetch },
	errorReporter,
}: UtilityContext): Promise<GetRecentUserPetitionResponse | undefined> {
	try {
		const { data } = await fetch<StartedPetitionsHomepageQuery, StartedPetitionsHomepageQueryVariables>({
			query: StartedPetitionsHomepage,
			rejectOnError: true,
		});

		if (data?.viewer?.user?.startedPetitionsConnection?.__typename !== 'StartedPetitionsConnectionSuccess') {
			return undefined;
		}

		const mostRecentPetition = data.viewer.user.startedPetitionsConnection.nodes[0];

		if (!mostRecentPetition) return undefined;
		if (mostRecentPetition.status === 'VICTORY') return undefined;

		const publishedAt = mostRecentPetition.publishedAt ? mostRecentPetition.publishedAt : mostRecentPetition.createdAt;

		const dateDiff = differenceInDays(new Date(), new Date(publishedAt));
		if (dateDiff > DAYS_AFTER_CREATE) return undefined;

		return mostRecentPetition;
	} catch (e) {
		void errorReporter.report(`[StarterDashPromptingBanner] Error fetching user petitions: ${String(e)}`);
		return undefined;
	}
}
