import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'login_or_join',
		path: ['/v2/login_or_join', '/login_or_join', '/login_or_join/redirected', '/login', '/join', '/login/join'],
		component: loadable(async () => import(/* webpackChunkName: "pages/loginOrSignup" */ './index'), {
			exportName: 'LoginOrSignup',
			fallback: Loading,
		}),
		frame: { footer: false, header: true },
		preventCookieWall: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
