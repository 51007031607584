import { useEffect, useState } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { initDLocal, loadDLocalScript, mapToDLocalLocale } from 'src/app/shared/utils/payments';
import type { DLocalSDK, SmartFields } from 'src/app/shared/utils/payments';

import type { DLocalInstance, DLocalStatus } from './context';

export function useDLocalSDK(): DLocalInstance {
	const utilityContext = useUtilityContext();
	const [sdk, setSdk] = useState<DLocalSDK | null>(null);
	const [smartFields, setSmartFields] = useState<SmartFields | null>(null);
	const [status, setStatus] = useState<DLocalStatus>('loading');
	const locale = useLocale();
	const country = useCountryCode();

	useEffect(() => {
		(async function initializeDlocal() {
			try {
				await loadDLocalScript(utilityContext.errorReporter.report, utilityContext.environment.getEnvironment());
				const dLocalSdk = initDLocal(utilityContext.environment.getEnvironment(), country);
				setSdk(dLocalSdk);
				setSmartFields(
					dLocalSdk.fields({
						locale: mapToDLocalLocale(locale),
						country,
					}),
				);
				setStatus('ready');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				void utilityContext.errorReporter.report(e);
				setStatus('error');
			}
		})();
	}, [country, locale, utilityContext]);

	return { status, sdk, smartFields };
}
