import { useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { Flex } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';
import { Text } from '@change/design-system/typography';

export type ConfirmModalOptions = {
	title?: string;
	text?: React.ReactNode | readonly React.ReactNode[];

	/**
	 * defaults to fe.components.confirm_modal.buttons.cancel
	 */
	cancelLabel?: string;
	/**
	 * defaults to fe.components.confirm_modal.buttons.confirm
	 */
	confirmLabel?: string;
	/**
	 * - horizontal: aligns to the right, "cancel" first, then "confirm", width adapted to content
	 * - horizontal-wide: takes full width, "cancel" first, then "confirm", each 50% width
	 * - vertical: "confirm" at the top, "cancel" at the bottom, both 100% width
	 *
	 * defaults to "horizontal"
	 */
	buttonsOrientation?: 'vertical' | 'horizontal' | 'horizontal-wide';
	confirmDataQa?: string;
	cancelDataQa?: string;
};

function isTextArray(text: React.ReactNode | readonly React.ReactNode[]): text is readonly React.ReactNode[] {
	return Array.isArray(text);
}

export const useConfirmModal = createModalHook<ConfirmModalOptions, { choice: boolean }>({
	name: 'ConfirmModal',
	title: ({ options: { title } }) => <>{title}</>,
	body: function ConfirmModalBody({
		options: { text, cancelLabel, confirmLabel, buttonsOrientation, confirmDataQa, cancelDataQa },
		closeModal,
	}) {
		const { translate } = useI18n();

		const textArray = useMemo(() => {
			if (!text) return [];
			return isTextArray(text) ? text : [text];
		}, [text]);

		const buttonStyle = useMemo(() => {
			switch (buttonsOrientation) {
				case 'vertical':
					return {
						width: '100%',
					};
				case 'horizontal-wide':
					return {
						marginLeft: 16,
						width: `calc(50% - ${16 / 2}px)`,
					};
				default:
					return {
						marginLeft: 16,
					};
			}
		}, [buttonsOrientation]);

		const buttons = useMemo(() => {
			const list = [
				{
					variant: 'secondary' as const,
					choice: false,
					label: cancelLabel || translate('fe.components.confirm_modal.buttons.cancel'),
					qa: cancelDataQa,
				},
				{
					variant: 'primary' as const,
					choice: true,
					label: confirmLabel || translate('fe.components.confirm_modal.buttons.confirm'),
					qa: confirmDataQa,
				},
			];
			if (buttonsOrientation === 'vertical') {
				list.reverse();
			}
			return list;
		}, [translate, buttonsOrientation, cancelDataQa, confirmDataQa, cancelLabel, confirmLabel]);

		return (
			<>
				{textArray.map((t, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<Text key={idx} as="p" mt={16}>
						{t}
					</Text>
				))}
				<Flex
					sx={{ flexDirection: buttonsOrientation === 'vertical' ? 'column' : 'row' }}
					ml={buttonsOrientation === 'vertical' ? 0 : -16}
				>
					{buttons.map(({ choice, label, qa, variant }) => (
						<Button
							key={choice.toString()}
							variant={variant}
							data-qa={qa}
							sx={buttonStyle}
							onClick={() => closeModal({ choice })}
							mt={16}
						>
							{label}
						</Button>
					))}
				</Flex>
			</>
		);
	},
});
