/**
 * Creating a custom button here for "toggle" button behavior. Styling is different than our existing buttons
 * so I've used a div with button implementation so I don't have to reset styles or have regression issues.
 * Button implementation requirements can be found here: https://www.w3.org/WAI/ARIA/apg/example-index/button/button.html
 */

import type { ComponentProps, JSX, PropsWithChildren } from 'react';

import { Box } from '@change/design-system/layout';

const ToggleButtonStyles = {
	/* eslint-disable @typescript-eslint/naming-convention */
	alignItems: 'center',
	borderColor: 'neutral-grey600',
	borderRadius: 'standard',
	borderStyle: 'solid',
	borderWidth: '1px',
	display: 'flex',
	fontSize: '16px',
	justifyContent: 'center',
	padding: '8px 16px',
	'&:hover': {
		backgroundColor: 'primary-greyBackground',
		borderWidth: '2px',
		cursor: 'pointer',
		padding: '7px 15px',
	},
	'&[aria-pressed=true]': {
		borderWidth: '2px',
		borderColor: 'primary-changeRed',
		color: 'primary-changeRed',
		fontWeight: 'bold',
		padding: '7px 15px',
	},
	/* eslint-enable @typescript-eslint/naming-convention */
};

type ChangeEvent = React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>;
export type ToggleButtonProps<VALUE> = Omit<ComponentProps<typeof Box>, 'onChange'> & {
	onChange?: (e: ChangeEvent, value: VALUE) => void;
	value: VALUE;
	selected?: boolean;
};
export function ToggleButton<VALUE>({
	children,
	onChange,
	selected,
	value,
	...rest
}: PropsWithChildren<ToggleButtonProps<VALUE>>): JSX.Element {
	const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (!onChange) return;

		switch (e.key) {
			case 'Enter': {
				e.preventDefault();
				onChange(e, value);
				break;
			}
			// The default space key behavior is to scroll the page, so we should
			// prevent the default behavior and activate the action handler on keyup instead
			// to mimic native button events
			case ' ':
			case 'Spacebar': {
				e.preventDefault();
				break;
			}
			default:
				break;
		}
	};

	const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (!onChange) return;

		switch (e.key) {
			case ' ':
			case 'Spacebar': {
				e.preventDefault();
				onChange(e, value);
				break;
			}
			default:
				break;
		}
	};

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!onChange) return;

		onChange(e, value);
	};

	return (
		<Box
			role="button"
			aria-pressed={selected}
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			sx={{ ...ToggleButtonStyles }}
			{...rest}
		>
			{children}
		</Box>
	);
}
