import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

/**
 * we need to use the same component reference in each tab route config in order to conserve the tab nav
 * when navigating between tabs
 */
export const ROUTES_COMPONENT = loadable(
	async () => import(/* webpackPrefetch: true, webpackChunkName: "pages/petition" */ 'src/app/pages/petition'),
	{
		exportName: 'PetitionHome',
		fallback: Loading,
	},
);

export const ROUTES: readonly AppRoute[] = [
	// these are defined in the sub-routes
];
