import { Translate } from '@change/core/react/i18n';
import { createModalHook } from '@change/design-system/modals';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import type { ReportContext } from '../shared/types';

import { ReportAbuseFormContainer } from './ReportAbuseForm';

export const useReportAbuseModal = createModalHook<{ reportContext: ReportContext }>({
	name: 'ReportAbuseModal',
	title: () => <Translate value="fe.components.petition_report_abuse.form_title" />,
	body: function ReportAbuseModalBody({ options: { reportContext }, closeModal }) {
		return (
			<>
				<Header />
				<ReportAbuseFormContainer onSuccess={() => closeModal()} {...reportContext} />
				<Footer type={reportContext.type} />
			</>
		);
	},
});
