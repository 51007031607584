import type { JSX } from 'react';

import { Translate, TranslateHtml, useI18n } from '@change/core/react/i18n';
import { Link } from '@change/design-system/components/actions';
import { Text } from '@change/design-system/typography';

import type { ReportType } from '../shared/types';

type Props = {
	type: ReportType;
};

export function Footer({ type }: Props): JSX.Element {
	const { translate } = useI18n();

	const notice = translate(`fe.components.${type}_report_abuse.notice`, {
		tosUrl: '',
		cgUrl: '',
	});

	// for some reason, sometimes the i18n label contains the <a> tag, sometimes not...
	if (!notice.includes('<a')) {
		return (
			<Text as="div" size="caption" sx={{ textAlign: 'center' }} mt={16}>
				<TranslateHtml
					value={`fe.components.${type}_report_abuse.notice`}
					// TODO process the generated links into @change/core Link?
					replacements={{
						tosUrl: (
							<Link to="/policies/terms-of-service">
								<Translate value="fe.pages.terms_of_service.title" />
							</Link>
						),
						cgUrl: (
							<Link to="/policies/community">
								<Translate value="fe.pages.community_guidelines.title" />
							</Link>
						),
					}}
				/>
			</Text>
		);
	}

	return (
		<Text as="div" size="caption" sx={{ textAlign: 'center' }} mt={16}>
			<TranslateHtml
				value={`fe.components.${type}_report_abuse.notice`}
				// TODO process the generated links into @change/core Link?
				replacements={{
					tosUrl: '/policies/terms-of-service',
					cgUrl: '/policies/community',
				}}
			/>
		</Text>
	);
}
