import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { canEditPetition, Loading, petitionSlugCanonicalRedirect } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-edit',
		path: '/p/:slug/edit',
		beforeServerRender: petitionSlugCanonicalRedirect,
		component: loadable(async () => import(/* webpackChunkName: "pages/petition_edit" */ './index'), {
			exportName: 'PetitionEdit',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['staff', canEditPetition('slug')],
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
