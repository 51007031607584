import type { ComponentPropsWithoutRef, JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { Box } from '@change/design-system/layout';

type Props = Readonly<{ viewportSize: 'small' | 'normal' }>;

type BoxProps = ComponentPropsWithoutRef<typeof Box>;

export function ResponsiveBox({
	children,
	viewportSize: size,
	...rest
}: PropsWithChildren<Props & BoxProps>): JSX.Element {
	const display = useMemo(() => (size === 'small' ? ['block', 'none'] : ['none', 'block']), [size]);

	return (
		<Box {...rest} sx={{ display }}>
			{children}
		</Box>
	);
}
