import type { Params } from 'react-router';
import { matchPath } from 'react-router';

import type { AppRoute } from 'src/shared/routes';

import { APP_ROUTES } from '../routes';

export type MatchedRouteConfig = { config: AppRoute; params: Params<string>; path: string };

export function getRouteConfigFromPath(pathname: string): MatchedRouteConfig | undefined {
	for (const config of APP_ROUTES) {
		const paths = Array.isArray(config.path) ? config.path : [config.path];
		if (config.feProxyPath) {
			const feProxyPaths = Array.isArray(config.feProxyPath) ? config.feProxyPath : [config.feProxyPath];
			paths.push(...feProxyPaths);
		}
		for (const path of paths) {
			const match = matchPath(path, pathname);
			if (match) {
				return { config, params: match.params, path };
			}
		}
	}
	return undefined;
}
