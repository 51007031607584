import { createMandatoryContext } from '@change/core/react/context';
import type { Session } from '@change/core/session';

import type { StateWithoutError } from 'src/app/shared/utils/async';

import type { SessionStateMutations } from '../stateContext';

export const {
	Context: SessionContext,
	Provider: SessionContextProvider,
	useContext: useSessionContext,
} = createMandatoryContext<{
	sync: Session | undefined;
	async: StateWithoutError<{ value: Session }>;
	mutations: SessionStateMutations;
	sessionUpdates: number;
}>(undefined, {
	name: 'SessionContext',
});
