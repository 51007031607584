import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_CARD } from './config';

export const {
	FcmExperimentsContext: MembershipAwarenessCardFcmExperimentsContext,
	FcmExperimentsProvider: MembershipAwarenessCardFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipAwarenessCardFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipAwarenessCardFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipAwarenessCardFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipAwarenessCardFcmExperimentsContext',
	dataProperty: 'fcmExperiments',
	configs: {
		showMembershipAwarenessCard: FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_CARD,
	},
	prefetch: true,
});

export type MembershipAwarenessCardFcmExperimentsState = ReturnType<
	typeof useAsyncMembershipAwarenessCardFcmExperiments
>;
export type MembershipAwarenessCardFcmExperiments = LoadedStateData<MembershipAwarenessCardFcmExperimentsState>;
