import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'legal-policy',
		path: ['/policies', '/policies/:slug'],
		component: loadable(async () => import(/* webpackChunkName: "pages/legal_policy" */ './index'), {
			exportName: 'Policy',
			fallback: Loading,
		}),
		frame: true,
		preventCookieWall: true,
		ssr: {
			enabled: true,
			seo: true,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					lifetimeInSeconds: 1 * 60 * 60, // 1 hour
				},
			},
		},
	},
];
