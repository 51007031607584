import type {
	I18nFieldValidator,
	I18nFieldValidatorAsync,
	I18nFieldValidatorResult,
	NonI18nFieldValidator,
	NonI18nFieldValidatorResult,
} from './shared/types';

// eslint-disable-next-line @typescript-eslint/ban-types
export function composeValidators<TYPE, ALL_VALUES extends object = object>(
	...validators: ReadonlyArray<I18nFieldValidator<TYPE, ALL_VALUES>>
): I18nFieldValidator<TYPE, object> {
	return (value, allValues, meta) =>
		validators.reduce<I18nFieldValidatorResult | undefined>(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(error, validator) => error || validator(value, allValues as ALL_VALUES, meta),
			undefined,
		);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function composeNonI18nValidators<TYPE, ALL_VALUES extends object = object>(
	...validators: ReadonlyArray<NonI18nFieldValidator<TYPE, ALL_VALUES>>
): NonI18nFieldValidator<TYPE, object> {
	return (value, allValues, meta) =>
		validators.reduce<NonI18nFieldValidatorResult | undefined>(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(error, validator) => error || validator(value, allValues as ALL_VALUES, meta),
			undefined,
		);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function composeValidatorsAsync<TYPE, ALL_VALUES extends object = object>(
	...validators: ReadonlyArray<I18nFieldValidatorAsync<TYPE, ALL_VALUES>>
): I18nFieldValidatorAsync<TYPE, object> {
	return async (value, allValues, meta) =>
		validators.reduce<Promise<I18nFieldValidatorResult | undefined>>(
			async (promise, validator) =>
				// eslint-disable-next-line promise/prefer-await-to-then, promise/no-return-wrap, @typescript-eslint/consistent-type-assertions
				promise.then(async (error) => Promise.resolve(error || validator(value, allValues as ALL_VALUES, meta))),
			Promise.resolve(undefined),
		);
}
