import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import type { SapCreatePublishedPetitionMutationVariables } from './createPublishedPetition.graphql';

type TransformPetitionInputParams = { petition: PetitionInput };
type CreatePetitionInput = SapCreatePublishedPetitionMutationVariables['input'];

const parseAiFlow = (petition: TransformPetitionInputParams['petition']) => {
	if (!petition.aiResult || !petition.aiResult.ask || !petition.aiResult.description)
		throw new Error('Invalid petition input');

	return {
		ask: petition.aiResult.ask,
		description: petition.aiResult.description,
		aiAsk: petition.aiResult.originalAsk,
		aiDescription: petition.aiResult.originalDescription,
	};
};

const parseDefaultFlow = (petition: TransformPetitionInputParams['petition']) => {
	if (!petition.ask || !petition.description) throw new Error('Invalid petition input');

	return { ask: petition.ask, description: petition.description };
};

export function transformPetitionInput({ petition }: TransformPetitionInputParams): CreatePetitionInput {
	const requiredFields = (() => {
		if (petition.aiPromptChoice === 'ai') return parseAiFlow(petition);

		return parseDefaultFlow(petition);
	})();

	const location = (() => {
		if (!petition.location) return {};
		return {
			location: { googlePlacesId: petition.location.googlePlacesId, localizedName: petition.location.localizedName },
		};
	})();

	const optionalFields = (() => {
		return {
			...(petition.geoReach ? { geoReach: petition.geoReach } : {}),
			...(petition.media?.url ? { media: { photo: { srcUrl: petition.media.url } } } : {}),
			...location,
		};
	})();

	return {
		...optionalFields,
		...requiredFields,
	};
}
