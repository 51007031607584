import { perimeterxConfigs } from '@change/config/perimeterx';
import type { EnvironmentUtils } from '@change/core/environment';
import { createErrorReporter as _createErrorReporter } from '@change/core/errorReporter/client';
import type { ErrorReporter } from '@change/core/errorReporter/common';
import { getLocation } from '@change/core/window';

import airbrakeConfig from 'config/airbrake.browser.json';

import webappInfo from 'src/webappInfo';

import {
	filterOutBrowserExtensions,
	filterOutCurrentUrls,
	filterOutEmptyFileNames,
	filterOutHttpAndRedirectErrors,
	filterOutMiscErrors,
	filterOutQaaScripts,
} from './filters';
import {
	addOptimizelyParams,
	addRouteContext,
	fixFacebookSdkBackTraceItems,
	fixPerimeterxBackTraceItems,
	fixUrlBackTraceItems,
} from './processors';

type Options = Readonly<{
	environment: EnvironmentUtils;
}>;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		newrelic: Readonly<{
			noticeError: (error: string | Error, customAttributes?: Record<string, unknown>) => void;
			// ... and a bunch of other stuff
		}>;
	}
}

export function createErrorReporter({ environment }: Options): ErrorReporter {
	const { hostname } = getLocation();

	const pxId = perimeterxConfigs[environment.getEnvironment()].appId;

	return _createErrorReporter({
		environment: environment.getEnvironment(),
		hostname,
		webappInfo,
		reporters: {
			airbrake: airbrakeConfig,
		},
		silencedBots: true,
		...(process.env.ERROR_REPORTING_ENABLED ? { silencedEnvironments: [] } : {}),
	})
		.addFilter(filterOutHttpAndRedirectErrors)
		.addFilter(filterOutBrowserExtensions)
		.addFilter(filterOutQaaScripts)
		.addFilter(filterOutEmptyFileNames)
		.addFilter(filterOutCurrentUrls)
		.addFilter(filterOutMiscErrors)
		.addProcessor(fixFacebookSdkBackTraceItems)
		.addProcessor(fixPerimeterxBackTraceItems(pxId))
		.addProcessor(fixUrlBackTraceItems)
		.addProcessor(addOptimizelyParams)
		.addProcessor(addRouteContext)
		.toggleLogging(environment.getEnvironment() === 'development')
		.init();
}
