import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const countries: readonly string[] = ['us', 'ca', 'uk', 'au', 'nz'];

export const FCM_MEMBERSHIP_AWARENESS_LOCALIZED_SIGN_COUNTS = createFcmConfig(
	'membership_awareness_localized_sign_counts',
	{
		normalizer: createJsonNormalizer(
			createShapeNormalizer({
				type: 'record',
				keys: countries,
				shape: {
					type: 'record',
					shape: { type: 'number' },
				},
			}),
		),
		defaultValue: {},
	},
);
