import type { TrackingFn } from '@change/core/react/tracking';

function getTrackingCommonData() {
	return {
		location: 'qr_code_flyer_download',
	};
}

export function trackShareSuccess(
	eventName: string,
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	track: TrackingFn,
): void {
	void track(eventName, { ...getTrackingCommonData(), ...trackingData });
}
