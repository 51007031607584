import type { JSX } from 'react';

import type { MembershipSource } from '@change/graphql-schema';

import { useIsVisible } from './hooks';
import { MembershipBadge as MembershipBadgeInner } from './layout';

export type BadgeProps = {
	userId: string;
	membershipData: {
		createdAt: string;
		source: MembershipSource;
	} | null;
	size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
	sourceLocation: string;
	verticalAlign?: 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
};

export function MembershipBadge({ userId, membershipData, ...innerProps }: BadgeProps): JSX.Element | null {
	const isVisible = useIsVisible({ userId, membershipData });
	if (!isVisible) return null;
	return <MembershipBadgeInner {...innerProps} />;
}
