import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-decision-maker-edit',
		path: '/decision-makers/:slug/edit',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_decisionMakerEdit" */ './index'), {
			exportName: 'DecisionMakerEdit',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		frame: true,
	},
];
