import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';
import { getLocales } from '@change/config/locales';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		locales: { type: 'array', shape: { type: 'enum', values: getLocales() } },
	},
});

export const FCM_STARFISH_SHARE_VISUALS = createFcmConfig('starfish_share_visuals', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
