import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	PromotePageLoginModalLoginByEmailMutation,
	PromotePageLoginModalLoginByEmailMutationVariables,
} from './loginByEmail.graphql';

const QUERY = gql`
	mutation PromotePageLoginModalLoginByEmail($input: LoginByEmailMutationInput!) {
		loginByEmail(input: $input) {
			status
			waitTime
		}
	}
`;

export type LoginByEmailResult = PromotePageLoginModalLoginByEmailMutation['loginByEmail'];

export async function loginByEmail(
	input: PromotePageLoginModalLoginByEmailMutationVariables['input'],
	{ gql: { fetch } }: UtilityContext,
): Promise<PromotePageLoginModalLoginByEmailMutation['loginByEmail']> {
	const { data, errors } = await fetch<
		PromotePageLoginModalLoginByEmailMutation,
		PromotePageLoginModalLoginByEmailMutationVariables
	>({
		path: '/login',
		query: QUERY,
		variables: {
			input,
		},
		important: true, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error('Unknown error');
	if (!data || !data.loginByEmail) throw new Error('Unknown error');
	return data.loginByEmail;
}
