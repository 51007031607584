import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';
import type { Session } from '@change/core/session';
import { getSession } from '@change/core/session';

export function useGetNewSession(): () => Promise<Session> {
	const {
		gql: { fetch },
		errorReporter: { report },
		csrf: { refreshCsrfToken },
	} = useUtilityContext();

	return useCallback(async () => {
		const newSession = await getSession({
			gqlFetch: fetch,
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			reportError: report,
		});
		refreshCsrfToken(newSession.csrfToken);
		return newSession;
	}, [fetch, report, refreshCsrfToken]);
}
