import type { Session } from '@change/core/session';

import { useSessionContext } from '../context';

import type { AsyncState } from './shared/types';

export function useSession(): Session {
	const session = useSessionContext().sync;

	if (!session)
		throw new Error('Session not available in SSR env with user data disabled - use xxxAsync version of hook instead');

	return session;
}
export function useSessionAsync(): AsyncState<Session> {
	return useSessionContext().async;
}
