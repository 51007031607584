import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		petition_ids: {
			type: 'array',
			shape: {
				type: 'number',
			},
		},
	},
});

export const FCM_REVENUE_MANUALLY_PROMOTED_VICTORIOUS_PETITION_DATA = createFcmConfig(
	'revenue_manually_promoted_victorious_petition_data',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: undefined,
	},
);
