import type { ComponentProps, ForwardedRef } from 'react';

import { forwardRef } from '@change/core/react/core';
import { Radio } from '@change/design-system/components/forms';
import { Box } from '@change/design-system/layout';

/* eslint-disable @typescript-eslint/naming-convention */
const radioCardStyles = {
	'& > label': {
		borderRadius: 'standard',
		borderWidth: 1,
		borderColor: 'neutral-grey100',
		borderStyle: 'solid',
		backgroundColor: 'primary-greyBackground',
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		'& > input[type="radio"]': {
			margin: 12,
		},
	},
	'& > label:focus-within': {
		outlineRadius: 'standard',
		outlineWidth: 2,
		outlineStyle: 'solid',
		outlineColor: 'secondary-blue',
	},
	'& > label[data-selected="true"]': {
		borderColor: 'secondary-blue',
	},
	'& > label > input[type="radio"]:focus': {
		outline: 'none',
	},
	'& > label > .boolean-input-label': {
		overflow: 'hidden',
		borderTopRightRadius: 'standard',
		borderTopLeftRadius: 'standard',
		backgroundColor: 'background',
	},
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

function RadioCardInner(props: ComponentProps<typeof Radio>, ref: ForwardedRef<HTMLInputElement>): JSX.Element {
	return (
		<Box sx={{ ...radioCardStyles }}>
			<Radio {...props} ref={ref} />
		</Box>
	);
}

export const RadioCard = forwardRef(RadioCardInner);
