import type { JSX, PropsWithChildren } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { Box, Flex } from '@change/design-system/layout';
import type { Theme } from '@change/design-system/theme';
import { derivedStyle } from '@change/design-system/theme';
import { Text } from '@change/design-system/typography';

type Props = {
	signatureCount: number;
	signatureGoal: number;
	animationDuration?: number;
	height?: 'standard' | 'large';
	rounded?: boolean;
};

type WidthOptions = {
	signatureCount: number;
	signatureGoal: number;
	animated: boolean;
};

const MINIMUM_FULL_WIDTH_PERCENT = 20;

const THERMOMETER_HEIGHTS_PX = {
	standard: 12,
	large: 20,
};

type ThermometerTransitionProps = {
	width: string | number;
	transitionDuration: string;
};

function ThermometerTransitionBox({
	width,
	transitionDuration,
	children,
}: PropsWithChildren<ThermometerTransitionProps>) {
	return (
		<Box
			data-testid="thermometer-segment"
			sx={{
				display: 'inline-block',
				height: '100%',
				width,
				background: derivedStyle(
					(theme: Theme) => `linear-gradient(to right, #FFA600, ${(theme.colors || {})['primary-changeRed']})`,
				),
				transitionProperty: 'width',
				transitionTimingFunction: 'ease-in-out',
				transitionDuration,
			}}
		>
			{children}
		</Box>
	);
}

function getThermometerWidth({ signatureCount, signatureGoal, animated }: WidthOptions) {
	if (animated && signatureCount === 0) return 0;
	return Math.max((signatureCount / signatureGoal) * 100, MINIMUM_FULL_WIDTH_PERCENT);
}

/**
 * @deprecated use ProgressIndicator from @change/design-system instead
 */
export function Thermometer({
	children,
	signatureCount,
	signatureGoal,
	height = 'standard',
	rounded = true,
	animationDuration = 0,
}: PropsWithChildren<Props>): JSX.Element {
	const { translate } = useI18n();
	const heightPx = THERMOMETER_HEIGHTS_PX[height];
	return (
		<Box
			role="progressbar"
			aria-valuemin={0}
			aria-valuemax={100}
			aria-valuenow={Math.round((signatureCount / signatureGoal) * 100)}
			aria-label={translate('corgi.components.signature_thermometer.label')}
			sx={{
				height: heightPx,
				borderRadius: rounded ? heightPx : 0,
				borderWidth: 0,
				overflow: 'hidden',
			}}
		>
			<Flex backgroundColor="neutral-grey100" sx={{ height: '100%' }}>
				<ThermometerTransitionBox
					width={`${getThermometerWidth({ signatureCount, signatureGoal, animated: animationDuration > 0 })}%`}
					transitionDuration={`${animationDuration / 1000}s`}
				>
					<Text size="caption" as="div" color="primary-white" sx={{ textAlign: 'right' }}>
						{children}
					</Text>
				</ThermometerTransitionBox>
				<Box
					sx={{
						borderRadius: 0,
						borderWidth: 0,
						borderColor: 'transparent',
						borderTopWidth: heightPx / 2,
						borderTopStyle: 'solid',
						borderTopColor: 'transparent',
						borderBottomWidth: heightPx / 2,
						borderBottomStyle: 'solid',
						borderBottomColor: 'transparent',
						borderLeftWidth: heightPx / 2,
						borderLeftStyle: 'solid',
						borderLeftColor: 'primary-changeRed',
						display: 'inline-block',
					}}
				/>
			</Flex>
		</Box>
	);
}
