/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { css, Global } from '@emotion/react';

import { GlobalStyle } from '@change/design-system/core';

// TODO remove those styles and make sure this does not break UI
// that was relying on those styles being set
const TEMPORARY_OLD_DS_GLOBAL_STYLES = `
/*
	Default typography
*/
body {
	font-size: 16px;
	line-height: 1.5;
}

/*
	Default button styles
*/
button {
	padding: 0;
	background: none;
	border: 0;
	outline: 0;
}

/*
	Box model
*/
*,
*::before,
*::after {
	box-sizing: border-box;
}

/*
	Default list styles
*/
ol,
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

/*
	Default anchor styles
*/
a {
	color: inherit;
	cursor: pointer;
	text-decoration: underline;

	&:active: {
		outline: none;
	}
}

/*
	Paragraphs

	Normalize margins for paragraphs. This allows you to use the <p> tag
	freely without having to apply spacing overrides for margin.
*/
p {
	margin: 0;
}
`;

export function AppGlobalStyle(): JSX.Element {
	return (
		<>
			<GlobalStyle />
			<Global
				styles={css`
					body {
						padding: 0;
						margin: 0;
						font-family: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, Tahoma, sans-serif;
					}

					// css-only inert polyfill
					// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
					// add https://github.com/WICG/inert full polyfill for the Element.inert support
					[inert],
					[inert] * {
						user-select: none;
					}
					[inert] {
						pointer-events: none;
						cursor: default;
					}

					${TEMPORARY_OLD_DS_GLOBAL_STYLES}
				`}
			/>
		</>
	);
}
