import type { TrackingFn } from '@change/core/react/tracking';
import type { UtilityContext } from '@change/core/react/utilityContext';

import { copyToClipboard } from 'src/app/shared/utils/clipboard';

import { trackShareFailure, trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
		failure: string;
	};
};

export async function shareCopy(
	url: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
	i18nContext: UtilityContext['i18n'],
): Promise<boolean> {
	const success = await copyToClipboard(url, i18nContext);
	if (success) {
		trackShareSuccess(trackingEvents.success, trackingData, track);
	} else {
		trackShareFailure(trackingEvents.failure, trackingData, track);
	}
	return success;
}
