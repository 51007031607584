import type { JSX } from 'react';

import { Icon } from '@change/design-system/components/icon';
import { Avatar } from '@change/design-system/components/media';
import { iconEmail, iconSupervisorAccount } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

export type FooterProps = {
	userPhoto?: string;
	username: string;
	userLocation?: string;
	supporters: string;
	creation: string;
};

export function Footer({ userPhoto, username, userLocation, supporters, creation }: FooterProps): JSX.Element {
	return (
		<Flex
			sx={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: 56,
				px: 16,
				columnGap: 8,
			}}
		>
			<Flex sx={{ columnGap: 8, flex: 1, alignItems: 'center' }}>
				<Avatar src={userPhoto} alt="" size={24} variant="subtle" />
				<Flex sx={{ flexDirection: 'column' }}>
					<Text size="caption" ellipsis>
						<Text as="span" size="caption" fontWeight="bold">
							{username}
						</Text>
					</Text>
					<Text size="caption" ellipsis>
						<Text as="span" size="caption">
							{userLocation}
						</Text>
					</Text>
				</Flex>
			</Flex>
			<Flex sx={{ columnGap: 8, alignItems: 'center', width: 100 }}>
				<Icon size="xl" icon={iconSupervisorAccount} />
				<Text size="caption" ellipsis>
					{supporters}
				</Text>
			</Flex>
			<Flex sx={{ display: ['none', 'flex'], columnGap: 8, alignItems: 'center', width: 120 }}>
				<Icon size="xl" icon={iconEmail} />
				<Text size="caption" ellipsis>
					{creation}
				</Text>
			</Flex>
		</Flex>
	);
}
