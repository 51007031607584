import { normalizeBoolean } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_STARFISH_STARTER_DM_RECOMMENDER = createFcmConfig(
	'starfish_starter_dm_recommender',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
	{
		merger: ({ localeValue, countryValue }) => localeValue && countryValue,
	},
);
