import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipAwarenessCardFcmContext,
	FcmProvider: MembershipAwarenessCardFcmContextProvider,
	useFcm: useMembershipAwarenessCardFcm,
	useAsyncFcm: useAsyncMembershipAwarenessCardFcm,
	prefetchFcm: prefetchMembershipAwarenessCardFcm,
} = createFcmContext({
	name: 'MembershipAwarenessCardFcmContext',
	dataProperty: 'fcm',
	configs: {},
	prefetch: true,
});

export type MembershipAwarenessCardFcmState = ReturnType<typeof useAsyncMembershipAwarenessCardFcm>;
export type MembershipAwarenessCardFcm = LoadedStateData<MembershipAwarenessCardFcmState>;
