import { useCallback } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { loginOrSignupByFacebook, LoginOrSignupByFacebookError } from 'src/app/shared/api/auth';
import type { LoginOrSignupWithFacebookContext } from 'src/app/shared/api/auth';

import { useFacebookAutoLoginTracking } from './tracking';

export function useFacebookAutoLogin(): (context: LoginOrSignupWithFacebookContext) => Promise<void> {
	const { trackFailure } = useFacebookAutoLoginTracking();
	const utilityContext = useUtilityContext();

	return useCallback(
		async (context: LoginOrSignupWithFacebookContext) => {
			try {
				await loginOrSignupByFacebook(context, utilityContext);
			} catch (e) {
				trackFailure((e instanceof LoginOrSignupByFacebookError && e.status) || 'unknown');
				throw e;
			}
		},
		[trackFailure, utilityContext],
	);
}
