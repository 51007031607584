import type { ChangeEvent, JSX } from 'react';
import { useCallback, useMemo } from 'react';

import { Field, useField, useForm } from 'react-final-form';

import { translationKey } from '@change/core/i18n';
import { Radio, RadioGroup } from '@change/design-system/components/forms';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import { useCategoryLabel } from '../hooks/useCategoryLabel';
import { getCategories } from '../shared/categories';
import type { Category, FormValues, ReportType } from '../shared/types';

type Props = Readonly<{
	type: ReportType;
	countryCode: string;
}>;

const validator = requiredValidator<Category>({
	i18n: { key: translationKey('fe.components.petition_report_abuse.errors.category_required') },
});

function RadioField({ type, category }: { type: ReportType; category: Category }) {
	const form = useForm();
	const { input } = useField<FormValues['category']>('category', {
		type: 'radio',
		value: category,
	});

	const getLabel = useCategoryLabel(type, 'category');

	// reset the subcategory when the category changes
	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			form.change('subCategory', '');
			input.onChange(e);
		},
		[form, input],
	);

	return (
		<Box my={4}>
			<Radio
				id={`report-abuse-category-${category}`}
				label={getLabel(category)}
				value={input.value}
				name={input.name}
				checked={input.checked}
				onChange={onChange}
				data-testid={`reportAbuse-category-${category}-radio`}
				data-qa={`reportAbuse-category-${category}-radio`}
			/>
			<Box sx={{ display: input.checked ? 'block' : 'none' }} pl="m">
				<Text as="div" size="caption" color="typography-secondary">
					{getLabel(category, true)}
				</Text>
			</Box>
		</Box>
	);
}

export function CategoryField({ type, countryCode }: Props): JSX.Element {
	const { getErrorMessage } = useFormI18n();

	const categories = useMemo(() => getCategories(type, countryCode), [type, countryCode]);

	return (
		<Box p={4}>
			<Field<FormValues['category']> name="category" subscription={{ error: true, touched: true }} validate={validator}>
				{({ meta }) => (
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					<RadioGroup id="report-abuse-category" error={meta.touched && getErrorMessage(meta.error)}>
						{categories.map((category) => (
							<RadioField type={type} category={category} key={category} />
						))}
					</RadioGroup>
				)}
			</Field>
		</Box>
	);
}
