import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useRefreshSession } from 'src/app/shared/hooks/session';
import { isUserAlreadyExistsError, setPxUserData } from 'src/app/shared/utils/payments';

import { useInformVerificationEmail } from '../useInformVerificationEmail';
import { useLoginWithModal } from '../useLoginWithModal';

import { createUser as createUserApi } from './api';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	signupContext: string;
	path: string;
	googleAuthEnabled: boolean;
};

type Result = { success: true } | { success: false; isUserAlreadyExistsError: boolean };

export function useCreateUser(): (props: Props) => Promise<Result> {
	const utilityContext = useUtilityContext();
	const countryCode = useCountryCode();
	const refreshSession = useRefreshSession();
	const login = useLoginWithModal();
	const informVerificationEmail = useInformVerificationEmail();

	return async function createUser({
		firstName,
		email,
		lastName,
		signupContext,
		googleAuthEnabled,
		phoneNumber,
		path,
	}): Promise<Result> {
		try {
			const result = await createUserApi(
				{
					firstName,
					lastName,
					email,
					countryCode,
					signupMethod: 'PAYMENT',
					signupContext,
					...(phoneNumber ? { phoneNumber } : {}),
				},
				utilityContext,
			);
			if (typeof result === 'object') {
				setPxUserData(result);
				await refreshSession();
			}
			return { success: true };
		} catch (error) {
			if (!isUserAlreadyExistsError(error)) {
				return { success: false, isUserAlreadyExistsError: false };
			}
			if (error.userPasswordSet) {
				void login({ email, showContinueAsGuest: false, googleAuthEnabled });
			} else {
				void informVerificationEmail({ showContinueAsGuest: false, userId: error.userId, path });
			}
			return { success: false, isUserAlreadyExistsError: true };
		}
	};
}
