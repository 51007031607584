import { createStateContext } from '@change/core/react/context';

import { mutations } from './mutations';
import type { ShortUrlsCacheState } from './types';

export const {
	StateContext: ShortUrlsStateContext,
	StateProvider: ShortUrlsStateContextProvider,
	useStateContext: useShortUrlsState,
} = createStateContext<ShortUrlsCacheState, typeof mutations, { initialState?: ShortUrlsCacheState }>({
	name: 'ShortUrlsStateContext',
	mutations,
	initialState: ({ initialState }) => initialState || { cache: {}, queue: [] },
});

export type { ShortUrlsCacheState };

export { mutations };
