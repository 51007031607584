import type { TrackingFn } from '@change/core/react/tracking';
import type { UtilityContext } from '@change/core/react/utilityContext';
import { openWindow } from '@change/core/window';

import { trackShareSuccess } from './track';
import { buildUrl } from './url';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

// eslint-disable-next-line max-params
export function shareSms(
	url: string,
	text: string | undefined,
	{ trackingEvents, trackingData }: Options,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	openWindow(buildUrl(text ? `${text}\n${url}` : url, utilityContext));
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
