import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'account-settings-privacy',
		path: '/account_settings/privacy',
		component: loadable(async () => import(/* webpackChunkName: "pages/account_settings_privacy" */ './index'), {
			exportName: 'Privacy',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
		},
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
