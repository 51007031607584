import { useMemo, useState } from 'react';

import { useLocation } from 'react-router';

import { useTracking } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCookiePrefAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

const VISIBLE_LOCATIONS = [
	{ path: '/', name: 'home' },
	{ path: '/u/me', name: 'my_petitions' },
	{ path: /^\/browse($|\/)/, name: 'browse' },
];

type Result = {
	show: boolean;
	pageTrackingName?: string;
	onClickClose: () => void;
};

const SEVEN_DAYS = 7;

const BANNER_COOKIE_NAME = 'mem_banner_x';

export function useShowBanner(): Result {
	const [bannerClosed, setBannerClosed] = useState<boolean | undefined>();
	const track = useTracking();
	const { pathname } = useLocation();
	const { cookies } = useUtilityContext();

	const cookieAllowedState = useCookiePrefAsync('preferences');
	const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;

	const onClickClose = () => {
		void track('membership_awareness_banner_click_close');
		setBannerClosed(true);
		if (cookieAllowed) {
			cookies.set(BANNER_COOKIE_NAME, JSON.stringify(true), { expires: SEVEN_DAYS, path: '/' });
		}
	};

	const closedBefore = useMemo(() => {
		if (!cookieAllowed) return !!bannerClosed;

		const cookieData = cookies.get(BANNER_COOKIE_NAME);
		if (!cookieData) return !!bannerClosed;

		try {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const result = JSON.parse(cookieData) as unknown;
			if (typeof result === 'boolean') return result;
			return false;
		} catch {
			return false;
		}
	}, [cookies, bannerClosed, cookieAllowed]);

	const visiblePage = useMemo(() => {
		return VISIBLE_LOCATIONS.find((visibleLocation) =>
			typeof visibleLocation.path === 'string'
				? visibleLocation.path === pathname
				: visibleLocation.path.test(pathname),
		);
	}, [pathname]);

	return {
		show: !closedBefore && !!visiblePage,
		pageTrackingName: visiblePage?.name,
		onClickClose,
	};
}
