import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading, starter } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'smart-images',
		path: '/p/:slug/smart',
		component: loadable(async () => import(/* webpackChunkName: "pages/smart_images" */ './index'), {
			exportName: 'SmartImages',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support', starter('slug')],
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
