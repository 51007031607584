import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'membership-guest-admin',
		path: '/member/:id/admin',
		component: loadable(async () => import(/* webpackChunkName: "pages/membership_guestAdmin" */ './index'), {
			exportName: 'GuestAdmin',
			fallback: Loading,
		}),
		frame: true,
	},
];
