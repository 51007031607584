import type { Session } from '@change/core/session';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

export function useLoginState(): Session['loginState'] {
	return useSession().loginState;
}
export function useLoginStateAsync(): AsyncState<Session['loginState']> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({ value: value.loginState }));
}
