import { loadable } from '@change/core/react/loadable';

import { Loading } from 'src/app/shared/routes';

export const PetitionGammaHome = loadable(
	async () => import(/* webpackChunkName: "pages/petition_gamma_home" */ './containers/Home'),
	{
		exportName: 'PetitionHomeContainer',
		fallback: Loading,
	},
);
