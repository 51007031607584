import { FCM_ENABLE_SUBSCRIPTIONS_FOR } from '@change/config/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm, prefetchFcm } = createFcmFunctions(
	{
		membershipEnabled: FCM_ENABLE_SUBSCRIPTIONS_FOR,
	},
	{ hook: true, prefetch: true },
);
