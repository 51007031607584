import type { JSX } from 'react';

import { forwardRef } from '@change/core/react/core';
import { useI18n } from '@change/core/react/i18n';
import { Icon } from '@change/design-system/components/icon';
import { Avatar } from '@change/design-system/components/media';
import { iconMenu } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { buttonResetStyles } from '@change/design-system/reset';
import type { ResponsiveValue } from '@change/design-system/theme';

type Props = {
	avatarUrl: string;
	headerHeights: ResponsiveValue<number | string>;
	onClick: () => void;
	isAtLeastIdentified: boolean;
	expanded: boolean;
};

export const DropDownButton = forwardRef<HTMLButtonElement, Props>(function DropDownButton(
	{ avatarUrl, isAtLeastIdentified, headerHeights, expanded, onClick }: Props,
	ref,
): JSX.Element | null {
	const { translate } = useI18n();

	return (
		<button
			type="button"
			sx={{
				...buttonResetStyles,
				cursor: 'pointer',
				height: headerHeights,
			}}
			data-testid="header-dropdown-button"
			aria-label={translate('fe.components.header.menu')}
			aria-haspopup="menu"
			aria-expanded={expanded}
			onClick={onClick}
			ref={ref}
		>
			<Flex sx={{ alignItems: 'center', height: '100%', gap: 8 }}>
				{isAtLeastIdentified && (
					<Avatar
						variant="subtle"
						size={24}
						src={avatarUrl}
						alt=""
						draggable={false}
						data-testid="header-user-avatar"
					/>
				)}

				<Icon icon={iconMenu} size="xl" sx={{ display: ['inline-block', null, 'none'] }} />
			</Flex>
		</button>
	);
});
