import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'account-settings-disable-account',
		path: '/account_settings/disable_account',
		component: loadable(
			async () => import(/* webpackChunkName: "pages/account_settings_disable_account" */ './index'),
			{
				exportName: 'DisableAccount',
				fallback: Loading,
			},
		),
		restrictedAccess: {
			authOnly: true,
		},
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
