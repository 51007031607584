import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'psf-membership-ask',
		path: '/p/:slug/psf/membership-ask',
		component: loadable(async () => import(/* webpackChunkName: "pages/psf_membership_ask" */ './index'), {
			exportName: 'MembershipAsk',
			fallback: Loading,
		}),
		frame: false,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
			// FIXME: temporary solution to force external navigation (resulting in session update)
			// when navigating to and from this page, to handle sign resulting in an identified state in non-GDPR countries
			isolated: true,
		},
	},
];
