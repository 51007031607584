import type { JSX } from 'react';

import { Icon } from '@change/design-system/components/icon';
import { iconRaisedFist } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { useSocialProofLabel } from 'src/app/shared/hooks/socialProof';

export function SocialProof(): JSX.Element {
	const {
		data: {
			petition: {
				dailySignatureCount,
				dailySignatureCountLocalized,
				weeklySignatureCount,
				weeklySignatureCountLocalized,
			},
			createdLessThanOneDayAgo,
		},
	} = usePetitionDetailsPageData();
	const textChildren = useSocialProofLabel({
		dailySignatureCount,
		dailySignatureCountLocalized,
		weeklySignatureCount,
		weeklySignatureCountLocalized,
		createdLessThanOneDayAgo,
	});

	return (
		<Flex
			backgroundColor="primary-greyBackground"
			padding={8}
			sx={{
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 8,
				borderRadius: 18,
			}}
		>
			<Icon icon={iconRaisedFist} />
			<Text size="small">{textChildren}</Text>
		</Flex>
	);
}
