import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'psf-promote-async',
		path: '/p/:slug/promote-async',
		component: loadable(async () => import(/* webpackChunkName: "pages/psf_promoteAsync" */ './index'), {
			exportName: 'PromoteAsync',
			fallback: Loading,
		}),
		frame: true,
	},
];
