/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { useCurrentUserIdAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import type { ReportContext } from '../../shared/types';

export function useReportAbuseTracking(reportContext: ReportContext): (context: { success: boolean }) => void {
	const userIdState = useCurrentUserIdAsync();
	const userId = isLoaded(userIdState) ? userIdState.value : undefined;
	const track = useTracking();

	return useCallback(
		({ success }) => {
			switch (reportContext.type) {
				case 'petition':
					void track<{
						petition_id: string;
						user_id: string | undefined;
						successful: boolean;
					}>('petition_report_abuse', {
						petition_id: reportContext.petitionId,
						user_id: userId,
						successful: success,
					});
					return undefined;
				case 'comment':
					void track<{
						comment_id: string;
						likes: number;
						petition_id: string;
						report_abuse_location: string | undefined;
						role: string;
						user_id: string | undefined;
						successful: boolean;
					}>('comment_report_abuse', {
						comment_id: reportContext.comment.id,
						likes: reportContext.comment.likes,
						role: reportContext.comment.role.toLowerCase(),
						petition_id: reportContext.petitionId,
						report_abuse_location: reportContext.location,
						user_id: userId,
						successful: success,
					});
					return undefined;
				case 'video_comment':
					void track<{
						comment_id: string;
						likes: number;
						petition_id: string;
						report_abuse_location: string | undefined;
						role: string;
						user_id: string | undefined;
						successful: boolean;
					}>('video_comment_report_abuse', {
						comment_id: reportContext.comment.id,
						likes: reportContext.comment.likes,
						role: reportContext.comment.role.toLowerCase(),
						petition_id: reportContext.petitionId,
						report_abuse_location: reportContext.location,
						user_id: userId,
						successful: success,
					});
					return undefined;
				default:
					return undefined;
			}
		},
		[track, reportContext, userId],
	);
}
