import type { FeatureConfigInfo } from '@change/core/fcm';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

import type { ExperimentName, FcmExperimentConfig, GetterContext, Result } from './types';
import { getExperimentName } from './types';

export function createFcmExperimentGetter<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>>({
	fcmConfigs,
	isEnabled,
	experimentName,
}: FcmExperimentConfig<T, EN>): (context: GetterContext) => Promise<Result<T, EN>> {
	const { getFcm } = createFcmFunctions(fcmConfigs, { getter: true });

	return async (context: GetterContext): Promise<Result<T, EN>> => {
		const fcm = await getFcm(context.utilityContext);

		const {
			l10n: { locale, countryCode },
		} = context;

		const callbackContext = { fcm, locale, countryCode };

		const getVariation = async (name: string) => {
			const experiment = context.utilityContext.experiments.get(name);
			await experiment.treat();
			return experiment.variation;
		};

		const fcmEnabled = isEnabled(callbackContext);

		const name = getExperimentName(callbackContext, experimentName);

		return {
			fcm,
			name,
			variation: fcmEnabled && name ? await getVariation(name) : 'control',
		};
	};
}
