import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'unsubscribe-email',
		path: '/unsubscribe',
		component: loadable(async () => import(/* webpackChunkName: "pages/unsubscribe_email" */ './index'), {
			exportName: 'UnsubscribeEmail',
			fallback: Loading,
		}),
		// no restricted access as this is done using a dedicated token
		frame: true,
		ssr: {
			enabled: true,
			// this is a mutation page so we want to avoid navigating to it using react-router
			isolated: true,
			seo: false,
		},
	},
];
