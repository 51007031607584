import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		specificPetitions: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'string' },
					dm_status: {
						type: 'array',
						shape: {
							type: 'object',
							shape: {
								id: { type: 'string' },
								petition_update: { type: 'number' },
							},
						},
					},
				},
			},
		},
	},
});

export const FCM_DM_STATUS = createFcmConfig('dm_status', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		specificPetitions: [],
	},
});
