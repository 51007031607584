import { normalizeBoolean } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_CAMPAIGN_STARTER_DASHBOARD_EMAIL_DECISION_MAKER = createFcmConfig(
	'campaign_starter_dashboard_email_decision_maker',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
