import { getWindow } from '@change/core/window';

import type { WebappInfo } from 'src/webappInfo';
import webappInfo from 'src/webappInfo';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		webappInfo: WebappInfo;
		version: string;
	}
}

export function decorateWindowWithInfo(): void {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const win = getWindow();
	/* eslint-disable @typescript-eslint/no-unsafe-member-access */
	win.webappInfo = webappInfo;
	win.version = `${webappInfo.name}-${webappInfo.version}`;
	/* eslint-enable @typescript-eslint/no-unsafe-member-access */
}
