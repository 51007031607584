import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_CAROUSEL_MEMBERSHIP_CONFIGURATION = createFcmConfig('carousel_membership_configuration', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				amount_base_units: {
					type: 'number',
				},
				contribute_url: {
					type: 'string',
					optional: true,
				},
			},
		}),
	),
	defaultValue: undefined,
});
