import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	PromoteLoginModalSendResetPasswordLinkMutation,
	PromoteLoginModalSendResetPasswordLinkMutationVariables,
} from './sendResetPasswordLink.graphql';

export async function sendResetPasswordLink(
	input: PromoteLoginModalSendResetPasswordLinkMutationVariables,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data, errors } = await fetch<
		PromoteLoginModalSendResetPasswordLinkMutation,
		PromoteLoginModalSendResetPasswordLinkMutationVariables
	>({
		query: gql`
			mutation PromoteLoginModalSendResetPasswordLink($email: String!) {
				sendResetPasswordLink(input: { email: $email }) {
					success
				}
			}
		`,
		variables: input,
	});

	if (errors) throw new Error(JSON.stringify(errors));

	return !!data?.sendResetPasswordLink?.success;
}
