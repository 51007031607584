import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { canEditPetition, Loading, petitionSlugCanonicalRedirect } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'starter-dashboard',
		path: '/p/:slug/dashboard',
		component: loadable(async () => import(/* webpackChunkName: "pages/starter_dashboard" */ './index'), {
			exportName: 'StarterDashboard',
			fallback: Loading,
		}),
		beforeServerRender: async (context) => {
			await petitionSlugCanonicalRedirect(context);
		},
		frame: true,
		restrictedAccess: {
			nonGuestOnly: true,
			role: ['staff', 'support', canEditPetition('slug')],
		},
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
