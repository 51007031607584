import type { PrefetchContext } from '@change/core/react/prefetch';

import { useMappedLoadedState, useMergedStates } from 'src/app/shared/hooks/state';

import { createFcmExperimentGetter } from './createFcmExperimentGetter';
import { createFcmExperimentHook } from './createFcmExperimentHook';
import { createFcmExperimentPrefetch } from './createFcmExperimentPrefetch';
import type { FcmExperimentConfig, GetterContext, Result } from './types';

type Functions<H extends boolean, P extends boolean, G extends boolean> = {
	/**
	 * Requests a React hook.
	 */
	hook?: H;
	/**
	 * Requests a prefetch function.
	 *
	 * Usually called from within a `prefetchData` function.
	 *
	 * This will store FCM values in the app cache so that it can be restored on hydration.
	 *
	 * Warning: The context passed to that function requires access to experiments, meaning that user data must be available.
	 */
	prefetch?: P;
	/**
	 * Requests a prefetch function.
	 *
	 * Usually called from within a `prefetchUserData` function.
	 *
	 * This will NOT store FCM values in the app cache.
	 */
	getter?: G;
};

type HookOptions<HP extends string = 'experiment'> = {
	/**
	 * Makes the React hook SSR-compatible for when user data is not available
	 *
	 * => will result into waiting for 2nd render to return a loaded state
	 */
	async?: boolean;
	/**
	 * The key of the property that will hold the values in the hook's return value
	 *
	 * Defaults to "experiments"
	 */
	valueProperty?: HP;
};

export type FcmExperimentsFunctionsResult<T extends Record<string, FcmExperimentConfig>> = {
	[K in keyof T]: Result<T[K]['fcmConfigs'], T[K]['experimentName']>;
};

export type FcmExperimentsFunctionsResultState<
	T extends Record<string, FcmExperimentConfig>,
	PROP extends string = 'experiments',
> = ({ status: 'loaded' } & { [key in PROP]: FcmExperimentsFunctionsResult<T> }) | { status: 'loading' };

export type FcmExperimentsFunctionsReturnValue<
	T extends Record<string, FcmExperimentConfig>,
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'experiments',
> = (H extends true ? { useFcmExperiments: () => FcmExperimentsFunctionsResultState<T, HP> } : EmptyIntersection) &
	(P extends true
		? { prefetchFcmExperiments: (context: PrefetchContext) => Promise<FcmExperimentsFunctionsResult<T>> }
		: EmptyIntersection) &
	(G extends true
		? {
				getFcmExperiments: (context: GetterContext) => Promise<FcmExperimentsFunctionsResult<T>>;
			}
		: EmptyIntersection);

export type FcmExperimentsFunctionsOptions<
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'experiments',
> = HookOptions<HP> & Functions<H, P, G>;

export function createFcmExperimentsFunctions<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Record<string, FcmExperimentConfig<any, any>>,
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'experiments',
>(
	configs: T,
	additionalOptions: FcmExperimentsFunctionsOptions<H, P, G, HP>,
): FcmExperimentsFunctionsReturnValue<T, H, P, G, HP> {
	/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
	const res: any = {};
	const { hook, prefetch, getter, ...rest } = additionalOptions;
	if (hook) {
		const hooks = Object.entries(configs).map(([valueProperty, options]) =>
			createFcmExperimentHook(options, { ...rest, valueProperty }),
		);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const mapper = (value: any) => ({ [rest.valueProperty || 'experiments']: value });
		res.useFcmExperiments = function useFcmExperiments() {
			// unfortunate casting necessary here
			return useMappedLoadedState(useMergedStates(...(hooks.map((singleExpHook) => singleExpHook()) as [any])), mapper);
		};
	}
	if (prefetch) {
		const functions = Object.entries(configs).map(([valueProperty, options]) => {
			const fn = createFcmExperimentPrefetch(options);
			return async (context: PrefetchContext) => {
				const value = await fn(context);
				return { [valueProperty]: value };
			};
		});
		res.prefetchFcmExperiments = async function prefetchFcmExperiments(context: PrefetchContext) {
			const values = await Promise.all(functions.map(async (fn) => fn(context)));
			return values.reduce((acc, value) => ({ ...acc, ...value }), {});
		};
	}
	if (getter) {
		const functions = Object.entries(configs).map(([valueProperty, options]) => {
			const fn = createFcmExperimentGetter(options);
			return async (context: GetterContext) => {
				const value = await fn(context);
				return { [valueProperty]: value };
			};
		});
		res.getFcmExperiments = async function prefetchFcmExperiments(context: GetterContext) {
			const values = await Promise.all(functions.map(async (fn) => fn(context)));
			return values.reduce((acc, value) => ({ ...acc, ...value }), {});
		};
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return res;
	/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
}
