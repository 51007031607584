import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-promoters',
		path: '/p/:slug/promoters',
		component: loadable(async () => import(/* webpackChunkName: "pages/petition_promoters" */ './index'), {
			exportName: 'PetitionPromoters',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
