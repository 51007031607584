import { useEffect, useMemo } from 'react';

import find from 'lodash/fp/find';
import isEqual from 'lodash/fp/isEqual';
import keyBy from 'lodash/fp/keyBy';
import keys from 'lodash/fp/keys';
import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import some from 'lodash/fp/some';
import { useCustomCompareMemo } from 'use-custom-compare';

import type { FeatureConfigData, FeatureConfigInfo } from '@change/core/fcm';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { FcmCacheState } from './context';
import { useFcmState } from './context';
import { reportMultipleConfigs } from './shared/reportMultipleConfigs';
import type { HookOptions, MultiFcmState } from './shared/types';
import { updateConfigsMap } from './shared/updateConfigsMap';

export function isCombinedStatesEqual(state1: FcmCacheState['cache'], state2: FcmCacheState['cache']): boolean {
	if (!isEqual(keys(state1), keys(state2))) {
		return false;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return keys(state1).every((key) => state1[key as never] === state2[key as never]);
}

// eslint-disable-next-line max-lines-per-function
export function createFcmHook<T extends Record<string, FeatureConfigInfo>, KEY extends string = 'values'>(
	configs: T,
	{ valueProperty }: HookOptions<KEY> = {},
): () => MultiFcmState<T, KEY> {
	const multipleConfigs = updateConfigsMap(configs);

	const names = keys(keyBy((config) => config.name, configs));

	const getFromCache = (cache: FcmCacheState['cache']) => {
		return names.reduce<FcmCacheState['cache']>((acc, name) => {
			// eslint-disable-next-line no-param-reassign
			acc[name] = cache[name];
			return acc;
		}, {});
	};

	return (): MultiFcmState<T, KEY> => {
		const [state, { addToQueue }] = useFcmState();

		const utilityContext = useUtilityContext();

		useEffect(() => {
			reportMultipleConfigs(multipleConfigs, utilityContext);
		}, [utilityContext]);

		const fcmStatesSel = getFromCache(state.cache);

		const fcmStates = useCustomCompareMemo(
			() => fcmStatesSel,
			[fcmStatesSel],
			([c1], [c2]) => isCombinedStatesEqual(c1, c2),
		);

		// fcmStates = { fcm_labels: { status: 'loaded' }, fcm_enabled: undefined }

		const undefinedFcmStates = useMemo(() => pickBy((fcmState) => !fcmState, fcmStates), [fcmStates]);

		// undefinedFcmStates = { fcm_enabled: undefined }

		const fcmToLoad = useMemo(
			() =>
				keys(undefinedFcmStates).reduce<FeatureConfigInfo[]>((acc, name) => {
					const config = find((c) => c.name === name, configs);
					if (config) acc.push(config);
					return acc;
				}, []),
			[undefinedFcmStates],
		);

		// fcmToLoad = [{ name: 'fcm_enabled', ... }]

		useEffect(() => {
			if (!Object.keys(fcmToLoad).length) return;
			addToQueue(fcmToLoad);
		}, [addToQueue, fcmToLoad]);

		// so the loading object is always the same for a specific config
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const loadingState: MultiFcmState<T, KEY> = useMemo(() => ({ status: 'loading' as const }), [configs]);

		return useMemo(() => {
			const anyLoading = !!some((fcmState) => !fcmState || fcmState.status === 'loading', fcmStates);
			if (anyLoading) {
				return loadingState;
			}

			/* eslint-disable @typescript-eslint/consistent-type-assertions */
			return {
				status: 'loaded' as const,
				[valueProperty || 'values']: mapValues(({ name }) => {
					if (!fcmStates[name]) {
						return undefined;
					}
					return (fcmStates[name] as { status: 'loaded'; value: T }).value;
				}, configs) as FeatureConfigData<T>,
			} as MultiFcmState<T, KEY>;
			/* eslint-enable @typescript-eslint/consistent-type-assertions */
		}, [loadingState, fcmStates]);
	};
}
