/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useCallback, useState } from 'react';

import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';
import { createMandatoryContext } from '@change/core/react/context';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { HttpError, RedirectError } from 'src/shared/error';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import type { ContextData, Map, Options, Result, RethrowableErrorState } from './types';
import { useCheckHookDeps } from './useCheckHookDeps';

function createContext<
	DATA extends Record<string, unknown>,
	DATA_PROPERTY extends string = 'data',
	OLD_VERSION extends boolean = false,
>(name: string, dataProperty: DATA_PROPERTY) {
	return createMandatoryContext<
		ContextData<DATA, OLD_VERSION>,
		{
			[key in DATA_PROPERTY]: ContextData<DATA, OLD_VERSION>;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	>(undefined, {
		name,
		processProviderProps: (props: {
			[key in DATA_PROPERTY]: ContextData<DATA, OLD_VERSION>;
		}): ContextData<DATA, OLD_VERSION> => props[dataProperty],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any);
}

// eslint-disable-next-line max-lines-per-function
export function createAsyncDataContext<
	DATA extends Record<string, unknown>,
	PARAMS extends unknown[] = [],
	DATA_PROPERTY extends string = 'data',
	OLD_VERSION extends boolean = false,
	ERROR extends Map = EmptyIntersection,
>({
	name,
	getData,
	dataProperty: dataPropertyP,
	errorHandler: errorHandlerP,
	hookDeps,
	loadingBetweenQueries,
	oldVersion,
}: Options<DATA, PARAMS, DATA_PROPERTY, OLD_VERSION, ERROR>): Result<DATA, PARAMS, DATA_PROPERTY, OLD_VERSION, ERROR> {
	const dataProperty = dataPropertyP || ('data' as DATA_PROPERTY);
	const { Context, Provider, useContext } = createContext<DATA, DATA_PROPERTY, OLD_VERSION>(name, dataProperty);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const errorHandler: (e: any) => ERROR = (error: any) => {
		// not catching HTTP errors that should instead be handled by the server, or the ErrorBoundary
		if (error instanceof HttpError || error instanceof RedirectError) {
			throw error;
		}
		return errorHandlerP ? errorHandlerP(error) : ({} as ERROR);
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const hookErrorHandler: (e: any) => ERROR = (error: any) => {
		try {
			return errorHandler(error);
		} catch (err) {
			// using a "hidden" field in the returned state, because the error is thrown within a useEffect
			// which would not fall into a ErrorBoundary
			// eslint-disable-next-line @typescript-eslint/naming-convention
			return { __errorToRethrow: err } satisfies RethrowableErrorState as unknown as ERROR;
		}
	};

	const res = {
		Context,
		Provider,
		useContext,
		useAsyncData: (...params: PARAMS) => {
			const utilityContext = useUtilityContext();
			const [refreshCount, setRefreshCount] = useState(0);

			const deps = hookDeps ? hookDeps(...params) : params;

			useCheckHookDeps(deps, utilityContext);

			const refreshData = useCallback(() => setRefreshCount((count) => count + 1), []);

			const dataState = useQuery(
				async () => ({
					[dataProperty]: await getData(utilityContext, ...params),
				}),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[...deps, refreshCount, utilityContext],
				{
					errorHandler: hookErrorHandler,
					loadingBetweenQueries,
				},
			);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const hookRes = useMappedLoadedState(dataState, (data: any) => ({
				[dataProperty]: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
					data: data[dataProperty],
					actions: {
						refreshData,
					},
				},
			})) as QueryHookResult<
				{
					[key in DATA_PROPERTY]: ContextData<DATA, false>;
				},
				ERROR
			>;

			if (hookRes.status === 'error' && (hookRes as unknown as RethrowableErrorState).__errorToRethrow) {
				throw (hookRes as unknown as RethrowableErrorState).__errorToRethrow;
			}

			return hookRes;
		},
	};

	if (!oldVersion) return res;

	return {
		...res,
		useAsyncData: (...params: PARAMS) => {
			const dataState = res.useAsyncData(...params);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return useMappedLoadedState(dataState, (data: any) => ({
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				[dataProperty]: data[dataProperty].data,
			})) as QueryHookResult<
				{
					[key in DATA_PROPERTY]: ContextData<DATA, false>;
				},
				ERROR
			>;
		},
	};
}
