import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { HttpClient } from '@change/core/http';
import { createHttpClient as _createHttpClient } from '@change/core/http';

type Options = Readonly<{
	errorReporter: ErrorReporter;
}>;

export function createHttpClient({ errorReporter }: Options): HttpClient {
	return _createHttpClient({
		reportNetworkError: errorReporter.createSampledReporter(0.01),
	});
}
