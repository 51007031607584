import type { Session } from '@change/core/session';

import { setupIntl as _setupIntl } from 'src/app/intl';
import { markTimelineEnd, markTimelineStart } from 'src/app/shared/performance';

type Options = Readonly<{
	session: Session;
}>;

export async function setupIntl({ session }: Options): Promise<void> {
	const {
		locale: { localeCode: locale },
	} = session;

	markTimelineStart('intl');
	const res = _setupIntl({ locale });
	markTimelineEnd('intl');
	return res;
}
