import type { ComponentProps, JSX } from 'react';
import { useCallback } from 'react';

import type { TranslationKey } from '@change/core/i18n';
import { translationKey } from '@change/core/i18n';
import { Translate } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { iconNavigateBefore } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { useStepState } from 'src/app/pages/startAPetition/pageContext';
import { useFocusFallbackManualTrigger } from 'src/app/shared/hooks/focus';

type SecondaryActionButtonProps = ComponentProps<typeof Button> & { label: TranslationKey };
export function SecondaryActionButton({
	label,
	loading,
	onClick,
	mode,
	...rest
}: SecondaryActionButtonProps): JSX.Element {
	const triggerFocusFallback = useFocusFallbackManualTrigger();
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		onClick?.(e);
		triggerFocusFallback(e);
	};

	return (
		<Button
			type="button"
			variant="secondary"
			onClick={handleClick}
			data-testid="secondary-action"
			icon={iconNavigateBefore}
			loading={loading}
			loadingLabel="loading"
			mode={mode || ['icon', 'label']}
			{...rest}
		>
			<Translate value={label} />
		</Button>
	);
}

type PrimaryActionButtonProps = ComponentProps<typeof Button> & { label: TranslationKey };
export function PrimaryActionButton({
	disabled,
	label,
	loading,
	onClick,
	...rest
}: PrimaryActionButtonProps): JSX.Element {
	const triggerFocusFallback = useFocusFallbackManualTrigger();
	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			onClick?.(e);
			triggerFocusFallback(e);
		},
		[onClick, triggerFocusFallback],
	);

	return (
		<Button
			type="button"
			onClick={handleClick}
			disabled={disabled}
			data-testid="primary-action"
			loading={loading}
			loadingLabel="loading"
			{...rest}
		>
			<Text>
				<Translate value={label} />
			</Text>
		</Button>
	);
}

type ActionBarProps = ComponentProps<typeof Box>;
export function ActionBar({ children, ...rest }: ActionBarProps): JSX.Element {
	return (
		<Box
			sx={{
				backgroundColor: ['primary-white', 'transparent'],
				borderTopWidth: [1, 0],
				borderTopColor: 'neutral-grey100',
				borderTopStyle: 'solid',
				p: [16, 0],
				mt: [0, 32],
				position: ['fixed', 'static'],
				left: [0, 'auto'],
				right: [0, 'auto'],
				bottom: [0, 'auto'],
				zIndex: 1,
			}}
			{...rest}
		>
			<Box sx={{ maxWidth: ['100%', 740, 960], width: '100%', margin: '0 auto' }}>{children}</Box>
		</Box>
	);
}

type OnClick = () => Promise<void> | void;
type StepActionsProps = ComponentProps<typeof Box> & {
	disabled?: boolean;
	loading?: boolean;
	onSecondaryAction?: OnClick;
	onPrimaryAction: OnClick;
};
/**
 * Renders a set of buttons with two layouts:
 * * `large screens` - inline within existing layout flow
 * * `small screens` - fixed bottom container
 */
export function StepActions({ disabled, loading, onSecondaryAction, onPrimaryAction }: StepActionsProps): JSX.Element {
	const { data } = useStepState();
	const showSecondaryActionButton = data.metadata.currentStepIndex !== 0;

	return (
		<ActionBar>
			<Flex sx={{ justifyContent: ['flex-start', 'flex-end'] }}>
				{showSecondaryActionButton ? (
					<Box sx={{ ml: [0, 'auto'] }}>
						<SecondaryActionButton
							onClick={onSecondaryAction}
							loading={loading}
							label={translationKey('corgi.sap.button.back')}
						/>
					</Box>
				) : null}
				<Box sx={{ ml: ['auto', 16] }}>
					<PrimaryActionButton
						onClick={onPrimaryAction}
						disabled={disabled}
						loading={loading}
						label={translationKey('corgi.sap.button.forward')}
					/>
				</Box>
			</Flex>
		</ActionBar>
	);
}
