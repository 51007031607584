import type { UtilityContext } from '@change/core/react/utilityContext';

import { getShortUrl } from './getShortUrl';

export async function getShortUrls(
	urls: readonly string[],
	utilityContext: UtilityContext,
): Promise<Readonly<Record<string, string>>> {
	const shortUrls = await Promise.all(urls.map(async (url) => getShortUrl(url, utilityContext)));
	return urls.reduce<Record<string, string>>((map, url, index) => {
		// eslint-disable-next-line no-param-reassign
		map[url] = shortUrls[index];
		return map;
	}, {});
}
