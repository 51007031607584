import { useCallback, useEffect, useState } from 'react';

import type { TranslationKey } from '@change/core/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { MediaInput } from 'src/app/pages/startAPetition/pageContext';
import { useStepState } from 'src/app/pages/startAPetition/pageContext';
import type { RawMediaCropInput, UploaderMethodProps } from 'src/app/shared/components/imageUpload';
import { UPLOAD_ERROR_MESSAGES, useUploader } from 'src/app/shared/hooks/uppyUploader';

import { getImageFileType, isCropEqual, validateImageSize } from './utils';

type UseImageUploaderArgs = {
	tracking: { onFileAdded: (data: { uploadMethod: string; fileType: string }) => void };
};

type UseImageUploaderReturn = ModelHookResult<
	{
		uploader: UploaderMethodProps;
		imageData: MediaInput | undefined | null;
		invalidImageError: TranslationKey | null;
	},
	{
		handleImageChange: (image?: string) => void;
		handleImageCrop: (croppingData?: RawMediaCropInput) => void;
		handleRemoveImage: () => void;
	}
>;

// TODO: we could make this a bit more reusable but we should also decide the uppy/Uploader component api behavior
// e.g. it should behave as a controlled/uncontrolled api, so maybe in the future
// this should accept `onImageChange` and `onCropChange` as arguments to handle side effect (e.g. setting petition data and tracking)
// with some different access to the image and cropping data so we're not managing it ourselves all the time
// currently just passing in tracking but having `on[Action]` handlers would remove the need for this
// eslint-disable-next-line max-lines-per-function
export function useImageUploader({ tracking }: UseImageUploaderArgs): UseImageUploaderReturn {
	const utilityContext = useUtilityContext();
	const { data, actions } = useStepState();
	const [imageData, setImageData] = useState(data.petition?.media);
	const [invalidImageError, setInvalidImageError] = useState<TranslationKey | null>(null);
	const uploader = useUploader(setInvalidImageError);

	const handleImageChange = useCallback((image: string | undefined) => {
		if (!image) return;

		const currentImageData = { data: image, fileType: getImageFileType(image), status: 'added' as const };
		setImageData((prevData) => ({ ...prevData, ...currentImageData }));
	}, []);

	const handleImageCrop = useCallback(
		(croppingData?: RawMediaCropInput) => {
			if (!croppingData) return;
			// return early if we've already saved the image and the cropping data does not change
			// this is needed because `onCrop` will fire when mounting with existing data
			// we return early to prevent the status from changing from `saved` -> `cropped` when this happens
			if (imageData?.status === 'saved' && isCropEqual(imageData.croppingData, croppingData)) return;

			const imageDimensions = { width: croppingData.w, height: croppingData.h };
			const currentImageData = { croppingData, dimensions: imageDimensions, status: 'cropped' as const };
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			setImageData((prevData) => ({ data: prevData!.data, fileType: prevData!.fileType, ...currentImageData }));
			actions.updatePetition({ media: currentImageData });

			// this checks the image size to suggest using a larger image if the user uploads a small image
			// its not a restriction like the errors handled in `uppy`
			const isValidImageSize = validateImageSize(croppingData);
			if (!isValidImageSize) setInvalidImageError(UPLOAD_ERROR_MESSAGES.IMAGE_TOO_SMALL);
		},
		[actions, imageData?.croppingData, imageData?.status],
	);

	const handleRemoveImage = useCallback(() => {
		setInvalidImageError(null);
		setImageData(null);
		actions.updatePetition({ media: null });
	}, [actions]);

	// setting up handlers (not sure why the `ImageUploader` component can't do this)
	useEffect(() => {
		// FIXME: should it be using the `fileType` instead of `image/jpeg`??
		if (!!imageData?.data && !uploader.getFile()) {
			uploader.addFile('image/jpeg', imageData.data);
		}

		// other initialization tasks
		uploader.onFileAdded(handleImageChange);
		uploader.onFileAddedTracking(tracking.onFileAdded);
		uploader.onUseDropTarget();
		uploader.onUseAwsS3(utilityContext, 'USER_UPLOADS');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data: { imageData, invalidImageError, uploader },
		actions: { handleImageChange, handleImageCrop, handleRemoveImage },
	};
}
