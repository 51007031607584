import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-staff-roles',
		path: '/dashboard/staff/roles',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_staff_roles" */ './index'), {
			exportName: 'AdminStaffRoles',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: 'staffAdmin',
		},
		frame: {
			header: true,
		},
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
