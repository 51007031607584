import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { GqlClient } from '@change/core/gql';
import type { Session } from '@change/core/session';
import { getSession as _getSession } from '@change/core/session';

import type { HydrationData } from 'src/app/types';

import { createFallbackSession } from './fallback';
import { createSessionGqlPath } from './gqlPath';

type Options = Readonly<{
	gql: GqlClient;
	errorReporter: ErrorReporter;
	hydrationData: HydrationData | undefined;
}>;

type Result = Readonly<{
	session: Session;
	sessionError?: true;
}>;

export async function getSession({ gql, errorReporter, hydrationData }: Options): Promise<Result> {
	if (hydrationData?.session) {
		return { session: hydrationData.session };
	}

	try {
		const session = await _getSession({
			gqlFetch: async ({ rejectOnError, ...options }) =>
				rejectOnError
					? gql.fetch({ ...options, path: createSessionGqlPath({ hydrationData }), rejectOnError })
					: gql.fetch({ ...options, path: createSessionGqlPath({ hydrationData }) }),
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			reportError: errorReporter.report.bind(errorReporter),
		});

		return { session };
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return { session: createFallbackSession({ hydrationData }), sessionError: true };
	}
}
