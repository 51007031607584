import { useEffect } from 'react';

import { getDocument } from '@change/core/window';

import { listenToElementRemoval } from '../shared/listenToElementRemoval';

import { useFocusFallbackTrigger } from './useFocusFallbackTrigger';

export function useFocusFallbackListener(): void {
	const focusFallback = useFocusFallbackTrigger();

	useEffect(() => {
		if (!focusFallback) {
			return undefined;
		}

		let cleanListener: (() => void) | undefined;

		const { activeElement } = getDocument() || {};

		if (activeElement && !cleanListener) {
			// if there is already a focused element when hydrating, let's listen to it
			cleanListener = listenToElementRemoval(activeElement, focusFallback);
		}

		const listener = (event: FocusEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const focusElt = event.target as Node | null;

			if (!focusElt) return;

			// stop observing previous focus element
			cleanListener?.();

			cleanListener = listenToElementRemoval(focusElt, focusFallback);
		};

		getDocument() && getDocument().addEventListener('focusin', listener);

		return () => {
			cleanListener?.();

			getDocument() && getDocument().removeEventListener('focusin', listener);
		};
	}, [focusFallback]);
}
