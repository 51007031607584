import { translationKey, type TranslationKey } from '@change/core/i18n';

const BadgesTypes = {
	GREAT: {
		labelKey: translationKey('corgi.components.badge_score.variants.great'),
		backgroundColor: '#C0F5D4',
		color: '#147639',
	},
	GOOD: {
		labelKey: translationKey('corgi.components.badge_score.variants.good'),
		backgroundColor: '#DDF4C2',
		color: '#4B7718',
	},
	ROOM_TO_IMPROVE: {
		labelKey: translationKey('corgi.components.badge_score.variants.room_to_improve'),
		backgroundColor: '#FDECB1',
		color: '#80660A',
	},
	BELOW_AVERAGE: {
		labelKey: translationKey('corgi.components.badge_score.variants.below_average'),
		backgroundColor: '#FAE4CB',
		color: '#9C5705',
	},
};

type UseBadgeScoreReturn = ModelHookResult<{
	labelKey: TranslationKey;
	backgroundColor: string;
	color: string;
}>;

export const useBadgeScore = (score: number): UseBadgeScoreReturn => {
	if (score >= 10) {
		return {
			data: {
				...BadgesTypes.GREAT,
			},
		};
	}
	if (score >= 7) {
		return {
			data: {
				...BadgesTypes.GOOD,
			},
		};
	}
	if (score >= 4) {
		return {
			data: {
				...BadgesTypes.ROOM_TO_IMPROVE,
			},
		};
	}
	return {
		data: {
			...BadgesTypes.BELOW_AVERAGE,
		},
	};
};
