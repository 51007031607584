import { useMemo } from 'react';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

export function useSessionData(): { name?: string; totalSignatureCount: number } {
	const session = useSessionAsync();
	return useMemo(() => {
		if (!isLoaded(session)) {
			return { totalSignatureCount: 0, name: undefined };
		}
		return {
			totalSignatureCount: session.value.user?.totalSignatureCount ?? 0,
			name: session.value.user?.firstName,
		};
	}, [session]);
}
