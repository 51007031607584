import type { UtilityContext } from '@change/core/react/utilityContext';

import { isAndroidPhone, isIPhoneOrIPad } from 'src/app/shared/utils/userAgent';

export function buildUrl(text: string, utilityContext: UtilityContext): string {
	if (isIPhoneOrIPad(utilityContext)) {
		return `sms://&body=${encodeURIComponent(text)}`;
	}
	if (isAndroidPhone(utilityContext)) {
		return `sms:?body=${encodeURIComponent(text)}`;
	}
	throw new Error('Device does not support SMS');
}
