import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';
import type { UtilityContext } from '@change/core/react/utilityContext';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { getPetitionStats } from './api/stats';
import type { PetitionStats } from './api/stats';

export type PetitionStatsErrorReason = 'notFound' | 'other';
export type PetitionStatsState = QueryHookResult<Readonly<{ stats: PetitionStats }>>;
export type { PetitionStats };

async function getPetitionStatsWithNormalizedError(
	slug: string,
	utilityContext: UtilityContext,
): Promise<PetitionStats | undefined> {
	try {
		const stats = await getPetitionStats(slug, utilityContext);
		return stats;
	} catch (e) {
		throw new Error('other' as PetitionStatsErrorReason);
	}
}

async function getPetitionStatsOrThrow(slug: string, utilityContext: UtilityContext): Promise<PetitionStats> {
	const stats = await getPetitionStatsWithNormalizedError(slug, utilityContext);
	if (!stats) {
		throw new Error('notFound' as PetitionStatsErrorReason);
	}
	return stats;
}

export function useApiPetitionStats(slug: string): PetitionStatsState {
	const utilityContext = useUtilityContext();

	return useQuery(
		async () => ({ stats: await getPetitionStatsOrThrow(slug, utilityContext) }),
		[slug, utilityContext],
		{
			loadingBetweenQueries: true, // necessary for recent signers to properly update between pages
			errorHandler: (e: Error) => ({ reason: e.message as PetitionStatsErrorReason }),
		},
	);
}
