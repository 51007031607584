import type { UtilityContext } from '@change/core/react/utilityContext';

import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

export const {
	Context: MembershipAwarenessBannerDataContext,
	Provider: MembershipAwarenessBannerDataContextProvider,
	useContext: useMembershipAwarenessBannerData,
	useAsyncData: useMembershipAwarenessBannerDataFromApi,
	prefetchAsyncData: prefetchMembershipAwarenessBannerDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'MembershipAwarenessBannerDataContext',
	dataProperty: 'data',
	hasUserData: true,
	getData: async (_utilityContext: UtilityContext) => Promise.resolve({}),
});
