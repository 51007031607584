import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-promotion-settings-promoted-petition-dashboard',
		path: '/dashboard/promotion_settings/petitions/:slugOrId',
		component: loadable(
			async () =>
				import(/* webpackChunkName: "pages/admin_promotion_settings_promoted_petition_dashboard" */ './index'),
			{
				exportName: 'AdminPromotionSettingsPromotedPetitionDashboard',
				fallback: Loading,
			},
		),
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support'],
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
