import { googleSignInClientIds } from '@change/config/googleAuth';
import type { Environment, EnvironmentUtils } from '@change/core/environment';
import type { ErrorReporter } from '@change/core/errorReporter/common';
import type { GoogleAuth } from '@change/core/googleAuth';
import { createGoogleAuth as _createGoogleAuth } from '@change/core/googleAuth';
import type { UserAgentUtils } from '@change/core/userAgent';

export type Options = {
	errorReporter: ErrorReporter;
	environment: EnvironmentUtils;
	userAgent: UserAgentUtils;
};

function getGoogleSignInClientId(env: Environment): string {
	return googleSignInClientIds[env] ?? googleSignInClientIds.development;
}

export function createGoogleAuth({ errorReporter, environment, userAgent }: Options): GoogleAuth {
	const googleAuth = _createGoogleAuth({
		googleAuthClientId: getGoogleSignInClientId(environment.getEnvironment()),
		reportError: errorReporter.createSampledReporter(0.01),
		disabled: userAgent.isWebview,
	});
	void googleAuth.preload();

	return googleAuth;
}
