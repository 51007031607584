import type {
	ApiPetitionListSignature,
	ApiPetitionSignature,
	PetitionSignature,
	PetitionSignatureListItem,
} from './types';

export function normalizeSignature(apiSignature: ApiPetitionSignature): PetitionSignature {
	return {
		message: apiSignature.message,
		createdAt: apiSignature.created_at,
		petitionId: `${apiSignature.petition_id}`,
		signeeNumber: apiSignature.signee_number,
		userId: `${apiSignature.user_id}`,
	};
}

export function normalizeSignatureForList(apiSignature: ApiPetitionListSignature): PetitionSignatureListItem {
	return {
		...normalizeSignature(apiSignature),
		petition: apiSignature.petition,
	};
}
