import { useCallback } from 'react';

import { useI18n } from '@change/core/react/i18n';

import type { I18nFieldValidatorResult } from 'src/app/shared/form/validators';

type Result = Readonly<{
	getErrorMessage: (error: I18nFieldValidatorResult) => string | undefined;
}>;

export function useFormI18n(): Result {
	const { translate, translatePlural } = useI18n();

	const getErrorMessage = useCallback(
		(error: I18nFieldValidatorResult) => {
			if (!error) {
				return undefined;
			}
			if (error.plural) {
				return translatePlural(error.key, error.count, error.replacements);
			}
			return translate(error.key, error.replacements);
		},
		[translate, translatePlural],
	);

	return { getErrorMessage };
}
