import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'decision-makers-about',
		// path does not include 'about' (/decision-makers/about) but feature name will, so that it's more evident what this DM page is
		path: '/decision-makers',
		component: loadable(async () => import(/* webpackChunkName: "pages/decisionMakersAbout" */ './index'), {
			exportName: 'DecisionMakersAbout',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
