/* eslint-disable max-lines-per-function */
import { useLocation } from 'react-router';

import { ButtonLink } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { Image } from '@change/design-system/components/media';
import { iconClose } from '@change/design-system/icons';
import { Box, Container, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { useMembershipAwarenessCardMessage } from './hooks/useMembershipAwarenessCardMessage';

export function MembershipAwarenessCardMessage(): React.JSX.Element | null {
	const {
		data: { totalSignatureCount, name, display, slug, url },
		actions: { onClose, onSubmit },
	} = useMembershipAwarenessCardMessage();
	const { pathname: path } = useLocation();

	if (!display) return null;

	return (
		<>
			{path === `/p/${slug}` && (
				<Box
					sx={{
						backgroundColor: '#FAF7ED',
						padding: 24,
						borderWidth: 1,
						borderColor: 'neutral-grey100',
						borderStyle: 'solid',
						display: ['block', 'none'],
					}}
				>
					<Container variant="small">
						<Flex
							sx={{
								flexDirection: ['column', 'row'],
								width: '100%',
								alignItems: 'flex-start',
								gap: 16,
							}}
						>
							<Flex>
								<Box sx={{ display: ['block', 'none'] }}>
									<Image
										alt="support-change-image"
										src="https://change-assets.s3.amazonaws.com/membership/Heart+hands.png"
										sx={{ maxWidth: '75%' }}
									/>
								</Box>
								<Text size="large">
									<strong>
										{name ? `${name}, you have helped ` : 'You have helped '}
										<Text color="primary-changeRed" size="large">
											{totalSignatureCount > 1 ? totalSignatureCount : 'a'} petition{totalSignatureCount > 1 ? 's' : ''}
										</Text>{' '}
										gain momentum.
									</strong>{' '}
									Your impact is just getting started. Contribute today to support turning your signature into a
									victory.
								</Text>
								<Box sx={{ display: ['block'] }}>
									<Icon
										data-testid="close-icon"
										icon={iconClose}
										size="xl"
										sx={{ cursor: 'pointer' }}
										onClick={onClose}
									/>
								</Box>
							</Flex>
							<ButtonLink
								to={url}
								sx={{
									width: ['100%', 'fit-content'],
									whiteSpace: 'nowrap',
								}}
								onClick={onSubmit}
								data-testid="support-change-button"
							>
								<Text>Support Change</Text>
							</ButtonLink>
						</Flex>
					</Container>
				</Box>
			)}
			<Box
				sx={{
					backgroundColor: '#FAF7ED',
					borderRadius: 'standard',
					display: ['none', 'block'],
				}}
				my={16}
			>
				<Box px={16} py={16}>
					<Box sx={{ display: ['none', 'block'] }}>
						<Image
							alt="support-change-image"
							src="https://change-assets.s3.amazonaws.com/membership/Heart+hands.png"
							sx={{ maxWidth: '40%' }}
						/>
					</Box>
					<Box my={8}>
						<Text size="large">
							<strong>
								{name ? `${name}, you have helped ` : 'You have helped '}
								<Text color="primary-changeRed" size="large">
									{totalSignatureCount > 1 ? totalSignatureCount : 'a'} petition{totalSignatureCount > 1 ? 's' : ''}
								</Text>{' '}
								gain momentum.
							</strong>{' '}
							Your impact is just getting started. Contribute today to support turning your signature into a victory.
						</Text>
					</Box>
					<Box my={16}>
						<ButtonLink to={url} onClick={onSubmit} sx={{ width: '100%' }} data-testid="support-change-button">
							<Text>Support Change</Text>
						</ButtonLink>
					</Box>
				</Box>
			</Box>
		</>
	);
}
