import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		message: { type: 'number' },
		skip: { type: 'number' },
		initial: { type: 'number' },
	},
});

export const FCM_NAFTA_PSF_SHARE_COPY_BANDIT_TRAFFIC_CONFIG = createFcmConfig(
	'nafta_psf_share_copy_bandit_traffic_config',
	{
		normalizer: createJsonNormalizer(normalizer),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		defaultValue: {
			message: 0,
			skip: 0,
			initial: 0,
		} as ReturnType<typeof normalizer>,
	},
);
