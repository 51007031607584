import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		custom_promoter_count: { type: 'number' },
		custom_contribution_amount: { type: 'number' },
		custom_additional_signatures: { type: 'number' },
	},
});

export const FCM_COMBO_PROMOTER_CONTRIBUTION_IMPACT_VALUES = createFcmConfig(
	'combo_promoter_contribution_impact_values',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: undefined,
	},
);
