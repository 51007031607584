import type { EnvironmentUtils } from '@change/core/environment';
import { createEnvironmentUtils as _createEnvironmentUtils } from '@change/core/environment';
import { getLocation } from '@change/core/window';

import webappInfo from 'src/webappInfo';

export function createEnvironmentUtils(): EnvironmentUtils {
	const { hostname } = getLocation();

	return _createEnvironmentUtils({
		hostname,
		buildEnvironment: process.env.NODE_ENV,
		apiEnvironment: webappInfo.proxyTarget?.env,
		apiDemoEnvironment: webappInfo.proxyTarget?.demoEnv,
	});
}
