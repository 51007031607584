import type { ComponentProps } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Icon } from '@change/design-system/components/icon';
import { iconOutlinedFlag } from '@change/design-system/icons';
import { buttonResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import { ReportAbuseLink } from 'src/app/shared/components/abuse';

type Props = ComponentProps<typeof ReportAbuseLink>;

/**
 * Reusable report issue link component, opens the report abuse modal on click.
 *
 * This component inherits the `ReportAbuseLink` props.
 */
export function ReportAIIssueLink(props: Props): JSX.Element {
	return (
		<ReportAbuseLink {...props}>
			<button type="button" sx={{ ...buttonResetStyles, display: 'flex', alignItems: 'center', mb: 16 }}>
				<Icon icon={iconOutlinedFlag} size="m" color="typography-secondary" mr={5} />
				<Text sx={{ textDecoration: 'underline' }} color="typography-secondary" size="caption">
					<Translate value="fe.pages.decision_maker_show.ai_content.report_issue_label" />
				</Text>
			</button>
		</ReportAbuseLink>
	);
}
