import type { Normalizer } from '@change/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const idNormalizer: Normalizer<string> = (value) => {
	if (typeof value === 'string') return value;
	if (typeof value === 'number') return String(value);
	throw new Error('Invalid user id/uuid');
};

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		traffic_percentage: { type: 'number' },
		safe_listed_user_identifiers: {
			type: 'array',
			shape: { type: 'string', normalizer: idNormalizer },
			optional: true,
		},
		block_listed_user_identifiers: {
			type: 'array',
			shape: { type: 'string', normalizer: idNormalizer },
			optional: true,
		},
	},
});

export const FCM_PROMOTION_COUNTRY_GRADUAL_OPENING = createFcmConfig('promotion_country_gradual_opening', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
