import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		experimentName: { type: 'string' },
	},
});

export const FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT = {
	name: 'new_petition_page_access_experiment',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		experimentName: '',
	} as ReturnType<typeof normalizer>,
};
