import type { JSX } from 'react';

import { Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconStar } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';

import { Tooltip, TooltipContent, TooltipTrigger } from 'src/app/shared/components/tooltips';

import type { BadgeProps } from '../index';

import { useMembershipBadge } from './hooks';

type Props = Omit<BadgeProps, 'hasMembership' | 'membershipData' | 'userId'>;

export function MembershipBadge({ size, verticalAlign, sourceLocation }: Props): JSX.Element {
	const {
		data: { url, tooltip },
		actions: { onClick },
	} = useMembershipBadge({ sourceLocation });

	return (
		<Box sx={{ display: verticalAlign ? 'inline' : 'flex', verticalAlign: verticalAlign ?? 'unset', pl: '4px' }}>
			<Tooltip placement="top-start">
				<TooltipTrigger>
					<Link to={url} onClick={onClick} data-testid="badge-link" aria-label={tooltip}>
						<Flex sx={{ borderRadius: '50%', backgroundColor: 'primary-changeRed', padding: '2px' }}>
							<Icon icon={iconStar} size={size ?? 'm'} color="white" />
						</Flex>
					</Link>
				</TooltipTrigger>
				<TooltipContent>{tooltip}</TooltipContent>
			</Tooltip>
		</Box>
	);
}
