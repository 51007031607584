import { useEffect } from 'react';
import type { JSX } from 'react';

import { useLocation } from 'react-router';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { NotFoundContainer } from './NotFound';

export function InvalidRouteContainer(): JSX.Element | null {
	const {
		errorReporter: { report },
	} = useUtilityContext();

	const { pathname } = useLocation();

	useEffect(() => {
		void report({
			error: 'Invalid route',
		});
	}, [report, pathname]);

	return <NotFoundContainer />;
}
