import type { UtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import type {
	SapCreatePublishedPetitionMutation,
	SapCreatePublishedPetitionMutationVariables,
} from './createPublishedPetition.graphql';
import { SAPCreatePublishedPetition } from './createPublishedPetition.graphql';
import { ERROR_TYPES } from './errors';
import { transformPetitionInput } from './transformPetitionInput';

type CreatePublishedPetitionParams = { petition: PetitionInput };
type CreatePublishedPetitionReturn = Extract<
	SapCreatePublishedPetitionMutation['createPublishedPetition'],
	{ __typename: 'CreatePublishedPetitionSuccess' }
>['petition'];
export async function createPublishedPetition(
	{ petition }: CreatePublishedPetitionParams,
	{ gql: { fetch }, errorReporter }: UtilityContext,
): Promise<CreatePublishedPetitionReturn> {
	try {
		const petitionInput = transformPetitionInput({ petition });

		const { data, errors } = await fetch<
			SapCreatePublishedPetitionMutation,
			SapCreatePublishedPetitionMutationVariables
		>({
			query: SAPCreatePublishedPetition,
			variables: {
				input: petitionInput,
			},
		});

		if (errors) throw new Error(ERROR_TYPES.GraphqlError);

		if (!data) throw new Error(ERROR_TYPES.NoResponseData);

		if (data.createPublishedPetition.__typename !== 'CreatePublishedPetitionSuccess') {
			const typename = data.createPublishedPetition.__typename;
			throw new Error(ERROR_TYPES[typename]);
		}

		return data.createPublishedPetition.petition;
	} catch (err) {
		const error = err instanceof Error ? err : new Error('SAP: Failed to save petition', { cause: err });
		void errorReporter.report({ error });
		throw error;
	}
}
