import type { HtmlTransformer } from '@change/core/react/html';
import { createRichMediaTransformers } from '@change/core/react/richMediaTags';

import { useMediaAssetsBasePath } from 'src/app/shared/hooks/assets';

type UseRichMediaTransformers = {
	decodeRichMediaTransformer: HtmlTransformer;
	encodeRichMediaTransformer: HtmlTransformer;
};

export function useRichMediaTransformers(): UseRichMediaTransformers {
	const assetBasePath = useMediaAssetsBasePath();
	const { decodeRichMediaTransformer, encodeRichMediaTransformer } = createRichMediaTransformers({ assetBasePath });

	return { decodeRichMediaTransformer, encodeRichMediaTransformer };
}
