import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';

import { useAutocompleteService } from 'src/app/shared/hooks/googlePlaces';
import { useLocale } from 'src/app/shared/hooks/l10n';

import { getPlacePredictions } from '../api/getPlacePredictions';
import type { GeoScope, Place } from '../shared/types';

// TODO make this a bit more generic so it can be used in different ways (like by admin/decisionMakerEdit)
export function usePlacePredictions({
	input,
	geoScope,
}: {
	input: string;
	geoScope?: GeoScope;
}): UsePlacePredictionsResult {
	const locale = useLocale();
	const autocompleteService = useAutocompleteService();

	return useQuery(
		async () => ({
			data: await getPlacePredictions({ input, locale, geoScope }, autocompleteService),
		}),
		[input, autocompleteService, locale, geoScope],
		{
			loadingBetweenQueries: true,
		},
	);
}

export type UsePlacePredictionsResult = QueryHookResult<{ data: Place[] }>;
