import { useMemo, useState } from 'react';

import { useParams } from 'react-router';

import { useTracking } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCookiePrefAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useMembershipAwarenessCardFcmExperiments } from '../../../../context';

import { useSessionData } from './hooks/useSessionData';

type Result = ModelHookResult<
	{
		totalSignatureCount: number;
		name?: string;
		display: boolean;
		slug?: string;
		url: string;
	},
	{
		onSubmit: () => void;
		onClose: () => void;
	}
>;

export function useMembershipAwarenessCardMessage(): Result {
	const { showMembershipAwarenessCard } = useMembershipAwarenessCardFcmExperiments();
	const variant = showMembershipAwarenessCard.variation;
	const track = useTracking();
	const { slug } = useParams();
	const { cookies } = useUtilityContext();
	const { name, totalSignatureCount } = useSessionData();

	const MEMBERSHIP_CARD_COOKIE = 'mem_card_x';
	const [cardClosed, setCardClosed] = useState<boolean | undefined>();
	const cookieAllowedState = useCookiePrefAsync('preferences');

	const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;

	const onClose = () => {
		void track('membership_awareness_card_click_close');
		setCardClosed(true);
		if (cookieAllowed) {
			cookies.set(MEMBERSHIP_CARD_COOKIE, JSON.stringify(true), { expires: 7, path: '/' });
		}
	};

	const closedBefore = useMemo(() => {
		if (!cookieAllowed) return !!cardClosed;
		const cookieData = cookies.get(MEMBERSHIP_CARD_COOKIE);
		if (!cookieData) return !!cardClosed;
		try {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const result = JSON.parse(cookieData) as unknown;
			if (typeof result === 'boolean') return result;
			return false;
		} catch {
			return false;
		}
	}, [cardClosed, cookies, cookieAllowed]);

	const onSubmit = () => {
		void track('membership_card_petition_details_click');
	};

	const url = slug
		? `/p/${slug}/psf/membership?source_location=membership_card_petition_details`
		: '/member/join?source_location=membership_card_petition_details';

	return {
		data: {
			totalSignatureCount,
			name,
			display: !closedBefore && variant === 'variant_1',
			slug,
			url,
		},
		actions: {
			onSubmit,
			onClose,
		},
	};
}
