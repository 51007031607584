type CraftEmailHrefArgs = {
	dmName: string;
	dmEmail?: string | null;
	ask: string;
	slug: string;
	starterDisplayName: string;
	signatureCount?: string;
};

const ccChangeEmail = 'decision.makers@change.org';
export const craftSupporterEmail = ({ ask, slug, dmName, dmEmail, starterDisplayName }: CraftEmailHrefArgs): string => {
	const petitionUrl = `https://www.change.org/p/${slug}?utm_source=supporter_emails_dm`;

	const subject = `${dmName}, did you hear us?`;
	const p1 = `Dear ${dmName},`;
	const p2 = `I am supporting a petition called "${ask}" started by ${starterDisplayName}: \n${petitionUrl}`;
	const p3 =
		'You have been identified as a decision maker on this petition so I am reaching out to you for a response. What is your opinion about this petition? You can respond by replying to this email.';
	const p4 = 'Thanks for reading,';
	const p5 =
		'P.S. Check out services for decision makers: https://www.change.org/decision-makers?utm_source=supporter_emails_dm';
	const paragraphs = [p1, p2, p3, p4, p5];

	const body = paragraphs.join('\n\n');
	const encodedSubject = encodeURIComponent(subject);
	const encodedBody = encodeURIComponent(body);

	return `mailto:${dmEmail}?cc=${ccChangeEmail}&body=${encodedBody}&subject=${encodedSubject}`;
};
