import { createJsonNormalizer, createShapeNormalizer } from '@change/config/fcm/normalizers';
import type { Environment } from '@change/core/environment';

import { createFcmConfig } from '../shared/factory';

const ENVS: readonly Environment[] = ['production', 'staging', 'demo', 'development'];

type Strategy =
	| 'promoted'
	| 'user_based_recommendations'
	| 'petition_based_recommendations'
	| 'topic_petitions'
	| 'popular_weekly';
const STRATEGIES: readonly Strategy[] = [
	'promoted',
	'user_based_recommendations',
	'petition_based_recommendations',
	'topic_petitions',
	'popular_weekly',
];

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		maxPetitionsToShow: {
			type: 'number',
		},
		minPetitionsToShow: {
			type: 'record',
			keys: ENVS,
			shape: {
				type: 'number',
			},
		},
		promotedInsertionInterval: {
			type: 'number',
		},
		userRecommendedModelName: {
			type: 'string',
			optional: true,
		},
		cardFetchStrategies: {
			type: 'array',
			shape: {
				type: 'enum',
				values: STRATEGIES,
			},
		},
	},
});

export const FCM_CAROUSEL_CONFIGURATION = createFcmConfig('carousel_configuration', {
	normalizer: createJsonNormalizer(normalizer),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	defaultValue: {
		maxPetitionsToShow: 60,
		minPetitionsToShow: {
			production: 5,
			staging: 5,
			demo: 3,
			development: 3,
		},
		promotedInsertionInterval: 2,
		userRecommendedModelName: undefined,
		cardFetchStrategies: ['topic_petitions', 'popular_weekly', 'promoted'],
	} as ReturnType<typeof normalizer>,
});
