import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-tag-edit',
		path: '/t/:slug/edit',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_tag_edit" */ './index'), {
			exportName: 'AdminTagEdit',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['engineering', 'product', 'campaigns'],
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
