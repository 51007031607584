import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-comms-audience-uploads',
		path: '/dashboard/comms/audience_uploads/create',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_comms_audience_uploads" */ './index'), {
			exportName: 'AdminCommsAudienceUploads',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: ['staff'],
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
