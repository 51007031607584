import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Separator } from '@change/design-system/components/content';
import { Text } from '@change/design-system/typography';

import type { PetitionContentUpdate } from 'src/app/pages/petitionGamma/details/shared/types';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

export function UpdateCardHeading({ update }: Props): JSX.Element | null {
	if (update.type !== 'dmResponse') {
		return null;
	}

	return (
		<>
			<Text as="div" p={16} size="caption" color="typography-primary">
				<Translate value="fe.components.updates_feed.decision_maker_post_title" />
			</Text>
			<Separator role="presentation" m={0} />
		</>
	);
}
