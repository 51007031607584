import { createStateContext } from '@change/core/react/context';
import type { Session } from '@change/core/session';

import { mutations } from './mutations';
import type { SessionState } from './types';

export const {
	StateContext: SessionStateContext,
	StateProvider: SessionStateContextProvider,
	useStateContext: useSessionState,
} = createStateContext<SessionState, typeof mutations, { session?: Session }>({
	name: 'SessionStateContext',
	mutations,
	initialState: ({ session }) => ({
		session,
		sessionUpdates: 0,
	}),
});
