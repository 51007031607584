import { useMemo } from 'react';
import type { JSX } from 'react';

import { CacheProvider } from '@emotion/react';
import { Helmet } from 'react-helmet-async';

import { createNavigationUtils } from '@change/core/react/navigation';
import { AppRenderInfoProvider } from '@change/core/react/ssr/render';
import { UtilityContextProvider } from '@change/core/react/utilityContext';
import { DesignSystem } from '@change/design-system/core';

import { ErrorPage } from 'src/app/app/error';
import { DefaultLayout, SkipToMainContent } from 'src/app/app/layout';
import { Head } from 'src/app/shared/components/head';
import { FcmCacheProvider } from 'src/app/shared/hooks/fcm';
import { L10n } from 'src/app/shared/hooks/l10n';
import { SessionProvider } from 'src/app/shared/hooks/session';
import { useWebappTrackLoad } from 'src/app/shared/hooks/webapp';

import { AppGlobalStyle } from './GlobalStyle';
import type { AppOptions } from './types';

const fullyExternalNavigation = createNavigationUtils({ internalRoutes: [] });

export function ErrorApp(appOptions: AppOptions & { hasTranslations: boolean }): JSX.Element | null {
	const {
		utilities,
		l10n: { locale, countryCode },
		hasTranslations,
		errorId,
		errorStatus,
		session,
		emotionCache,
	} = appOptions;

	const utilitiesWithErrorPageNavigation = useMemo(
		() => ({ ...utilities, navigation: fullyExternalNavigation }),
		[utilities],
	);

	useWebappTrackLoad(appOptions);

	const ErrorWithId = useMemo(
		() =>
			// eslint-disable-next-line react/function-component-definition, @typescript-eslint/no-shadow
			function ErrorWithId() {
				return <ErrorPage status={errorStatus || 500} errorId={errorId} />;
			},
		[errorStatus, errorId],
	);

	return (
		<CacheProvider value={emotionCache}>
			<DesignSystem>
				<AppGlobalStyle />
				<AppRenderInfoProvider>
					<UtilityContextProvider utilities={utilitiesWithErrorPageNavigation}>
						<Helmet
							titleTemplate="%s · Change.org"
							defaultTitle="Change.org"
							bodyAttributes={{
								// necessary for focus fallback
								tabindex: '-1',
							}}
						>
							<html lang={locale} />
						</Helmet>
						<L10n locale={locale} countryCode={countryCode}>
							<SessionProvider session={session}>
								<FcmCacheProvider>
									<Head />
									{hasTranslations ? (
										<>
											<SkipToMainContent />
											<DefaultLayout>
												<ErrorWithId />
											</DefaultLayout>
										</>
									) : (
										<ErrorWithId />
									)}
								</FcmCacheProvider>
							</SessionProvider>
						</L10n>
					</UtilityContextProvider>
				</AppRenderInfoProvider>
			</DesignSystem>
		</CacheProvider>
	);
}
