import { loadable } from '@change/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-fcm-details',
		path: '/dashboard/features/:slug',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_fcm_details" */ './index'), {
			exportName: 'AdminFcmDetails',
			fallback: Loading,
		}),
		frame: {
			header: true,
			// no footer to avoid setting a min-height and preventing position:sticky from being used
		},
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		ssr: {
			// SSR is disabled because of performance issues when rendering big lists using @emotion
			// resulting in a 50 seconds server rendering in dev, and 3-4 seconds in prod, which risks impacting other users on other pages
			// due to node being single-thread
			enabled: false,
			seo: false,
		},
	},
];
