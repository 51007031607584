import { useTracking } from '@change/core/react/tracking';

import { useShowBanner } from './hooks';

type Result = ModelHookResult<
	{
		show: boolean;
		url: string;
	},
	{
		onClickSupport: () => void;
		onClickClose: () => void;
	}
>;

export function useLayout(): Result {
	const { show, pageTrackingName, onClickClose } = useShowBanner();
	const track = useTracking();

	const onClickSupport = () => {
		void track(`membership_banner_${pageTrackingName}_click`);
	};

	return {
		data: {
			show,
			url: `/member/join?source_location=membership_banner_${pageTrackingName}`,
		},
		actions: {
			onClickSupport,
			onClickClose,
		},
	};
}
